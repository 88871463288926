import * as React from 'react';
import { Switch } from '@headlessui/react';
import { AiOutlineControl } from 'react-icons/ai';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import useViewer from 'hooks/useViewer';
import Modal, { ModalWidths } from 'components/Modal';
import { TabNav } from './TabNav';
import { useUpdateUserOptionFlowFiltersMutation } from 'types/generated/client';
import { State } from 'utils/shared/trades/presets';
import {
  useCommandCenter,
  DEFAULT_COMMAND_CENTER_STATE,
  getFlowFilterFunction,
} from 'hooks/useCommandCenter';
import { TickerAggregatedDetails } from 'components/OptionAggregatedTable/props';
import { OptionFlowFilters } from './OptionFlowFilters';
import { DarkPollFilters } from './DarkPoolFilters';
import { Trade, EquityPrint } from 'utils/shared/trades/types';
import {
  CommandCenterButton,
  CommandContent,
  CommandBody,
  CommandFooter,
  CommandApplyButton,
  CommandCancelButton,
  ResetBox,
  ClearFiltersButton,
  FilterCountBox,
  FilterCountBoxSmall,
} from './styles';

interface Props {
  isCommandCenterOpen: boolean;
  setIsCommandCenterOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentFilters: State;
  setCurrentFilters: (state: State) => void;
  currentFilterCount: number;
  resetCurrentFilters: () => void;
  allOptionFlowTrades: Trade[];
  allDarkPoolTrades: EquityPrint[];
  watchListTickers: string[];
  tradesByTickerMap: Record<string, TickerAggregatedDetails>;
}

const tabs = [{ label: 'Options Flow Filters' }, { label: 'Dark Pool Filters' }];

const CommandCenter: React.FC<React.PropsWithChildren<Props>> = ({
  isCommandCenterOpen,
  setIsCommandCenterOpen,
  currentFilters,
  currentFilterCount,
  setCurrentFilters,
  resetCurrentFilters,
  allOptionFlowTrades,
  // allDarkPoolTrades,
  watchListTickers,
  tradesByTickerMap,
}) => {
  const { state, dispatch, ActionTypes } = useCommandCenter();
  const [updateFiltersMutation] = useUpdateUserOptionFlowFiltersMutation();
  const [activeTab, setActiveTab] = React.useState(0);
  const isPreciseFiltersEnabled = state.isPreciseFiltersEnabled;

  const viewer = useViewer();

  React.useEffect(() => {
    if (isCommandCenterOpen) {
      dispatch({ type: ActionTypes.Set, payload: { items: currentFilters } });
    }
  }, [isCommandCenterOpen, currentFilters]);

  const filteredTrades = React.useMemo(() => {
    const flowFilterFunction = getFlowFilterFunction(state, tradesByTickerMap);
    const filteredTrades = allOptionFlowTrades.filter(flowFilterFunction);
    return {
      trades: filteredTrades,
      filteredTradeCount: filteredTrades.length,
      watchListTradeCount: filteredTrades.filter(
        (trade) => !!trade.ticker && watchListTickers.includes(trade.ticker),
      ).length,
    };
  }, [allOptionFlowTrades, state, tradesByTickerMap, watchListTickers]);

  return (
    <>
      <CommandCenterButton onClick={() => setIsCommandCenterOpen(true)}>
        <span className="flex items-center">
          {currentFilterCount > 0 && (
            <FilterCountBoxSmall className="flex items-center justify-center p-0">
              {currentFilterCount}
            </FilterCountBoxSmall>
          )}
        </span>
        <AiOutlineControl />
      </CommandCenterButton>
      <Modal
        width={ModalWidths.CoverPage}
        titleText="Command Center"
        onExit={() => setIsCommandCenterOpen(false)}
        mounted={isCommandCenterOpen}
        focusDialog
        verticallyCenter
        maxHeight="100%"
        useCloseButton={false}
      >
        <CommandContent>
          {currentFilterCount > 0 && (
            <div className="px-4">
              <ResetBox className="mb-1 mt-3 flex items-center justify-between rounded p-4 text-sm">
                <div className="text-base font-semibold" style={{ lineHeight: 1 }}>
                  <span className="flex items-center">
                    <span>Filters have been applied</span>
                    {currentFilterCount > 0 && (
                      <FilterCountBox className="ml-2 px-2 py-1">
                        {currentFilterCount}
                      </FilterCountBox>
                    )}
                  </span>
                </div>
                <ClearFiltersButton
                  className="flex items-center"
                  onClick={() => {
                    dispatch({ type: ActionTypes.Reset });
                    resetCurrentFilters();
                    setIsCommandCenterOpen(false);
                    if (viewer?.viewer?.uid) {
                      updateFiltersMutation({
                        variables: {
                          id: viewer.viewer.uid,
                          optionFlowFilters: DEFAULT_COMMAND_CENTER_STATE,
                        },
                      });
                    }
                  }}
                >
                  <span className="mr-2 text-xs font-semibold uppercase">Clear Filters</span>
                  <AiOutlineCloseCircle />
                </ClearFiltersButton>
              </ResetBox>
            </div>
          )}
          <div className="flex items-center justify-between border-b border-palette-border px-5 py-2 text-xl font-bold">
            <h3>Command Center</h3>
            <TabNav tabs={tabs} activeTab={activeTab} onTabClick={setActiveTab} />
            <div>
              <Switch.Group as="div" className="ml-4 flex items-center justify-end">
                <Switch
                  checked={isPreciseFiltersEnabled}
                  onChange={() => {
                    dispatch({
                      type: ActionTypes.Set,
                      payload: { items: { isPreciseFiltersEnabled: !isPreciseFiltersEnabled } },
                    });
                  }}
                  className="group relative inline-flex h-3 w-6 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
                >
                  <span
                    aria-hidden="true"
                    className={`${
                      isPreciseFiltersEnabled ? 'bg-yellow-400' : 'bg-gray-600'
                    } pointer-events-none absolute mx-auto h-2 w-5 rounded-full transition-colors duration-200 ease-in-out`}
                  />
                  <span
                    aria-hidden="true"
                    className={`${isPreciseFiltersEnabled ? 'translate-x-3' : 'translate-x-0'} ${
                      isPreciseFiltersEnabled ? 'bg-white' : 'bg-gray-300'
                    } pointer-events-none absolute left-0 inline-block h-3 w-3 transform rounded-full border border-gray-400 shadow ring-0 transition-transform duration-200 ease-in-out`}
                  />
                </Switch>
                <Switch.Label as="span" className="ml-2 leading-none" style={{ lineHeight: 0 }}>
                  <span className="text-xs font-medium leading-none text-yellow-400">
                    Precise Filters
                  </span>
                </Switch.Label>
              </Switch.Group>
            </div>
          </div>
          <CommandBody className="px-5 py-1">
            <div className="mt-4 w-full">
              {activeTab === 0 && (
                <OptionFlowFilters
                  enabled={isPreciseFiltersEnabled}
                  state={state}
                  dispatch={dispatch}
                  ActionTypes={ActionTypes}
                />
              )}
              {activeTab === 1 && (
                <DarkPollFilters
                  enabled={isPreciseFiltersEnabled}
                  state={state}
                  dispatch={dispatch}
                  ActionTypes={ActionTypes}
                />
              )}
            </div>
          </CommandBody>
          <CommandFooter className="justify-start px-3 py-2">
            <div className="mx-2 flex justify-start px-1 text-sm font-semibold text-gray-300">
              Visible Flow Trades
              <span className="ml-2 text-yellow-400">{filteredTrades.trades.length}</span>
            </div>
            <div className="mx-2 flex justify-start px-1 text-sm font-semibold text-gray-300">
              Visible Watchlist Trades
              <span className="ml-2 text-yellow-400">{filteredTrades.watchListTradeCount}</span>
            </div>
            <div className="ml-auto flex items-center justify-end space-x-2">
              <CommandCancelButton onClick={() => setIsCommandCenterOpen(false)}>
                Cancel
              </CommandCancelButton>
              <CommandApplyButton
                onClick={() => {
                  setIsCommandCenterOpen(false);
                  setCurrentFilters(state);
                  if (viewer?.viewer?.uid) {
                    updateFiltersMutation({
                      variables: {
                        id: viewer.viewer.uid,
                        optionFlowFilters: state,
                      },
                    });
                  }
                }}
              >
                Apply Filters
              </CommandApplyButton>
            </div>
          </CommandFooter>
        </CommandContent>
      </Modal>
    </>
  );
};

export default CommandCenter;
