import { Fragment } from 'react';

import { useCommandCenter } from 'hooks/useCommandCenter';
import SliderFilter from './Slider';
import {
  OPTION_TYPE,
  TRADE_TYPE,
  PRICE_OPTIONS,
  TICKER_TYPE_OPTIONS,
  OTM_OPTIONS,
  TICKER_FLOW_OPTIONS,
  MOMENTUM_OPTIONS,
  SIZE_OPTIONS,
  PREMIUM_OPTIONS,
  VOLATILITY_OPTIONS,
  OPEN_INTEREST_OPTIONS,
  SECTOR_OPTIONS,
  TIME_OPTIONS,
  MARKET_CAP_OPTIONS,
} from './filterOptions';
import { SliderDirection, EQUITY_FILTER } from 'utils/shared/trades/presets';
import { FilterButtonGroup } from './FilterButtonGroup';
import { FilterButtonGroupWithLabel } from './LabelFilterButtonGroup';
import { CommandRow, OutlineButtonWhite } from './styles';

interface StateProps {
  state: ReturnType<typeof useCommandCenter>['state'];
  dispatch: ReturnType<typeof useCommandCenter>['dispatch'];
  ActionTypes: ReturnType<typeof useCommandCenter>['ActionTypes'];
}

interface RowProps {
  enabled?: boolean;
}

const Row: React.FC<React.PropsWithChildren<RowProps>> = ({ children, enabled }) => (
  <CommandRow
    className={`py-3 text-sm last:mb-0 ${
      enabled ? 'block' : 'flex flex-col justify-between md:flex-row md:items-center'
    }`}
  >
    {children}
  </CommandRow>
);

const RowTitle: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <div className="text-lg font-semibold">{children}</div>
);

const RowDescription: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <div className="pr-24 text-xs font-light text-gray-400">{children}</div>
);

export function OptionFlowFilters({
  enabled = false,
  state,
  dispatch,
  ActionTypes,
}: RowProps & StateProps) {
  return (
    <Fragment>
      <div>
        <Row>
          <RowTitle>Trade Type</RowTitle>
          <div className="flex justify-end opacity-65 hover:opacity-100">
            <FilterButtonGroupWithLabel
              activeOption={state.tradeType}
              options={TRADE_TYPE}
              onClick={(selectedTt) => {
                const stateWithoutAll = state.tradeType.filter((item) => item !== 'All');
                const newItems =
                  selectedTt === 'All'
                    ? ['All']
                    : stateWithoutAll.includes(selectedTt)
                    ? stateWithoutAll.filter((item) => item !== selectedTt)
                    : [...stateWithoutAll, selectedTt];

                if (newItems.length === 0) {
                  newItems.push('All');
                }

                dispatch({
                  type: ActionTypes.Set,
                  payload: { items: { tradeType: newItems } },
                });
              }}
            />
          </div>
        </Row>
        <Row>
          <RowTitle>Option Type</RowTitle>
          <div className="flex justify-end opacity-65 hover:opacity-100">
            <FilterButtonGroupWithLabel
              activeOption={state.optionType}
              options={OPTION_TYPE}
              onClick={(selectedOt) => {
                const newItems = TRADE_TYPE.reduce((acc, option) => {
                  acc[option.label] = selectedOt === 'All' ? false : option.label === selectedOt;
                  return acc;
                }, {} as Record<string, boolean>);
                dispatch({
                  type: ActionTypes.Set,
                  payload: { items: { ...newItems, optionType: selectedOt } },
                });
              }}
            />
          </div>
        </Row>
        <Row>
          <div>
            <RowTitle>Ticker Type</RowTitle>
            <RowDescription>
              <div>We recommend following equities for trading individual tickers.</div>
              <div>Smart money ETF activity is often used primarily for hedging.</div>
            </RowDescription>
          </div>
          <div className="flex justify-end gap-y-2 opacity-65 hover:opacity-100">
            <FilterButtonGroupWithLabel
              activeOption={state.tt}
              options={TICKER_TYPE_OPTIONS}
              onClick={(selectedTt) => {
                const newItems = TICKER_TYPE_OPTIONS.reduce((acc, option) => {
                  acc[option.label] = selectedTt === 'All' ? false : option.label === selectedTt;
                  return acc;
                }, {} as Record<string, boolean>);
                dispatch({
                  type: ActionTypes.Set,
                  payload: { items: { ...newItems, tt: selectedTt } },
                });
              }}
            />
          </div>
        </Row>
        <Row enabled={enabled}>
          <div className="w-1/2">
            <RowTitle>Expiration</RowTitle>
            <RowDescription>
              Select any to include: {'<'} 1 week, 1 week - 1 month, 1 - 3 months, 3+ months
            </RowDescription>
          </div>
          <SliderFilter
            enabled={enabled}
            range={{ min: 0, max: 365 }}
            absoluteMin={EQUITY_FILTER.minExpiration}
            absoluteMax={EQUITY_FILTER.maxExpiration}
            unit=" Days"
            value={
              state.expirationDirection === SliderDirection.Below
                ? state.maxExpiration
                : state.minExpiration
            }
            direction={state.expirationDirection}
            onDirectionChange={(direction: SliderDirection) => {
              dispatch({
                type: ActionTypes.Set,
                payload: { items: { expirationDirection: direction } },
              });
            }}
            onChange={(min, max) => {
              dispatch({
                type: ActionTypes.Set,
                payload: {
                  items: {
                    minExpiration: min,
                    maxExpiration: max,
                    ultrashort: false,
                    short: false,
                    medium: false,
                    long: false,
                  },
                },
              });
            }}
          />
          {!enabled && (
            <div className="flex w-1/2 justify-end opacity-65 hover:opacity-100">
              <OutlineButtonWhite
                isActive={!state.ultrashort && !state.short && !state.medium && !state.long}
                onClick={() => {
                  dispatch({
                    type: ActionTypes.Set,
                    payload: {
                      items: { ultrashort: false, short: false, medium: false, long: false },
                    },
                  });
                }}
              >
                All
              </OutlineButtonWhite>
              <OutlineButtonWhite
                isActive={state.ultrashort}
                onClick={() => {
                  dispatch({
                    type: ActionTypes.Set,
                    payload: { items: { ultrashort: !state.ultrashort } },
                  });
                }}
              >
                Ultra Short
              </OutlineButtonWhite>
              <OutlineButtonWhite
                isActive={state.short}
                onClick={() => {
                  dispatch({
                    type: ActionTypes.Set,
                    payload: { items: { short: !state.short } },
                  });
                }}
              >
                Short
              </OutlineButtonWhite>
              <OutlineButtonWhite
                isActive={state.medium}
                onClick={() => {
                  dispatch({
                    type: ActionTypes.Set,
                    payload: { items: { medium: !state.medium } },
                  });
                }}
              >
                Medium
              </OutlineButtonWhite>
              <OutlineButtonWhite
                isActive={state.long}
                onClick={() => {
                  dispatch({
                    type: ActionTypes.Set,
                    payload: { items: { long: !state.long } },
                  });
                }}
              >
                Long
              </OutlineButtonWhite>
            </div>
          )}
        </Row>
        <Row enabled={enabled}>
          <RowTitle>Price</RowTitle>
          <SliderFilter
            enabled={enabled}
            range={{ min: 0.5, max: 50 }}
            absoluteMin={EQUITY_FILTER.minPrice}
            absoluteMax={EQUITY_FILTER.maxPrice}
            step={0.5}
            unit="$ "
            unitPosition="prefix"
            value={state.priceDirection === SliderDirection.Below ? state.maxPrice : state.minPrice}
            direction={state.priceDirection}
            onDirectionChange={(direction: SliderDirection) => {
              dispatch({
                type: ActionTypes.Set,
                payload: { items: { priceDirection: direction } },
              });
            }}
            onChange={(min, max) => {
              dispatch({
                type: ActionTypes.Set,
                payload: { items: { minPrice: min, maxPrice: max } },
              });
            }}
          />
          {!enabled && (
            <FilterButtonGroup
              options={PRICE_OPTIONS}
              minState={state.minPrice}
              maxState={state.maxPrice}
              onClick={(min, max) => {
                dispatch({
                  type: ActionTypes.Set,
                  payload: { items: { minPrice: min, maxPrice: max } },
                });
              }}
            />
          )}
        </Row>
        <Row enabled={enabled}>
          <RowTitle>Out of the Money</RowTitle>
          <SliderFilter
            enabled={enabled}
            range={{ min: -100, max: 100 }}
            unit="%"
            absoluteMin={EQUITY_FILTER.minOtm}
            absoluteMax={EQUITY_FILTER.maxOtm}
            value={state.otmDirection === SliderDirection.Below ? state.maxOtm : state.minOtm}
            direction={state.otmDirection}
            onDirectionChange={(direction: SliderDirection) => {
              dispatch({
                type: ActionTypes.Set,
                payload: { items: { otmDirection: direction } },
              });
            }}
            onChange={(min, max) => {
              dispatch({
                type: ActionTypes.Set,
                payload: { items: { minOtm: min, maxOtm: max } },
              });
            }}
          />
          {!enabled && (
            <FilterButtonGroup
              options={OTM_OPTIONS}
              minState={state.minOtm}
              maxState={state.maxOtm}
              onClick={(min, max) => {
                dispatch({
                  type: ActionTypes.Set,
                  payload: { items: { minOtm: min, maxOtm: max } },
                });
              }}
            />
          )}
        </Row>
        <Row enabled={enabled}>
          <div>
            <RowTitle>Put Flow</RowTitle>
            <RowDescription>
              Look for overwhelmingly bullish and bearish flows based on the % of puts
            </RowDescription>
          </div>
          <SliderFilter
            // isOnlyAbove={true}
            enabled={enabled}
            range={{ min: 0, max: 100 }}
            unit=" %"
            absoluteMin={EQUITY_FILTER.minFlow}
            absoluteMax={EQUITY_FILTER.maxFlow}
            value={state.flowDirection === SliderDirection.Below ? state.maxFlow : state.minFlow}
            direction={state.flowDirection}
            onDirectionChange={(direction: SliderDirection) => {
              dispatch({
                type: ActionTypes.Set,
                payload: { items: { flowDirection: direction } },
              });
            }}
            onChange={(min, max) => {
              dispatch({
                type: ActionTypes.Set,
                payload: { items: { minFlow: min, maxFlow: max } },
              });
            }}
          />
          {!enabled && (
            <FilterButtonGroup
              options={TICKER_FLOW_OPTIONS}
              minState={state.minFlow}
              maxState={state.maxFlow}
              onClick={(min, max) => {
                dispatch({
                  type: ActionTypes.Set,
                  payload: { items: { minFlow: min, maxFlow: max } },
                });
              }}
            />
          )}
        </Row>
        <Row enabled={enabled}>
          <div>
            <RowTitle>Momentum</RowTitle>
            <RowDescription>
              Tickers with large volume relative to their 5-day average
            </RowDescription>
          </div>
          <SliderFilter
            enabled={enabled}
            range={{ min: 1, max: 10 }}
            unit="x"
            absoluteMin={EQUITY_FILTER.minMomentum}
            absoluteMax={EQUITY_FILTER.maxMomentum}
            step={0.5}
            value={
              state.momentumDirection === SliderDirection.Below
                ? state.maxMomentum
                : state.minMomentum
            }
            direction={state.momentumDirection}
            onDirectionChange={(direction: SliderDirection) => {
              dispatch({
                type: ActionTypes.Set,
                payload: { items: { momentumDirection: direction } },
              });
            }}
            onChange={(min, max) => {
              dispatch({
                type: ActionTypes.Set,
                payload: { items: { minMomentum: min, maxMomentum: max } },
              });
            }}
          />
          {!enabled && (
            <FilterButtonGroup
              options={MOMENTUM_OPTIONS}
              minState={state.minMomentum}
              maxState={state.maxMomentum}
              onClick={(min, max) => {
                dispatch({
                  type: ActionTypes.Set,
                  payload: { items: { minMomentum: min, maxMomentum: max } },
                });
              }}
            />
          )}
        </Row>
        <Row enabled={enabled}>
          <RowTitle>Size</RowTitle>
          <SliderFilter
            enabled={enabled}
            range={{ min: 0, max: 10000 }}
            absoluteMin={EQUITY_FILTER.minSize}
            absoluteMax={EQUITY_FILTER.maxSize}
            unit="K"
            step={500}
            value={state.sizeDirection === SliderDirection.Below ? state.maxSize : state.minSize}
            direction={state.sizeDirection}
            onDirectionChange={(direction: SliderDirection) => {
              dispatch({
                type: ActionTypes.Set,
                payload: { items: { sizeDirection: direction } },
              });
            }}
            onChange={(min, max) => {
              dispatch({
                type: ActionTypes.Set,
                payload: { items: { minSize: min, maxSize: max } },
              });
            }}
          />
          {!enabled && (
            <FilterButtonGroup
              options={SIZE_OPTIONS}
              minState={state.minSize}
              maxState={state.maxSize}
              onClick={(min, max) => {
                dispatch({
                  type: ActionTypes.Set,
                  payload: { items: { minSize: min, maxSize: max } },
                });
              }}
            />
          )}
        </Row>
        <Row enabled={enabled}>
          <RowTitle>Premium</RowTitle>
          <SliderFilter
            enabled={enabled}
            range={{ min: 0, max: 1000000 }}
            unit="K"
            step={5000}
            absoluteMin={EQUITY_FILTER.minPremium}
            absoluteMax={EQUITY_FILTER.maxPremium}
            value={
              state.premiumDirection === SliderDirection.Below ? state.maxPremium : state.minPremium
            }
            direction={state.premiumDirection}
            onDirectionChange={(direction: SliderDirection) => {
              dispatch({
                type: ActionTypes.Set,
                payload: { items: { premiumDirection: direction } },
              });
            }}
            onChange={(min, max) => {
              dispatch({
                type: ActionTypes.Set,
                payload: { items: { minPremium: min, maxPremium: max } },
              });
            }}
          />
          {!enabled && (
            <FilterButtonGroup
              options={PREMIUM_OPTIONS}
              minState={state.minPremium}
              maxState={state.maxPremium}
              onClick={(min, max) => {
                dispatch({
                  type: ActionTypes.Set,
                  payload: { items: { minPremium: min, maxPremium: max } },
                });
              }}
            />
          )}
        </Row>
        <Row enabled={enabled}>
          <RowTitle>Implied Volatility</RowTitle>
          <SliderFilter
            enabled={enabled}
            range={{ min: 0, max: 100 }}
            unit=" %"
            absoluteMin={EQUITY_FILTER.minImpVolatility}
            absoluteMax={EQUITY_FILTER.maxImpVolatility}
            value={
              state.ivDirection === SliderDirection.Below
                ? state.maxImpVolatility
                : state.minImpVolatility
            }
            direction={state.ivDirection}
            onDirectionChange={(direction: SliderDirection) => {
              dispatch({
                type: ActionTypes.Set,
                payload: { items: { ivDirection: direction } },
              });
            }}
            onChange={(min, max) => {
              dispatch({
                type: ActionTypes.Set,
                payload: { items: { minImpVolatility: min, maxImpVolatility: max } },
              });
            }}
          />
          {!enabled && (
            <FilterButtonGroup
              options={VOLATILITY_OPTIONS}
              minState={state.minImpVolatility}
              maxState={state.maxImpVolatility}
              onClick={(min, max) => {
                dispatch({
                  type: ActionTypes.Set,
                  payload: { items: { minImpVolatility: min, maxImpVolatility: max } },
                });
              }}
            />
          )}
        </Row>
        <Row enabled={enabled}>
          <RowTitle>Open Interest</RowTitle>
          <SliderFilter
            enabled={enabled}
            range={{ min: 0, max: 75000 }}
            unit="K"
            step={1000}
            absoluteMin={EQUITY_FILTER.minOpenInterest}
            absoluteMax={EQUITY_FILTER.maxOpenInterest}
            value={
              state.interestDirection === SliderDirection.Below
                ? state.maxOpenInterest
                : state.minOpenInterest
            }
            direction={state.interestDirection}
            onDirectionChange={(direction: SliderDirection) => {
              dispatch({
                type: ActionTypes.Set,
                payload: { items: { interestDirection: direction } },
              });
            }}
            onChange={(min, max) => {
              dispatch({
                type: ActionTypes.Set,
                payload: { items: { minOpenInterest: min, maxOpenInterest: max } },
              });
            }}
          />
          {!enabled && (
            <FilterButtonGroup
              options={OPEN_INTEREST_OPTIONS}
              minState={state.minOpenInterest}
              maxState={state.maxOpenInterest}
              onClick={(min, max) => {
                dispatch({
                  type: ActionTypes.Set,
                  payload: { items: { minOpenInterest: min, maxOpenInterest: max } },
                });
              }}
            />
          )}
        </Row>
        <Row enabled={enabled}>
          <div>
            <RowTitle>Market Cap</RowTitle>
            <div className="pr-24 text-xs font-light text-gray-400">{`Select any to include: <$300M, $300M - $2B, $2B - $10B, >$200B`}</div>
          </div>
          <SliderFilter
            enabled={enabled}
            range={{ min: 0, max: 100_000_000_000 }}
            unit="M"
            step={10_000_000}
            absoluteMin={EQUITY_FILTER.minMarketCap}
            absoluteMax={EQUITY_FILTER.maxMarketCap}
            value={
              state.marketCapDirection === SliderDirection.Below
                ? state.maxMarketCap
                : state.minMarketCap
            }
            direction={state.marketCapDirection}
            onDirectionChange={(direction: SliderDirection) => {
              dispatch({
                type: ActionTypes.Set,
                payload: { items: { marketCapDirection: direction } },
              });
            }}
            onChange={(min, max) => {
              dispatch({
                type: ActionTypes.Set,
                payload: { items: { minMarketCap: min, maxMarketCap: max } },
              });
            }}
          />
          {!enabled && (
            <FilterButtonGroup
              options={MARKET_CAP_OPTIONS}
              minState={state.minMarketCap}
              maxState={state.maxMarketCap}
              onClick={(min, max) => {
                dispatch({
                  type: ActionTypes.Set,
                  payload: { items: { minMarketCap: min, maxMarketCap: max } },
                });
              }}
            />
          )}
        </Row>
        <Row>
          <RowTitle>Sector</RowTitle>
          <div className="flex w-[70%] flex-wrap justify-end gap-y-2 opacity-65 hover:opacity-100">
            <FilterButtonGroupWithLabel
              activeOption={state.activeSector}
              options={SECTOR_OPTIONS}
              onClick={(selectedSector) => {
                const stateWithoutAll = state.activeSector.filter((item) => item !== 'All');
                const newItems =
                  selectedSector === 'All'
                    ? ['All']
                    : stateWithoutAll.includes(selectedSector)
                    ? stateWithoutAll.filter((item) => item !== selectedSector)
                    : [...stateWithoutAll, selectedSector];

                if (newItems.length === 0) {
                  newItems.push('All');
                }

                dispatch({
                  type: ActionTypes.Set,
                  payload: { items: { activeSector: newItems } },
                });
              }}
            />
          </div>
        </Row>
        <Row enabled={enabled}>
          <div>
            <RowTitle>Time</RowTitle>
          </div>
          {enabled && (
            <SliderFilter
              enabled={enabled}
              range={{ min: 0, max: 240 }}
              absoluteMin={EQUITY_FILTER.minTime}
              absoluteMax={EQUITY_FILTER.maxTime}
              unit={' Min'}
              step={15}
              value={state.timeDirection === SliderDirection.Below ? state.maxTime : state.minTime}
              direction={state.timeDirection}
              onDirectionChange={(direction: SliderDirection) => {
                dispatch({
                  type: ActionTypes.Set,
                  payload: { items: { timeDirection: direction } },
                });
              }}
              onChange={(min, max) => {
                dispatch({
                  type: ActionTypes.Set,
                  payload: { items: { minTime: min, maxTime: max } },
                });
              }}
            />
          )}
          {!enabled && (
            <FilterButtonGroup
              options={TIME_OPTIONS}
              minState={state.minTime}
              maxState={state.maxTime}
              onClick={(min, max) => {
                dispatch({
                  type: ActionTypes.Set,
                  payload: { items: { minTime: min, maxTime: max } },
                });
              }}
            />
          )}
        </Row>
      </div>
    </Fragment>
  );
}
