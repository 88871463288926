export const GAINERS = 'gainers';
export const LOSERS = 'losers';
export type MoverValues = typeof GAINERS | typeof LOSERS;

export interface TopMoversTicker {
  day: {
    c: number;
    h: number;
    l: number;
    o: number;
    v: number;
    vw: number;
  };
  lastQuote: {
    P: number;
    S: number;
    p: number;
    s: number;
    t: number;
  };
  lastTrade: {
    c: number[];
    i: string;
    p: number;
    s: number;
    t: number;
    x: number;
  };
  min: {
    av: number;
    c: number;
    h: number;
    l: number;
    o: number;
    v: number;
    vw: number;
  };
  prevDay: {
    c: number;
    h: number;
    l: number;
    o: number;
    v: number;
    vw: number;
  };
  ticker: string;
  todaysChange: number;
  todaysChangePerc: number;
  updated: number;
}

export interface TopMoversResponse {
  status: string;
  tickers: TopMoversTicker[];
}
