import * as React from 'react';
import styled from 'styled-components';
import { BiSearch } from 'react-icons/bi';
import { IoIosCloseCircle } from 'react-icons/io';
import { RawButton } from 'styles/elements';

interface Props {
  value?: string;
  className?: string;
  isSearch?: boolean;
  placeholder?: string;
  onClickSearch?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  reset?: () => void;
  button?: any;
  hideClose?: boolean;
}

const InputWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.color.border};
  min-width: 200px;
`;

const Input = styled.input`
  background-color: ${({ theme }) => theme.color.backgroundSecondary};
  transition: border 0.2s ease;
  line-height: 1;
  height: 32px;
  transition: background-color 200ms ease;

  &:focus {
    outline: none;
    background-color: ${({ theme }) => theme.color.backgroundDistinct};
  }
`;

const Search = styled(RawButton)`
  display: flex;
  width: 32px;
  height: 32px;
  border-left: 1px solid ${({ theme }) => theme.color.border};
  border-radius: 0.15rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 0.9em;
  transition: background-color 200ms ease;
  background-color: ${({ theme }) => theme.color.buttonPrimary};

  &:hover {
    background-color: ${({ theme }) => theme.color.buttonHover};
  }
`;

const ClearButton = styled(RawButton)`
  position: absolute;
  right: 8px;
  top: 0;
  bottom: 0;
`;

const MiniInputContainer: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  className = '',
  isSearch,
  placeholder = '',
  onClickSearch,
  onChange,
  reset,
  button,
  hideClose,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <InputWrapper className="flex rounded">
      <div className="relative w-full flex-grow">
        <Input
          className={`w-full rounded-tl rounded-bl px-3 text-xs ${
            className ? ` ${className}` : ''
          } `}
          ref={inputRef}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          onFocus={() => {
            if (inputRef.current) {
              inputRef.current?.select();
            }
          }}
        />
        {!!value && !hideClose && (
          <ClearButton className="absolute" type="button" onClick={reset}>
            <IoIosCloseCircle />
          </ClearButton>
        )}
      </div>
      {isSearch && (
        <Search
          className="flex-shrink-0 items-center justify-center"
          type="submit"
          onClick={onClickSearch}
        >
          <BiSearch />
        </Search>
      )}
      {!!button && button}
    </InputWrapper>
  );
};

export default MiniInputContainer;
