import * as React from 'react';
import { ApolloError } from '@apollo/client';
import { BiNews } from 'react-icons/bi';
import Card from 'components/Card';
import PulseLoader from 'components/Loading/Pulse';
import SectionTitle from 'components/SectionTitle';
import { EventsResponse } from 'constants/stockNewsApi';
import PermissionedBlock from 'components/PermissionedBlock';
import { hasDashboardAccess } from 'services/client/token';
import { AuthStatus } from 'constants/auth';
import useViewer from 'hooks/useViewer';

import { NewsEvent, EventTicker } from './styles';

interface Props {
  events: EventsResponse['data'];
  loading: boolean;
  error?: Error | ApolloError | any;
  getNewsUrl: (eventId: string) => string;
  getTickerUrl: (ticker: string) => string;
  hideTickers?: boolean;
}

const TopEventsOverview: React.FC<React.PropsWithChildren<Props>> = ({
  events,
  loading,
  error,
  getNewsUrl,
  getTickerUrl,
  hideTickers,
}) => {
  const viewer = useViewer();
  const isLoadingAuthStatus = viewer.status === AuthStatus.Loading;
  const hasAccess = viewer.status === AuthStatus.User && hasDashboardAccess(viewer);

  return (
    <Card className="h-full w-full" isDark>
      <SectionTitle Icon={BiNews} title="Top News Events" className="mb-4" />
      {isLoadingAuthStatus ? (
        <div className="flex h-full w-full items-center justify-center">
          <PulseLoader />
        </div>
      ) : !hasAccess ? (
        <PermissionedBlock />
      ) : !!error && events.length === 0 ? (
        <div className="flex h-full items-center justify-center text-gray-400">
          Error loading news
        </div>
      ) : loading && events.length === 0 ? (
        <div className="flex h-full w-full items-center justify-center">
          <PulseLoader />
        </div>
      ) : (
        <div className="flex h-full max-h-full flex-col overflow-hidden pb-12">
          <div className="flex-shrink flex-grow overflow-y-auto">
            {events.map((event) => {
              return (
                <NewsEvent key={event.event_id}>
                  <a href={getNewsUrl(event.event_id)} target="_blank">
                    <div className="p-4">
                      <h3 className="text-sm font-semibold leading-5">{event.event_name}</h3>
                      {!hideTickers &&
                        (!!event.tickers && event.tickers.length > 0 ? (
                          <div className="flex flex-wrap">
                            {event.tickers.map((ticker) => (
                              <EventTicker
                                className="mr-2 mt-2"
                                key={ticker}
                                href={getTickerUrl(ticker)}
                                target="_blank"
                              >
                                {ticker}
                              </EventTicker>
                            ))}
                          </div>
                        ) : (
                          <div className="flex">
                            <EventTicker className="mr-2 mt-2" href="#">
                              NEWS
                            </EventTicker>
                          </div>
                        ))}
                    </div>
                  </a>
                </NewsEvent>
              );
            })}
          </div>
        </div>
      )}
    </Card>
  );
};

export default TopEventsOverview;
