export const ETF_SECTOR = 'ETF';

const sectorMap: Record<string, string> = {
  'Health Care': 'Healthcare',
  ETF: ETF_SECTOR,
  'Public Utilities': 'Energy',
  'Basic Industries': 'Materials',
  Energy: 'Energy',
  'Consumer Durables': 'Discretionary',
  Finance: 'Financials',
  'Consumer Services': 'Staples',
  Transportation: 'Industrials',
  Miscellaneous: 'Other',
  'Capital Goods': 'Industrials',
  'Consumer Non-Durables': 'Discretionary',
  Technology: 'Technology',
};

export const mapCondensedSectors = (originalSector: string) =>
  sectorMap[originalSector] || originalSector;

const SECTOR_SHORT_NAMES: Record<string, string> = {
  'ETF/ETN': ETF_SECTOR,
  Technology: 'Tech',
  Financials: 'Fin',
  Finance: 'Fin',
  Discretionary: 'Disc',
  Industrials: 'Indst',
  Energy: 'Enrgy',
  Healthcare: 'Hlth',
  Staples: 'Stpl',
  'Consumer Services': 'Serv',
  'Capital Goods': 'Goods',
  Miscellaneous: 'Misc',
  Transportation: 'Trnsp',
  'Basic Industries': 'Basic Ind',
  'Consumer Non-Durables': 'N-Dur',
  'Consumer Durables': 'Dur',
  'Public Utilities': 'Utils',
  'Health Care': 'Hlth',
  Materials: 'Mat',
  '': '-',
};

export const getSectorShortName = (sector: string) => {
  const mappedSector = mapCondensedSectors(sector);
  return SECTOR_SHORT_NAMES[mappedSector] || mappedSector;
};
