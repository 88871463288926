import React from 'react';
import { CommandButtonGroup } from './styles';

interface Option {
  label: string;
  min: number;
  max: number | undefined;
}

interface FilterButtonGroupProps {
  options: Option[];
  minState: number | undefined;
  maxState: number | undefined;
  onClick: (min: number, max: number | undefined) => void;
}


const ButtonGroup: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <CommandButtonGroup className="mt-2 flex flex-shrink-0 items-center md:mt-0">
    {children}
  </CommandButtonGroup>
);

export const FilterButtonGroup: React.FC<FilterButtonGroupProps> = ({ options, minState, maxState, onClick }) => {
  const isActive = (min: number, max: number | undefined): boolean => minState === min && maxState === max;

  return (
    <ButtonGroup>
      {options.map((option, index) => (
        <button
          key={index}
          type="button"
          className={`mr-2 items-center rounded-[4px] border px-3 py-2 text-xs font-semibold leading-none ${isActive(option.min, option.max)
            ? 'text-brand-primary bg-white'
            : 'text-white'
            }`}
          onClick={() => onClick(option.min, option.max)}
        >
          {option.label}
        </button>
      ))}
    </ButtonGroup>
  );
};
