import { ColumnType } from 'utils/shared/trades/types';

export const DEFAULT_FLOW_KEY_INDEX = 0;
export const FLOW_TABLE_HEADERS = [
  { name: 'Time', sortKey: 'createdAt', columnType: ColumnType.Date },
  { name: 'Ticker', sortKey: 'ticker', columnType: ColumnType.Text },
  { name: 'Expiry', sortKey: 'expiry', columnType: ColumnType.Date },
  { name: 'C/P', sortKey: 'cp', columnType: ColumnType.Text },
  { name: 'Spot', sortKey: 'spot', columnType: ColumnType.Number },
  { name: 'Strike', sortKey: 'strike', columnType: ColumnType.Number },
  { name: 'OTM', sortKey: 'otmPercent', columnType: ColumnType.Number },
  { name: 'Price', sortKey: 'price', columnType: ColumnType.Number },
  { name: 'Size', sortKey: 'size', columnType: ColumnType.Number },
  { name: 'Open Interest', sortKey: 'openInterest', columnType: ColumnType.Number },
  { name: 'Implied Vol', sortKey: 'impliedVol', columnType: ColumnType.Number },
  { name: 'Type', sortKey: 'type', columnType: ColumnType.Text },
  { name: 'Premium', sortKey: 'premium', columnType: ColumnType.Number },
  { name: 'Sector', sortKey: 'sector', columnType: ColumnType.Text },
  { name: 'Heat Score', sortKey: 'heatScore', columnType: ColumnType.Number },
];
export const DEFAULT_FLOW_KEY = FLOW_TABLE_HEADERS[DEFAULT_FLOW_KEY_INDEX].sortKey;
