import * as React from 'react';
import { BsColumns } from 'react-icons/bs';
import Card from 'components/Card';
import { Tab, TabWrapper } from 'components/Tab';
import SectionTitle from 'components/SectionTitle';
import SectorBubbleChart from './SectorBubbleChart';
import TreemapChartJs from './SectorTreemap';
import { SectorGraphProps } from './props';

export const DEFAULT_SECTOR_GRAPH_INDEX = 0;

const NAV_HEIGHT = '30px';
const NAV_MARGIN = '.5rem';

const SectorGraphs: React.FC<React.PropsWithChildren<SectorGraphProps>> = ({
  tradesBySector,
  isUnusualFiltered,
  isSweepsFiltered,
  activeSectorGraph,
  setActiveSectorGraph,
}) => {
  return (
    <div className="h-full w-full">
      <Card className="flex h-full w-full flex-col" isDark>
        <SectionTitle
          Icon={BsColumns}
          title="Options Sector Sentiment"
          right={
            <TabWrapper>
              <Tab isActive={activeSectorGraph === 0} onClick={() => setActiveSectorGraph(0)}>
                OTM %
              </Tab>
              <Tab isActive={activeSectorGraph === 1} onClick={() => setActiveSectorGraph(1)}>
                Total Premium
              </Tab>
            </TabWrapper>
          }
        />
        {tradesBySector.length > 0 && activeSectorGraph === 0 && (
          <div
            style={{ height: `calc(100% - ${NAV_HEIGHT} - ${NAV_MARGIN})` }}
            className="w-full max-w-full"
          >
            <SectorBubbleChart
              tradesBySector={tradesBySector}
              isUnusualFiltered={isUnusualFiltered}
              isSweepsFiltered={isSweepsFiltered}
              activeSectorGraph={activeSectorGraph}
              setActiveSectorGraph={setActiveSectorGraph}
            />
          </div>
        )}
        {tradesBySector.length > 0 && activeSectorGraph === 1 && (
          <div
            style={{ height: `calc(100% - ${NAV_HEIGHT} - ${NAV_MARGIN})` }}
            className="w-full max-w-full"
          >
            <TreemapChartJs
              tradesBySector={tradesBySector}
              isUnusualFiltered={isUnusualFiltered}
              isSweepsFiltered={isSweepsFiltered}
              activeSectorGraph={activeSectorGraph}
              setActiveSectorGraph={setActiveSectorGraph}
            />
          </div>
        )}
      </Card>
    </div>
  );
};

export default SectorGraphs;
