import * as React from 'react';
import { useRouter } from 'next/router';
import { Menu, Transition } from '@headlessui/react';
// import { IconType } from 'react-icons';
import { AiOutlineStar, AiOutlineDotChart } from 'react-icons/ai';
import { GoGraph } from 'react-icons/go';
import { BiNews } from 'react-icons/bi';
import { AiOutlineAreaChart } from 'react-icons/ai';
import { IoLogoReact } from 'react-icons/io5';
import { RiLightbulbFlashLine, RiArrowUpDownFill, RiSignalTowerFill } from 'react-icons/ri';
import { CaretDown16 } from '@carbon/icons-react';
import { DatePicker, DatePickerInput } from 'carbon-components-react';
import { trackEvent } from 'services/client/analytics';
import { FLOW_TOGGLE_SIDE_MODULE } from 'services/client/analytics/events';
import classNames from 'styles/utils/classNames';
import SidebarPage from 'layouts/SidebarPage';
import MiniInput from 'components/MiniInput';
import PageTitle from 'components/PageTitle';
import Dropdown from 'components/Dropdown';
import { LayoutGrid, MainColumnDynamic, StackedColumn } from 'components/Layout';
import StatCard from 'components/SentimentCards/StatCard';
import PutCallCard from 'components/SentimentCards/PutCallCard';
import Card from 'components/Card';
import { CardRow } from 'components/SentimentCards/styles';
import OptionAggregatedTable from 'components/OptionAggregatedTable';
import CommandCenter from 'components/CommandCenter';
import Slider from 'components/Slider';
import { TickerAggregatedDetails } from 'components/OptionAggregatedTable/props';
import DarkPoolTable from 'components/DarkPoolTable/DarkPoolTable';
import TopMoversTable from 'components/TopMoversTable';
import SignalAlertTable from 'components/SignalAlertTable';
import MostActiveTickers from 'components/MostActiveTickers';
import getSignaturePrintStatus from 'utils/shared/trades/getSignaturePrintStatus';
import { getIsGoldenDarkPool } from 'utils/shared/trades/getIsGoldenDarkPool';
import {
  useCommandCenter,
  getFlowFilterFunction,
  getDarkPoolFilterFunction,
  getAggregateFilterFunction,
} from 'hooks/useCommandCenter';
// import { useApiGateway } from 'hooks/useApi';
import {
  TICKER_FLOW_PAGE,
  OPTION_FLOW_PAGE,
  HISTORICAL_FLOW_PAGE,
  WEEKLY_OVERVIEW_PAGE,
  PageIcons,
} from 'constants/pages';
import { calculatePutFlow } from 'utils/shared/trades/calculatePutFlow';
import isUnusual from 'utils/shared/trades/isUnusual';
import isSweep from 'utils/shared/trades/isSweep';
import isSplit from 'utils/shared/trades/isSplit';
import isBlock from 'utils/shared/trades/isBlock';
import isGoldenSweep from 'utils/shared/trades/isGoldenSweep';
import calculateTickerOverview from 'utils/shared/trades/calculateTickerOverview';
import calculatePercentOtm from 'utils/shared/trades/calculatePercentOtm';
import {
  PresetType,
  isEquityPreset,
  isUnusualPreset,
  isSweepPreset,
  isMomentumPreset,
  isEtfPreset,
  isMoonshotPreset,
  presetObjects,
  presetAggregateSortKey,
  presetGraphIndex,
} from 'utils/shared/trades/presets';
import { mapCondensedSectors, ETF_SECTOR } from 'utils/shared/trades/sectors';
import abbreviateNumber from 'utils/shared/number/abbreviateNumber';
import { SortDirection, ColumnType, SignaturePrintStatus } from 'utils/shared/trades/types';
import getDerivedTradeValues from 'utils/shared/trades/getDerivedTradeValues';
import {
  useUpdateUserOptionFlowFiltersMutation,
  useGetWatchListQuery,
  GetOptionFlowQuery,
  GetWeeklyOverviewQuery,
} from 'types/generated/client';
import { hasDashboardAccess } from 'services/client/token';
import { AuthStatus } from 'constants/auth';
import { useCurrentUser } from 'hooks/useCurrentUser';
import {
  useGetDarkPoolTradesQuery,
  useGetDarkPoolTradesCountQuery,
  Timezone_Location_Enum,
} from 'types/generated/client';
import { MAX_FREE_TRADES } from 'constants/user';
import TopTickerGraphs, { DEFAULT_TOP_TICKER_GRAPH_INDEX } from './TopTickerGraphs';
import DarkPoolGraphs from './DarkPoolGraphs';
import SectorGraphs, { DEFAULT_SECTOR_GRAPH_INDEX } from './SectorGraphs';
import WatchList from './WatchList';
import TopNewsEvents from './TopNewsEvents';
import FlowDescription from './FlowDescription';
import {
  Props,
  SectorAggregatedDetails,
  DarkPoolSectorAggregatedDetails,
  DarkPoolTickerAggregatedDetails,
  DEFAULT_FLOW_KEY,
  DEFAULT_AGGREGATE_KEY,
  FLOW_TABLE_HEADERS,
  LayoutType,
} from './props';

import {
  Input,
  Label,
  Button,
  FieldWrapper,
  ShowTripleColumns,
  LayoutButtons,
  HideLarge,
  ShowLarge,
  LayoutButtonRow,
} from './styles';

type InjectedTrades =
  | GetOptionFlowQuery['option_flow']
  | GetWeeklyOverviewQuery['weekly_option_flow']
  | undefined;

const DARKPOOL_POLL_INTERVAL = 30_000;
const HEADER_ROW_COUNT = 1;
const AUDIO_NOTIFICATION_URL = '/audio/notification1.mp3';
const PAGE_DROPDOWN_ITEMS = [
  {
    name: (
      <span className="flex items-center">
        <PageIcons.OptionFlow /> <span className="ml-2">Realtime</span>
      </span>
    ),
    href: OPTION_FLOW_PAGE,
  },
  {
    name: (
      <span className="flex items-center">
        <PageIcons.HistoricalFlow /> <span className="ml-2">Historical</span>
      </span>
    ),
    href: HISTORICAL_FLOW_PAGE,
  },
  {
    name: (
      <span className="flex items-center">
        <PageIcons.WeeklyOverview /> <span className="ml-2">Weekly Overview</span>
      </span>
    ),
    href: WEEKLY_OVERVIEW_PAGE,
  },
];
const SLIDER_TABS = ['Stacked', 'Market', 'Flow'];
const PRESET_TABS = ['Equities', 'Unusual', 'Sweeps', 'Momentum', 'ETFs', 'Moonshots', 'Custom'];
enum RightColumnList {
  DarkPool = 'DARK_POOL',
  DarkPoolCharts = 'DARK_POOL_CHARTS',
  Graphs = 'GRAPHS',
  TickerGraphs = 'TICKER_GRAPHS',
  SectorGraphs = 'SECTOR_GRAPHS',
  WatchList = 'WATCH_LIST',
  TopNews = 'TOP_NEWS',
  TopMovers = 'TOP_MOVERS',
  Signals = 'SIGNALS',
  MostResearched = 'MOST_RESEARCHED',
}

// const RightColumnItem: React.FC<{ text: string; Icon: IconType }> = ({ text, Icon }) => {
//   return (
//     <span className="flex items-center">
//       <Icon />
//       <span className="ml-2">{text}</span>
//     </span>
//   );
// };

const rightColumnItems = [
  {
    key: RightColumnList.TickerGraphs,
    Icon: GoGraph,
    text: 'Options Top Tickers',
    name: (
      <span className="flex items-center">
        <GoGraph />
        <span className="ml-2">Options Top Tickers</span>
      </span>
    ),
  },
  {
    key: RightColumnList.SectorGraphs,
    Icon: AiOutlineDotChart,
    text: 'Options Sector Sentiment',
    name: (
      <span className="flex items-center">
        <AiOutlineDotChart />
        <span className="ml-2">Options Sector Sentiment</span>
      </span>
    ),
  },
  {
    key: RightColumnList.DarkPool,
    Icon: RiLightbulbFlashLine,
    text: 'Dark Pool Flow',
    name: (
      <span className="flex items-center">
        <RiLightbulbFlashLine />
        <span className="ml-2">Dark Pool Flow</span>
      </span>
    ),
  },
  {
    key: RightColumnList.DarkPoolCharts,
    Icon: AiOutlineAreaChart,
    text: 'Dark Pool Top Tickers',
    name: (
      <span className="flex items-center">
        <AiOutlineAreaChart />
        <span className="ml-2">Dark Pool Top Tickers</span>
      </span>
    ),
  },
  {
    key: RightColumnList.Signals,
    Icon: RiSignalTowerFill,
    text: 'Signals and Alerts',
    name: (
      <span className="flex items-center">
        <RiSignalTowerFill />
        <span className="ml-2">Signals and Alerts</span>
      </span>
    ),
  },
  {
    key: RightColumnList.WatchList,
    Icon: AiOutlineStar,
    text: 'Watch List',
    name: (
      <span className="flex items-center">
        <AiOutlineStar />
        <span className="ml-2">Watch List</span>
      </span>
    ),
  },
  {
    key: RightColumnList.TopNews,
    Icon: BiNews,
    text: 'Top News',
    name: (
      <span className="flex items-center">
        <BiNews />
        <span className="ml-2">Top News</span>
      </span>
    ),
  },
  {
    key: RightColumnList.TopMovers,
    Icon: RiArrowUpDownFill,
    text: 'Top Movers',
    name: (
      <span className="flex items-center">
        <RiArrowUpDownFill />
        <span className="ml-2">Top Movers</span>
      </span>
    ),
  },
  {
    key: RightColumnList.MostResearched,
    Icon: IoLogoReact,
    text: 'Most Researched Tickers',
    name: (
      <span className="flex items-center">
        <IoLogoReact />
        <span className="ml-2">Most Researched Tickers</span>
      </span>
    ),
  },
];
const rightColumnItemsLarge = [
  {
    key: RightColumnList.Signals,
    name: (
      <span className="flex items-center">
        <RiSignalTowerFill />
        <span className="ml-2">Signals and Alerts</span>
      </span>
    ),
  },
  {
    key: RightColumnList.DarkPoolCharts,
    name: (
      <span className="flex items-center">
        <AiOutlineAreaChart />
        <span className="ml-2">Dark Pool Top Tickers</span>
      </span>
    ),
  },
  {
    key: RightColumnList.WatchList,
    name: (
      <span className="flex items-center">
        <AiOutlineStar />
        <span className="ml-2">Watch List</span>
      </span>
    ),
  },
  {
    key: RightColumnList.TopNews,
    name: (
      <span className="flex items-center">
        <BiNews />
        <span className="ml-2">Top News</span>
      </span>
    ),
  },
  {
    key: RightColumnList.TopMovers,
    name: (
      <span className="flex items-center">
        <RiArrowUpDownFill />
        <span className="ml-2">Top Movers</span>
      </span>
    ),
  },
  {
    key: RightColumnList.MostResearched,
    name: (
      <span className="flex items-center">
        <IoLogoReact />
        <span className="ml-2">Most Researched Tickers</span>
      </span>
    ),
  },
];
export const MAX_HISTORICAL_RESULTS = 5000;

const instanceOfOptionFlow = (object: any): object is GetOptionFlowQuery => {
  return !!object && !!object['option_flow'];
};

const instanceOfWeeklyOverview = (object: any): object is GetWeeklyOverviewQuery => {
  return !!object && !!object['weekly_option_flow'];
};

const getInjectedTrades = (
  injectedPageData?: GetOptionFlowQuery | GetWeeklyOverviewQuery,
): InjectedTrades => {
  if (!injectedPageData) {
    return undefined;
  }

  if (instanceOfOptionFlow(injectedPageData)) {
    return injectedPageData.option_flow;
  }

  if (instanceOfWeeklyOverview(injectedPageData)) {
    return injectedPageData.weekly_option_flow;
  }

  return undefined;
};

const getRowsForLayout = (layout: LayoutType) => {
  if (layout === LayoutType.Stacked) {
    return 2;
  }

  return 1;
};

const OptionFlowPage: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    isWeekly,
    shouldShowDatePicker,
    OptionFlowTable,
    title,
    Icon,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    tickers,
    setTickers,
    maxResults,
    setMaxResults,
    handleHistoricalSearch,
    viewer,
    flowSliderReplacement,
    defaultLayoutType,
    injectedPageData,
    pageHref,
    isShowFlowDescription,
    totalFlowCount,
  } = props;
  /**
   * @todo use this
   */
  // const { get } = useApiGateway('/v1/products/option-flow');
  const additonalRows = shouldShowDatePicker ? 1 : 0;
  const router = useRouter();
  const {
    data: watchListData,
    refetch: watchListRefetch,
    called: watchListCalled,
    loading: watchListLoading,
  } = useGetWatchListQuery({
    skip: !viewer?.viewer?.uid,
    variables: {
      userId: viewer?.viewer?.uid || '',
    },
  });
  const { user: currentUser } = useCurrentUser();
  const timezoneLocation = currentUser?.timezoneLocation || Timezone_Location_Enum.Current;
  const isWallstreetTimezone = timezoneLocation === Timezone_Location_Enum.Wallstreet;
  const hasAccess = viewer.status === AuthStatus.User && hasDashboardAccess(viewer);
  const { data, loading, error, refetch } = useGetDarkPoolTradesQuery({
    skip: !hasAccess,
  });
  const darkpoolTrades = data?.equity_print_flow || [];
  const { data: countData, refetch: refetchCount } = useGetDarkPoolTradesCountQuery({
    skip: !hasAccess,
  });
  const count = countData?.equity_print_flow_aggregate?.aggregate?.count;
  const [updateFiltersMutation] = useUpdateUserOptionFlowFiltersMutation();
  const { state: filterState, dispatch, numberOfFilters, ActionTypes } = useCommandCenter();
  const [searchValue, setSearchValue] = React.useState('');
  const [activeRightColumnSectionTop, setActiveRightColumnSectionTop] = React.useState(
    RightColumnList.TickerGraphs,
  );
  const [activeRightColumnSectionBot, setActiveRightColumnSectionBot] = React.useState(
    RightColumnList.DarkPool,
  );
  const [activeRightColumnSectionLarge, setActiveRightColumnSectionLarge] = React.useState(
    RightColumnList.Signals,
  );
  const [audio, setAudio] = React.useState<HTMLAudioElement | null>(null);
  const [isFlowSoundEnabled, setIsFlowSoundEnabled] = React.useState(true);
  const [isFlowWatchListOnly, setIsFlowWatchListOnly] = React.useState(false);
  const [isAggregateWatchListOnly, setIsAggregateWatchListOnly] = React.useState(false);
  const [isCommandCenterOpen, setIsCommandCenterOpen] = React.useState(false);
  const [topTickerGraph, setTopTickerGraph] = React.useState(DEFAULT_TOP_TICKER_GRAPH_INDEX);
  const [activeSectorGraph, setActiveSectorGraph] = React.useState(DEFAULT_SECTOR_GRAPH_INDEX);
  const [layoutIndex, setLayoutIndex] = React.useState(defaultLayoutType || LayoutType.Stacked);
  const [mobileLayoutIndex, setMobileLayoutIndex] = React.useState<LayoutType | string>(
    defaultLayoutType || LayoutType.Stacked,
  );
  const [presetIndex, setPresetIndex] = React.useState(PresetType.Equities);
  const [aggregateSortKey, setAggregateSortKey] = React.useState(DEFAULT_AGGREGATE_KEY);
  const [aggregateSortDirection, setAggregateSortDirection] = React.useState(SortDirection.None);
  const [flowSortKey, setFlowSortKey] = React.useState(DEFAULT_FLOW_KEY);
  const [flowSortDirection, setFlowSortDirection] = React.useState(SortDirection.None);
  const injectedTrades: InjectedTrades = getInjectedTrades(injectedPageData);
  const initialTrades = injectedTrades || props.trades || [];
  const watchListTickers = React.useMemo(
    () =>
      watchListData?.watch_list.filter((item) => item.isWatching).map((item) => item.ticker) || [],
    [watchListData],
  );
  const trades = React.useMemo(
    () =>
      initialTrades
        .map((t, i) => {
          const spot = t.spot || t.priceExternalApi;
          return {
            ...t,
            spot,
            otmPercent: calculatePercentOtm(t.strike, spot, t.cp || ''),
            isBlur: !injectedPageData && !hasAccess && i >= MAX_FREE_TRADES,
          };
        })
        // NOTE: Pass in the values here and every other instance of getDerivedTradeValues which calls convertTimestampToTime. Then make sure every instance of convertTimestampToTime uses the user's timezone.
        .map((trade) => {
          return getDerivedTradeValues(trade, isWallstreetTimezone);
        }),
    [initialTrades, isWallstreetTimezone, hasAccess],
  );

  const hasWatchListItems =
    watchListCalled &&
    !watchListLoading &&
    !!watchListData?.watch_list.filter((item) => item.isWatching).length;
  React.useEffect(() => {
    const watchListHasLoaded = watchListCalled && !watchListLoading;
    const toggledWatchListOn = isFlowWatchListOnly || isAggregateWatchListOnly;
    if (toggledWatchListOn && watchListHasLoaded && !hasWatchListItems) {
      setActiveRightColumnSectionTop(RightColumnList.WatchList);
    }
  }, [
    isFlowWatchListOnly,
    isAggregateWatchListOnly,
    hasWatchListItems,
    watchListCalled,
    watchListLoading,
  ]);

  React.useEffect(() => {
    let interval: number | undefined;

    if (hasAccess) {
      if (refetchCount) {
        refetchCount();
      }

      // @ts-ignore issue with Node / browser types
      interval = setInterval(() => {
        if (refetchCount) {
          refetchCount();
        }
      }, DARKPOOL_POLL_INTERVAL);
    }

    return () => clearInterval(interval);
  }, [hasAccess, refetchCount]);

  // React.useEffect(() => {
  //   get().then((data) => console.log(data));
  // }, []);

  React.useEffect(() => {
    if (hasAccess) {
      if (refetch) {
        refetch();
      }
    }
  }, [hasAccess, refetch, count]);

  React.useEffect(() => {
    setAudio(new Audio(AUDIO_NOTIFICATION_URL));
  }, []);

  React.useEffect(() => {
    if (router.isReady) {
      // Currently allow default to take precedence
      if (defaultLayoutType) {
        setLayoutIndex(defaultLayoutType);
      } else if (router.query.layout) {
        const layout = parseInt(router.query.layout as string, 10);
        setLayoutIndex(layout as LayoutType);
      }

      if (router.query.presetIndex) {
        const presetIndex = router.query.presetIndex as string;
        const presetIndexNumber = parseInt(presetIndex, 10);
        const presetObject = presetObjects[presetIndexNumber as PresetType];
        setPresetIndex(presetIndexNumber);

        if (presetObject) {
          dispatch({
            type: ActionTypes.Set,
            payload: { items: { ...filterState, ...presetObject } },
          });
        }
      }

      if (router.query.flowSortKey) {
        const overrideFlowSortKey = router.query.flowSortKey as string;
        setFlowSortKey(overrideFlowSortKey);
      }

      if (router.query.flowSortDirection) {
        const overrideFlowSortDirection = router.query.flowSortDirection as string;
        setFlowSortDirection(overrideFlowSortDirection as SortDirection);
      }

      if (router.query.aggregateSortKey) {
        const overrideAggregateSortKey = router.query.aggregateSortKey as string;
        setAggregateSortKey(overrideAggregateSortKey);
      }

      if (router.query.aggregateSortDirection) {
        const overrideAggregateSortDirection = router.query.aggregateSortDirection as string;
        setAggregateSortDirection(overrideAggregateSortDirection as SortDirection);
      }

      if (router.query.topTickerGraph) {
        const overrideTopTickerGraph = router.query.topTickerGraph as string;
        setTopTickerGraph(parseInt(overrideTopTickerGraph, 10));
      }

      if (router.query.activeSectorGraph) {
        const overrideActiveSectorGraph = router.query.activeSectorGraph as string;
        setActiveSectorGraph(parseInt(overrideActiveSectorGraph, 10));
      }
    }
  }, [router.isReady, defaultLayoutType]);

  const dailyVolume = injectedPageData?.daily_volume || props.dailyVolume || [];
  const dailyVolumeMap: Record<string, number> = React.useMemo(
    () =>
      dailyVolume.reduce((acc, curr) => {
        if (curr.ticker && curr.volume) {
          // @ts-ignore
          acc[curr.ticker] = curr.volume;
        }
        return acc;
      }, {} as Record<string, number>),
    [dailyVolume],
  );

  React.useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (router.query.presetIndex) {
      return;
    }

    if (props.userDetails?.optionFlowFilters) {
      dispatch({
        type: ActionTypes.Set,
        payload: { items: { ...filterState, ...props.userDetails.optionFlowFilters } },
      });

      let presetIndex = PresetType.Custom;

      if (isEquityPreset(props.userDetails.optionFlowFilters)) {
        presetIndex = PresetType.Equities;
      }

      if (isUnusualPreset(props.userDetails.optionFlowFilters)) {
        presetIndex = PresetType.Unusual;
      }

      if (isSweepPreset(props.userDetails.optionFlowFilters)) {
        presetIndex = PresetType.Sweeps;
      }

      if (isMomentumPreset(props.userDetails.optionFlowFilters)) {
        presetIndex = PresetType.Momentum;
      }

      if (isEtfPreset(props.userDetails.optionFlowFilters)) {
        presetIndex = PresetType.ETFs;
      }

      if (isMoonshotPreset(props.userDetails.optionFlowFilters)) {
        presetIndex = PresetType.Moonshots;
      }

      const presetAggregateSort = presetAggregateSortKey[presetIndex];
      const presetGraph = presetGraphIndex[presetIndex];

      setPresetIndex(presetIndex);

      if (presetAggregateSort) {
        setAggregateSortKey(presetAggregateSort);
        setAggregateSortDirection(SortDirection.Desc);
      }

      if (presetGraph || presetGraph === 0) {
        setTopTickerGraph(presetGraph);
      }
    }
  }, [router.isReady, props.userDetails?.optionFlowFilters]);

  const tradesBySector = React.useMemo(() => {
    const groupedTrades: Record<string, SectorAggregatedDetails> = {};

    trades.forEach((t) => {
      const originalSector = t.tickerDetails?.sector;

      if (
        !!originalSector &&
        (originalSector !== 'ETF' || filterState.etfs || !filterState.stocks)
      ) {
        const sector = mapCondensedSectors(originalSector);
        if (!groupedTrades[sector]) {
          groupedTrades[sector] = {
            sector: sector,
            trades: [],
            unusual: [],
            sweeps: [],
            splits: [],
            blocks: [],
            goldenSweeps: [],
            putFlow: 0,
            expiry: 0,
            otm: 0,
            contracts: 0,
            premium: 0,
            unusualOtm: 0,
            unusualContracts: 0,
            unusualPremium: 0,
            sweepOtm: 0,
            sweepContracts: 0,
            sweepPremium: 0,
            splitOtm: 0,
            splitContracts: 0,
            splitPremium: 0,
            blockOtm: 0,
            blockContracts: 0,
            blockPremium: 0,
            goldenSweepOtm: 0,
            goldenSweepContracts: 0,
            goldenSweepPremium: 0,
          };
        }

        groupedTrades[sector].trades.push(t);
        if (isUnusual(t)) {
          groupedTrades[sector].unusual.push(t);
        }
        if (isSweep(t)) {
          groupedTrades[sector].sweeps.push(t);
        }
        if (isSplit(t)) {
          groupedTrades[sector].splits.push(t);
        }
        if (isBlock(t)) {
          groupedTrades[sector].blocks.push(t);
        }
        if (isGoldenSweep(t)) {
          groupedTrades[sector].goldenSweeps.push(t);
        }
      }
    });

    Object.keys(groupedTrades).forEach((sector) => {
      const sectorDetails = groupedTrades[sector];
      const { putFlowVolume } = calculatePutFlow(sectorDetails.trades);
      const { otm, expiry, contracts, premium } = calculateTickerOverview(sectorDetails.trades);
      const {
        otm: unusualOtm,
        contracts: unusualContracts,
        premium: unusualPremium,
      } = calculateTickerOverview(sectorDetails.unusual);
      const {
        otm: sweepOtm,
        contracts: sweepContracts,
        premium: sweepPremium,
      } = calculateTickerOverview(sectorDetails.sweeps);
      const {
        otm: splitOtm,
        contracts: splitContracts,
        premium: splitPremium,
      } = calculateTickerOverview(sectorDetails.splits);
      const {
        otm: blockOtm,
        contracts: blockContracts,
        premium: blockPremium,
      } = calculateTickerOverview(sectorDetails.blocks);
      const {
        otm: goldenSweepOtm,
        contracts: goldenSweepContracts,
        premium: goldenSweepPremium,
      } = calculateTickerOverview(sectorDetails.goldenSweeps);

      groupedTrades[sector].putFlow = Math.round(putFlowVolume * 100);
      groupedTrades[sector].expiry = Math.round(expiry);
      groupedTrades[sector].otm = Math.round(otm);
      groupedTrades[sector].premium = premium;
      groupedTrades[sector].contracts = contracts;
      groupedTrades[sector].unusualOtm = Math.round(unusualOtm);
      groupedTrades[sector].unusualContracts = unusualContracts;
      groupedTrades[sector].unusualPremium = unusualPremium;
      groupedTrades[sector].sweepOtm = Math.round(sweepOtm);
      groupedTrades[sector].sweepContracts = sweepContracts;
      groupedTrades[sector].sweepPremium = sweepPremium;
      groupedTrades[sector].splitOtm = Math.round(splitOtm);
      groupedTrades[sector].splitContracts = splitContracts;
      groupedTrades[sector].splitPremium = splitPremium;
      groupedTrades[sector].blockOtm = Math.round(blockOtm);
      groupedTrades[sector].blockContracts = blockContracts;
      groupedTrades[sector].blockPremium = blockPremium;
      groupedTrades[sector].goldenSweepOtm = Math.round(goldenSweepOtm);
      groupedTrades[sector].goldenSweepContracts = goldenSweepContracts;
      groupedTrades[sector].goldenSweepPremium = goldenSweepPremium;
    });

    return Object.values(groupedTrades);
  }, [trades, filterState]);

  const darkPoolBySector = React.useMemo(() => {
    const groupedTrades: Record<string, DarkPoolSectorAggregatedDetails> = {};

    darkpoolTrades.forEach((t) => {
      const originalSector = t.tickerDetails?.sector;

      if (!!originalSector || t.isEtf) {
        const sector = t.isEtf
          ? ETF_SECTOR
          : originalSector
          ? mapCondensedSectors(originalSector)
          : '';
        if (!groupedTrades[sector]) {
          groupedTrades[sector] = {
            sector: sector,
            trades: [],
            premium: 0,
            volume: 0,
            lateBuyCount: 0,
            lateSellCount: 0,
            goldenTradeCount: 0,
            avgVolume: t.avgVolume,
            dayVolume: t.dayVolume,
            marketCap: t.marketCap,
            isEtf: t.isEtf || sector === ETF_SECTOR,
          };
        }
        groupedTrades[sector].trades.push(t);
      }
    });

    Object.keys(groupedTrades).forEach((sector) => {
      const sectorDetails = groupedTrades[sector];

      sectorDetails.trades.forEach((t) => {
        const signaturePrintStatus = getSignaturePrintStatus(t, { open: t.open });
        groupedTrades[sector].premium += t.amount || 0;
        groupedTrades[sector].volume += t.size || 0;

        if (signaturePrintStatus === SignaturePrintStatus.Buy) {
          groupedTrades[sector].lateBuyCount += 1;
        } else if (signaturePrintStatus === SignaturePrintStatus.Sell) {
          groupedTrades[sector].lateSellCount += 1;
        }

        if (getIsGoldenDarkPool(t.size, t.avgVolume)) {
          groupedTrades[sector].goldenTradeCount += 1;
        }
      });
    });

    return Object.values(groupedTrades);
  }, [darkpoolTrades]);

  const darkPoolByTicker = React.useMemo(() => {
    const groupedTrades: Record<string, DarkPoolTickerAggregatedDetails> = {};

    darkpoolTrades.forEach((t) => {
      const originalSector = t.tickerDetails?.sector;
      const sector = t.isEtf
        ? ETF_SECTOR
        : originalSector
        ? mapCondensedSectors(originalSector)
        : '';
      const ticker = t.ticker || '';
      if (!groupedTrades[ticker]) {
        groupedTrades[ticker] = {
          ticker,
          tickerName: t.tickerDetails?.name || '',
          sector: sector,
          trades: [],
          premium: 0,
          volume: 0,
          lateBuyCount: 0,
          lateSellCount: 0,
          goldenTradeCount: 0,
          avgVolume: t.avgVolume,
          dayVolume: t.dayVolume,
          marketCap: t.marketCap,
          momentum: 0,
          isEtf: t.isEtf || sector === ETF_SECTOR,
        };
      }
      groupedTrades[ticker].trades.push(t);
    });

    Object.keys(groupedTrades).forEach((ticker) => {
      const tickerDetails = groupedTrades[ticker];

      tickerDetails.trades.forEach((t) => {
        const signaturePrintStatus = getSignaturePrintStatus(t, { open: t.open });
        groupedTrades[ticker].premium += t.amount || 0;
        groupedTrades[ticker].volume += t.size || 0;

        if (signaturePrintStatus === SignaturePrintStatus.Buy) {
          groupedTrades[ticker].lateBuyCount += 1;
        } else if (signaturePrintStatus === SignaturePrintStatus.Sell) {
          groupedTrades[ticker].lateSellCount += 1;
        }

        if (getIsGoldenDarkPool(t.size, t.avgVolume)) {
          groupedTrades[ticker].goldenTradeCount += 1;
        }
      });

      // NOTE: This is a hacked together momentum calculation
      groupedTrades[ticker].momentum =
        groupedTrades[ticker].volume /
        ((groupedTrades[ticker].avgVolume || groupedTrades[ticker].volume) / 2);
    });

    return Object.values(groupedTrades);
  }, [darkpoolTrades]);

  const tradesByTickerMap = React.useMemo(() => {
    const groupedTrades: Record<string, TickerAggregatedDetails> = {};

    trades.forEach((t) => {
      const ticker = t.ticker || '';

      if (!groupedTrades[ticker]) {
        groupedTrades[ticker] = {
          ticker: ticker,
          tickerName: t.tickerDetails?.name || '',
          sector: t.tickerDetails?.sector || '',
          marketCap: t.marketCap || 0,
          trades: [],
          unusual: [],
          sweeps: [],
          splits: [],
          blocks: [],
          goldenSweeps: [],
          putFlow: 0,
          price: 0,
          expiry: 0,
          otm: 0,
          momentum: 0,
          volumeGrowth: 0,
          contracts: 0,
          premium: 0,
          unusualOtm: 0,
          unusualContracts: 0,
          unusualPremium: 0,
          sweepOtm: 0,
          sweepContracts: 0,
          sweepPremium: 0,
          putVolume: 0,
          callVolume: 0,
          putPremium: 0,
          callPremium: 0,
          splitOtm: 0,
          splitContracts: 0,
          splitPremium: 0,
          blockOtm: 0,
          blockContracts: 0,
          blockPremium: 0,
          goldenSweepOtm: 0,
          goldenSweepContracts: 0,
          goldenSweepPremium: 0,
        };
      }

      groupedTrades[ticker].trades.push(t);
      if (isUnusual(t)) {
        groupedTrades[ticker].unusual.push(t);
      }
      if (isSweep(t)) {
        groupedTrades[ticker].sweeps.push(t);
      }
      if (isSplit(t)) {
        groupedTrades[ticker].splits.push(t);
      }
      if (isBlock(t)) {
        groupedTrades[ticker].blocks.push(t);
      }
      if (isGoldenSweep(t)) {
        groupedTrades[ticker].goldenSweeps.push(t);
      }
    });

    Object.keys(groupedTrades).forEach((ticker) => {
      const tickerDetails = groupedTrades[ticker];
      const { putFlowVolume, putVolume, callVolume, putPremium, callPremium } = calculatePutFlow(
        tickerDetails.trades,
      );
      const { otm, expiry, contracts, premium, price, momentum, volumeGrowth } =
        calculateTickerOverview(tickerDetails.trades, dailyVolumeMap[ticker]);
      const {
        otm: unusualOtm,
        contracts: unusualContracts,
        premium: unusualPremium,
      } = calculateTickerOverview(tickerDetails.unusual, dailyVolumeMap[ticker]);
      const {
        otm: sweepOtm,
        contracts: sweepContracts,
        premium: sweepPremium,
      } = calculateTickerOverview(tickerDetails.sweeps, dailyVolumeMap[ticker]);
      const {
        otm: splitOtm,
        contracts: splitContracts,
        premium: splitPremium,
      } = calculateTickerOverview(tickerDetails.splits, dailyVolumeMap[ticker]);
      const {
        otm: blockOtm,
        contracts: blockContracts,
        premium: blockPremium,
      } = calculateTickerOverview(tickerDetails.blocks, dailyVolumeMap[ticker]);
      const {
        otm: goldenSweepOtm,
        contracts: goldenSweepContracts,
        premium: goldenSweepPremium,
      } = calculateTickerOverview(tickerDetails.goldenSweeps, dailyVolumeMap[ticker]);

      groupedTrades[ticker].putFlow = Math.round(putFlowVolume * 100);
      groupedTrades[ticker].expiry = Math.round(expiry);
      groupedTrades[ticker].otm = Math.round(otm);
      groupedTrades[ticker].momentum = momentum;
      groupedTrades[ticker].volumeGrowth = volumeGrowth;
      groupedTrades[ticker].price = price;
      groupedTrades[ticker].premium = premium;
      groupedTrades[ticker].contracts = contracts;
      groupedTrades[ticker].unusualOtm = Math.round(unusualOtm);
      groupedTrades[ticker].unusualContracts = unusualContracts;
      groupedTrades[ticker].unusualPremium = unusualPremium;
      groupedTrades[ticker].sweepOtm = Math.round(sweepOtm);
      groupedTrades[ticker].sweepContracts = sweepContracts;
      groupedTrades[ticker].sweepPremium = sweepPremium;
      groupedTrades[ticker].callVolume = callVolume;
      groupedTrades[ticker].callPremium = callPremium;
      groupedTrades[ticker].putVolume = putVolume;
      groupedTrades[ticker].putPremium = putPremium;
      groupedTrades[ticker].splitContracts = splitContracts;
      groupedTrades[ticker].splitPremium = splitPremium;
      groupedTrades[ticker].splitOtm = Math.round(splitOtm);
      groupedTrades[ticker].blockContracts = blockContracts;
      groupedTrades[ticker].blockPremium = blockPremium;
      groupedTrades[ticker].blockOtm = Math.round(blockOtm);
      groupedTrades[ticker].goldenSweepContracts = goldenSweepContracts;
      groupedTrades[ticker].goldenSweepPremium = goldenSweepPremium;
      groupedTrades[ticker].goldenSweepOtm = Math.round(goldenSweepOtm);
    });

    return groupedTrades;
  }, [trades, dailyVolumeMap, filterState]);

  const tradesByTicker = React.useMemo(() => {
    const searchTerm = searchValue.trim();
    const aggregateFilterFunction = getAggregateFilterFunction(filterState);
    const values = Object.values(tradesByTickerMap).filter((trade) => {
      if (searchTerm && !trade.ticker?.includes(searchTerm)) {
        return false;
      }

      if (isAggregateWatchListOnly && (!trade.ticker || !watchListTickers.includes(trade.ticker))) {
        return false;
      }

      return aggregateFilterFunction(trade);
    });
    let sortKey = DEFAULT_AGGREGATE_KEY;

    if (
      !aggregateSortKey ||
      aggregateSortKey === DEFAULT_AGGREGATE_KEY ||
      aggregateSortDirection === SortDirection.None
    ) {
      sortKey = DEFAULT_AGGREGATE_KEY;
    } else {
      sortKey = aggregateSortKey;
    }

    return values.sort((a, b) => {
      let first = aggregateSortDirection === SortDirection.Asc ? a : b;
      let last = aggregateSortDirection === SortDirection.Asc ? b : a;

      // @ts-ignore
      if (first[sortKey] === last[sortKey]) {
        return first.contracts - last.contracts;
      }

      // @ts-ignore
      if (first[sortKey] < last[sortKey]) {
        return -1;
      }
      // @ts-ignore
      if (first[sortKey] > last[sortKey]) {
        return 1;
      }
      return 0;
    });
  }, [
    tradesByTickerMap,
    aggregateSortKey,
    aggregateSortDirection,
    searchValue,
    watchListTickers,
    isAggregateWatchListOnly,
    filterState,
  ]);

  const filteredTrades = React.useMemo(() => {
    const flowFilterFunction = getFlowFilterFunction(filterState, tradesByTickerMap);
    const filteredTrades = trades.filter(flowFilterFunction);

    if (isFlowWatchListOnly) {
      return filteredTrades.filter((trade) => {
        return !!trade.ticker && watchListTickers.includes(trade.ticker);
      });
    }

    return filteredTrades;
  }, [trades, filterState, tradesByTickerMap, watchListTickers, isFlowWatchListOnly]);

  const searchedTrades = React.useMemo(() => {
    const searchedTicker = searchValue.trim();
    if (!searchedTicker) {
      return filteredTrades;
    } else {
      return filteredTrades.filter((t) => t.ticker?.includes(searchedTicker));
    }
  }, [filteredTrades, searchValue]);

  const displayedTrades = React.useMemo(() => {
    let sortKey = DEFAULT_FLOW_KEY;

    if (
      !flowSortKey ||
      flowSortKey === DEFAULT_FLOW_KEY ||
      flowSortDirection === SortDirection.None
    ) {
      sortKey = DEFAULT_FLOW_KEY;
    } else {
      sortKey = flowSortKey;
    }

    const columnType = FLOW_TABLE_HEADERS.find((header) => header.sortKey === sortKey)?.columnType;
    let tradesToSort = [];

    if (columnType === ColumnType.Date) {
      tradesToSort = searchedTrades.map((t) => ({
        ...t,
        expiry: new Date(t.expiryString).getTime(),
      }));
    } else {
      tradesToSort = [...searchedTrades];
    }

    const sortedTrades = tradesToSort.sort((a, b) => {
      let first = flowSortDirection === SortDirection.Asc ? a : b;
      let last = flowSortDirection === SortDirection.Asc ? b : a;

      // @ts-ignore
      if (first[sortKey] === last[sortKey]) {
        return (first.size || 0) - (last.size || 0);
      }

      // @ts-ignore
      if (first[sortKey] < last[sortKey]) {
        return -1;
      }
      // @ts-ignore
      if (first[sortKey] > last[sortKey]) {
        return 1;
      }
      return 0;
    });

    return sortedTrades;
  }, [searchedTrades, flowSortKey, flowSortDirection]);

  const unusualTrades = displayedTrades.filter((t) => isUnusual(t));
  const sweepTrades = displayedTrades.filter((t) => isSweep(t));
  const { putFlowVolume, putVolume, callVolume } = calculatePutFlow(displayedTrades);
  const { putVolume: unusualPutVolume, callVolume: unusualCallVolume } =
    calculatePutFlow(unusualTrades);
  const { putVolume: sweepPutVolume, callVolume: sweepCallVolume } = calculatePutFlow(sweepTrades);

  const filteredDarkpoolTrades = React.useMemo(() => {
    const darkPoolFilterFunction = getDarkPoolFilterFunction(filterState);
    const filteredDarkpoolTrades = darkpoolTrades.filter(darkPoolFilterFunction);

    if (isFlowWatchListOnly) {
      return filteredDarkpoolTrades.filter((trade) => {
        return !!trade.ticker && watchListTickers.includes(trade.ticker);
      });
    }

    return filteredDarkpoolTrades;
  }, [darkpoolTrades, filterState, watchListTickers, isFlowWatchListOnly]);

  React.useEffect(() => {
    // @ts-ignore doing this for puppeteer
    window.RENDERED_DATA = { tradesByTicker };
  }, [tradesByTicker]);

  React.useEffect(() => {
    if (isFlowSoundEnabled && displayedTrades.length > 0) {
      try {
        audio?.play();
      } catch (_error) {
        // NOTE: Ignore error. Throws if user hasn't interacted with doc
      }
    }
  }, [audio, isFlowSoundEnabled, displayedTrades.length]);

  return (
    <SidebarPage hideFooter hideNav isPreventScroll>
      {!!injectedPageData && <div id="hasData" style={{ display: 'none' }} />}
      <div className="h-full w-full">
        <PageTitle
          Icon={Icon}
          title={title}
          right={
            <div className="flex w-auto items-center justify-end md:w-2/3">
              <div className="mr-2 hidden w-full max-w-3xl items-center xl:flex">
                <span
                  className="mr-2 flex-shrink-0 font-semibold uppercase"
                  style={{ fontSize: '11px' }}
                >
                  Actionable Presets
                </span>
                <Slider
                  items={PRESET_TABS}
                  activeTab={presetIndex}
                  setActiveTab={(itemIndex: PresetType) => {
                    const presetObject = presetObjects[itemIndex];
                    const presetAggregateSort = presetAggregateSortKey[itemIndex];
                    const presetGraph = presetGraphIndex[itemIndex];
                    setPresetIndex(itemIndex);

                    if (presetObject) {
                      dispatch({
                        type: ActionTypes.Set,
                        payload: { items: { ...filterState, ...presetObject } },
                      });
                    }

                    if (presetAggregateSort) {
                      setAggregateSortKey(presetAggregateSort);
                      setAggregateSortDirection(SortDirection.Desc);
                    }

                    if (presetGraph || presetGraph === 0) {
                      setTopTickerGraph(presetGraph);
                    }

                    if (itemIndex === PresetType.Custom) {
                      setIsCommandCenterOpen(true);
                    }

                    if (viewer?.viewer?.uid && presetObject) {
                      updateFiltersMutation({
                        variables: {
                          id: viewer.viewer.uid,
                          optionFlowFilters: presetObject,
                        },
                      });
                    }
                  }}
                />
              </div>
              <form
                className="hidden sm:block"
                onSubmit={(e) => {
                  e.preventDefault();
                  if (!searchValue.trim()) {
                    return;
                  }
                  window.open(`${TICKER_FLOW_PAGE}/${searchValue.trim()}`, '_blank');
                }}
              >
                <MiniInput
                  isSearch
                  placeholder="Search ticker (ex. TSLA)"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value.toUpperCase())}
                  reset={() => setSearchValue('')}
                />
              </form>
            </div>
          }
        />
        <LayoutGrid enableTripleColumns={!injectedPageData}>
          <MainColumnDynamic
            rows={getRowsForLayout(layoutIndex)}
            autoRows={HEADER_ROW_COUNT + additonalRows}
          >
            {shouldShowDatePicker && (
              <div>
                <Card className="w-full">
                  <form
                    autoComplete="off"
                    className="flex items-end"
                    onSubmit={(e) => {
                      e.preventDefault();
                      !!handleHistoricalSearch && handleHistoricalSearch();
                    }}
                  >
                    <FieldWrapper className="mr-2 w-full">
                      <Label>Start Date</Label>
                      <DatePicker
                        datePickerType="single"
                        value={startDate}
                        onChange={(_d, dateString) => {
                          !!setStartDate && setStartDate(dateString);
                        }}
                      >
                        <DatePickerInput
                          hideLabel
                          placeholder="mm/dd/yyyy"
                          labelText=""
                          id="start-date-calendar"
                        />
                      </DatePicker>
                    </FieldWrapper>
                    <FieldWrapper className="mr-2 w-full">
                      <Label>End Date</Label>
                      <DatePicker
                        datePickerType="single"
                        value={endDate}
                        onChange={(_d, dateString) => {
                          !!setEndDate && setEndDate(dateString);
                        }}
                      >
                        <DatePickerInput
                          hideLabel
                          placeholder="mm/dd/yyyy"
                          labelText=""
                          id="end-date-calendar"
                        />
                      </DatePicker>
                    </FieldWrapper>
                    <FieldWrapper className="mr-2 w-full">
                      <Label>Tickers</Label>
                      <Input
                        value={tickers}
                        placeholder="Ex. TSLA,AAPL,GME"
                        onChange={(e) => !!setTickers && setTickers(e.target.value.toUpperCase())}
                      />
                    </FieldWrapper>
                    <FieldWrapper className="mr-2 w-full">
                      <Label>Results (max {MAX_HISTORICAL_RESULTS})</Label>
                      <Input
                        value={maxResults === null ? '' : maxResults}
                        onChange={(e) => {
                          !!setMaxResults && setMaxResults(parseInt(e.target.value, 10) || null);
                        }}
                        type="number"
                        placeholder="Ex. 500"
                        min={0}
                        max={MAX_HISTORICAL_RESULTS}
                        step={1}
                      />
                    </FieldWrapper>
                    <Button type="submit">Search</Button>
                  </form>
                </Card>
              </div>
            )}
            <CardRow>
              <Card className="w-full md:w-1/3">
                <div className="flex items-center">
                  <Dropdown
                    containerStyles={{ width: '100%' }}
                    wrapperStyles={{ width: '100%' }}
                    triggerStyles={{ width: '100%', minWidth: 'auto' }}
                    triggerText={
                      PAGE_DROPDOWN_ITEMS.find((item) => item.href === pageHref)?.name ||
                      'Choose Active Flow'
                    }
                    items={PAGE_DROPDOWN_ITEMS}
                  />
                  <CommandCenter
                    isCommandCenterOpen={isCommandCenterOpen}
                    setIsCommandCenterOpen={setIsCommandCenterOpen}
                    currentFilters={filterState}
                    currentFilterCount={numberOfFilters}
                    setCurrentFilters={(newState) => {
                      dispatch({ type: ActionTypes.Set, payload: { items: newState } });
                    }}
                    resetCurrentFilters={() => {
                      dispatch({ type: ActionTypes.Reset });
                    }}
                    allOptionFlowTrades={trades}
                    allDarkPoolTrades={darkpoolTrades}
                    watchListTickers={watchListTickers}
                    tradesByTickerMap={tradesByTickerMap}
                  />
                </div>
                {flowSliderReplacement ? (
                  flowSliderReplacement
                ) : (
                  <>
                    <div className="mt-2 hidden lg:block">
                      <Slider
                        items={SLIDER_TABS}
                        activeTab={layoutIndex}
                        setActiveTab={setLayoutIndex}
                      />
                    </div>
                    <div className="mt-2 block lg:hidden">
                      <div className="flex justify-center">
                        <div className="relative w-full">
                          <select
                            value={mobileLayoutIndex}
                            onChange={(e) => {
                              e.preventDefault();

                              if (
                                e.target.value === '0' ||
                                e.target.value === '1' ||
                                e.target.value === '2'
                              ) {
                                setMobileLayoutIndex(parseInt(e.target.value, 10));
                              } else {
                                setMobileLayoutIndex(e.target.value);
                              }
                            }}
                            className="form-select m-0
                                  block
                                  w-full
                                  appearance-none
                                  rounded
                                  border
                                  border-solid
                                  border-button-color
                                  bg-palette-black-2 bg-clip-padding bg-no-repeat
                                  px-3 py-1.5 text-sm
                                  font-normal
                                  text-palette-black-9
                                  transition
                                  ease-in-out
                                  focus:border-blue-600 focus:bg-palette-black-2 focus:outline-none"
                            aria-label="Default select example"
                          >
                            <option value={LayoutType.Stacked}>Stacked</option>
                            <option value={LayoutType.Market}>Market</option>
                            <option value={LayoutType.Flow}>Option Flow</option>
                            {rightColumnItems.map((item) => (
                              <option key={item.key} value={item.key}>
                                {item.text}
                              </option>
                            ))}
                          </select>
                          <div className="absolute right-3 top-2 pt-0.5">
                            <CaretDown16 />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Card>
              <PutCallCard
                className="ml-2 hidden md:flex"
                tradeCount={trades.length}
                putFlow={putFlowVolume}
                putVolume={putVolume}
                callVolume={callVolume}
              />
              <StatCard
                title="Contracts Traded"
                topRow={`${abbreviateNumber(unusualCallVolume + unusualPutVolume)} Unusual`}
                bottomRow={`${abbreviateNumber(sweepCallVolume + sweepPutVolume)} Sweeps`}
                stat={`${abbreviateNumber(callVolume + putVolume, 1)}`}
              />
            </CardRow>
            <div
              className={
                layoutIndex === LayoutType.Market || layoutIndex === LayoutType.Stacked
                  ? 'hidden lg:block'
                  : 'hidden'
              }
            >
              <OptionAggregatedTable
                isWeekly={isWeekly}
                tradesByTicker={tradesByTicker}
                darkPoolByTicker={darkPoolByTicker}
                aggregateSortKey={aggregateSortKey}
                setAggregateSortKey={setAggregateSortKey}
                aggregateSortDirection={aggregateSortDirection}
                setAggregateSortDirection={setAggregateSortDirection}
                isWatchListOnly={isAggregateWatchListOnly}
                setIsWatchListOnly={setIsAggregateWatchListOnly}
                hasWatchListItems={hasWatchListItems}
                watchListTickers={watchListTickers}
                hasAccess={hasAccess || !!injectedPageData}
              />
            </div>
            <div
              className={
                layoutIndex === LayoutType.Flow || layoutIndex === LayoutType.Stacked
                  ? 'hidden lg:block'
                  : 'hidden'
              }
            >
              <OptionFlowTable
                totalFlowCount={totalFlowCount}
                trades={displayedTrades}
                flowSortKey={flowSortKey}
                setFlowSortKey={setFlowSortKey}
                flowSortDirection={flowSortDirection}
                setFlowSortDirection={setFlowSortDirection}
                isWatchListOnly={isFlowWatchListOnly}
                setIsWatchListOnly={setIsFlowWatchListOnly}
                hasWatchListItems={hasWatchListItems}
                isSoundEnabled={isFlowSoundEnabled}
                setIsSoundEnabled={setIsFlowSoundEnabled}
                watchListTickers={watchListTickers}
                hasAccess={hasAccess || !!injectedPageData}
              />
            </div>
            <div className="grid h-full grid-rows-2 gap-2 lg:hidden">
              {mobileLayoutIndex === LayoutType.Stacked && (
                <>
                  <div className="overflow-hidden">
                    <OptionAggregatedTable
                      isWeekly={isWeekly}
                      tradesByTicker={tradesByTicker}
                      darkPoolByTicker={darkPoolByTicker}
                      aggregateSortKey={aggregateSortKey}
                      setAggregateSortKey={setAggregateSortKey}
                      aggregateSortDirection={aggregateSortDirection}
                      setAggregateSortDirection={setAggregateSortDirection}
                      isWatchListOnly={isAggregateWatchListOnly}
                      setIsWatchListOnly={setIsAggregateWatchListOnly}
                      hasWatchListItems={hasWatchListItems}
                      watchListTickers={watchListTickers}
                      hasAccess={hasAccess || !!injectedPageData}
                    />
                  </div>
                  <div className="overflow-hidden">
                    <OptionFlowTable
                      totalFlowCount={totalFlowCount}
                      trades={displayedTrades}
                      flowSortKey={flowSortKey}
                      setFlowSortKey={setFlowSortKey}
                      flowSortDirection={flowSortDirection}
                      setFlowSortDirection={setFlowSortDirection}
                      isWatchListOnly={isFlowWatchListOnly}
                      setIsWatchListOnly={setIsFlowWatchListOnly}
                      hasWatchListItems={hasWatchListItems}
                      isSoundEnabled={isFlowSoundEnabled}
                      setIsSoundEnabled={setIsFlowSoundEnabled}
                      watchListTickers={watchListTickers}
                      hasAccess={hasAccess || !!injectedPageData}
                    />
                  </div>
                </>
              )}
              {mobileLayoutIndex === LayoutType.Market && (
                <div className="row-span-2 overflow-hidden">
                  <OptionAggregatedTable
                    isWeekly={isWeekly}
                    tradesByTicker={tradesByTicker}
                    darkPoolByTicker={darkPoolByTicker}
                    aggregateSortKey={aggregateSortKey}
                    setAggregateSortKey={setAggregateSortKey}
                    aggregateSortDirection={aggregateSortDirection}
                    setAggregateSortDirection={setAggregateSortDirection}
                    isWatchListOnly={isAggregateWatchListOnly}
                    setIsWatchListOnly={setIsAggregateWatchListOnly}
                    hasWatchListItems={hasWatchListItems}
                    watchListTickers={watchListTickers}
                    hasAccess={hasAccess || !!injectedPageData}
                  />
                </div>
              )}
              {mobileLayoutIndex === LayoutType.Flow && (
                <div className="row-span-2 overflow-hidden">
                  <OptionFlowTable
                    totalFlowCount={totalFlowCount}
                    trades={displayedTrades}
                    flowSortKey={flowSortKey}
                    setFlowSortKey={setFlowSortKey}
                    flowSortDirection={flowSortDirection}
                    setFlowSortDirection={setFlowSortDirection}
                    isWatchListOnly={isFlowWatchListOnly}
                    setIsWatchListOnly={setIsFlowWatchListOnly}
                    hasWatchListItems={hasWatchListItems}
                    isSoundEnabled={isFlowSoundEnabled}
                    setIsSoundEnabled={setIsFlowSoundEnabled}
                    watchListTickers={watchListTickers}
                    hasAccess={hasAccess || !!injectedPageData}
                  />
                </div>
              )}
              {mobileLayoutIndex === RightColumnList.TickerGraphs && (
                <div className="row-span-2 overflow-hidden">
                  <TopTickerGraphs presetGraph={topTickerGraph} tradesByTicker={tradesByTicker} />
                </div>
              )}
              {mobileLayoutIndex === RightColumnList.SectorGraphs && (
                <div className="row-span-2 overflow-hidden">
                  <SectorGraphs
                    tradesBySector={tradesBySector}
                    isUnusualFiltered={filterState.unusual}
                    isSweepsFiltered={filterState.sweeps}
                    activeSectorGraph={activeSectorGraph}
                    setActiveSectorGraph={setActiveSectorGraph}
                  />
                </div>
              )}
              {mobileLayoutIndex === RightColumnList.DarkPool && (
                <div className="row-span-2 overflow-hidden">
                  <DarkPoolTable
                    isWatchListOnly={isFlowWatchListOnly}
                    setIsWatchListOnly={setIsFlowWatchListOnly}
                    hasWatchListItems={hasWatchListItems}
                    watchListTickers={watchListTickers}
                    viewer={viewer}
                    searchValue={searchValue}
                    hasAccess={hasAccess || !!injectedPageData}
                    trades={filteredDarkpoolTrades}
                    loading={loading}
                    error={error}
                  />
                </div>
              )}
              {mobileLayoutIndex === RightColumnList.DarkPoolCharts && (
                <div className="row-span-2 overflow-hidden">
                  <DarkPoolGraphs
                    presetGraph={topTickerGraph}
                    tradesByTicker={darkPoolByTicker}
                    tradesBySector={darkPoolBySector}
                  />
                </div>
              )}
              {mobileLayoutIndex === RightColumnList.Signals && (
                <div className="row-span-2 overflow-hidden">
                  <SignalAlertTable
                    openInNewTab
                    watchListTickers={watchListTickers}
                    viewer={viewer}
                    searchValue={searchValue}
                  />
                </div>
              )}
              {mobileLayoutIndex === RightColumnList.WatchList && (
                <div className="row-span-2 overflow-hidden">
                  <WatchList
                    watchList={watchListData?.watch_list}
                    userId={props.userDetails?.id}
                    refetch={watchListRefetch}
                    isFlowWatchListOnly={isFlowWatchListOnly}
                    setIsFlowWatchListOnly={setIsFlowWatchListOnly}
                    isAggregateWatchListOnly={isAggregateWatchListOnly}
                    setIsAggregateWatchListOnly={setIsAggregateWatchListOnly}
                  />
                </div>
              )}
              {mobileLayoutIndex === RightColumnList.TopNews && (
                <div className="row-span-2 overflow-hidden">
                  <TopNewsEvents viewer={viewer} />
                </div>
              )}
              {mobileLayoutIndex === RightColumnList.TopMovers && (
                <div className="row-span-2 overflow-hidden">
                  <TopMoversTable watchListTickers={watchListTickers} />
                </div>
              )}
              {mobileLayoutIndex === RightColumnList.MostResearched && (
                <div className="row-span-2 overflow-hidden">
                  <MostActiveTickers watchListTickers={watchListTickers} viewer={viewer} />
                </div>
              )}
            </div>
          </MainColumnDynamic>
          {/* NOTE: Hiding this since they don't get a choice at large screen and either shows graphs or a 4x4 grid */}
          <HideLarge className="h-full">
            <StackedColumn enableTripleColumns={!injectedPageData} numRows={2}>
              <div className="h-full">
                <LayoutButtons className="mb-2 flex items-center justify-end">
                  <div className="mr-4 text-xs font-semibold text-gray-400">Select Layout</div>
                  <Menu as="div" className="relative inline-block text-left">
                    <div className="w-56">
                      <Menu.Button className="flex w-full items-center justify-between rounded-md border border-brand-primary bg-palette-black-1 px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-brand-primary">
                        {
                          rightColumnItems?.find((item) => item.key === activeRightColumnSectionTop)
                            ?.name
                        }
                        <CaretDown16 />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={React.Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-1 w-56 origin-top-right rounded-md border border-palette-border bg-palette-black-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {rightColumnItems.map((item) => (
                          <Menu.Item key={item.key}>
                            {({ active }) => (
                              <button
                                onClick={() => {
                                  setActiveRightColumnSectionTop(item.key);
                                  trackEvent(FLOW_TOGGLE_SIDE_MODULE, {
                                    module: item.key,
                                    section: 'top',
                                  });
                                }}
                                className={classNames(
                                  active ? 'bg-brand-primary text-white' : 'text-gray-200',
                                  'flex w-full items-center px-4 py-3 text-xs',
                                )}
                              >
                                {item.name}
                              </button>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </LayoutButtons>
                {/* <Card className="h-full w-full flex flex-col" isDark>
                  <DropdownToggleWrapper className="flex justify-between items-center">
                    <div className="text-sm font-semibold">Change Layout</div>
                    <div className="w-1/2 ml-1">
                      <Dropdown
                        isButtonPrimary
                        triggerStyles={{ width: '100%' }}
                        wrapperStyles={{ width: '100%' }}
                        triggerText={
                          rightColumnItems.find((item) => item.key === activeRightColumnSection)
                            ?.name || ''
                        }
                        items={rightColumnItems.map((item) => ({
                          name: item.name,
                          onClick: () => setActiveRightColumnSection(item.key),
                        }))}
                      />
                    </div>
                  </DropdownToggleWrapper>
                </Card> */}
                <LayoutButtonRow>
                  {activeRightColumnSectionTop === RightColumnList.TickerGraphs && (
                    <TopTickerGraphs presetGraph={topTickerGraph} tradesByTicker={tradesByTicker} />
                  )}
                  {activeRightColumnSectionTop === RightColumnList.SectorGraphs && (
                    <SectorGraphs
                      tradesBySector={tradesBySector}
                      isUnusualFiltered={filterState.unusual}
                      isSweepsFiltered={filterState.sweeps}
                      activeSectorGraph={activeSectorGraph}
                      setActiveSectorGraph={setActiveSectorGraph}
                    />
                  )}
                  {activeRightColumnSectionTop === RightColumnList.DarkPool && (
                    <DarkPoolTable
                      isWatchListOnly={isFlowWatchListOnly}
                      setIsWatchListOnly={setIsFlowWatchListOnly}
                      hasWatchListItems={hasWatchListItems}
                      watchListTickers={watchListTickers}
                      viewer={viewer}
                      searchValue={searchValue}
                      hasAccess={hasAccess || !!injectedPageData}
                      trades={filteredDarkpoolTrades}
                      loading={loading}
                      error={error}
                    />
                  )}
                  {activeRightColumnSectionTop === RightColumnList.DarkPoolCharts && (
                    <DarkPoolGraphs
                      presetGraph={topTickerGraph}
                      tradesByTicker={darkPoolByTicker}
                      tradesBySector={darkPoolBySector}
                    />
                  )}
                  {activeRightColumnSectionTop === RightColumnList.Signals && (
                    <SignalAlertTable
                      openInNewTab
                      watchListTickers={watchListTickers}
                      viewer={viewer}
                      searchValue={searchValue}
                    />
                  )}
                  {activeRightColumnSectionTop === RightColumnList.WatchList && (
                    <WatchList
                      watchList={watchListData?.watch_list}
                      userId={props.userDetails?.id}
                      refetch={watchListRefetch}
                      isFlowWatchListOnly={isFlowWatchListOnly}
                      setIsFlowWatchListOnly={setIsFlowWatchListOnly}
                      isAggregateWatchListOnly={isAggregateWatchListOnly}
                      setIsAggregateWatchListOnly={setIsAggregateWatchListOnly}
                    />
                  )}
                  {activeRightColumnSectionTop === RightColumnList.TopNews && (
                    <TopNewsEvents viewer={viewer} />
                  )}
                  {activeRightColumnSectionTop === RightColumnList.TopMovers && (
                    <TopMoversTable watchListTickers={watchListTickers} />
                  )}
                  {activeRightColumnSectionTop === RightColumnList.MostResearched && (
                    <MostActiveTickers watchListTickers={watchListTickers} viewer={viewer} />
                  )}
                </LayoutButtonRow>
              </div>
              <div className="h-full">
                <LayoutButtons className="mb-2 flex items-center justify-end">
                  <div className="mr-4 text-xs font-semibold text-gray-400">Select Layout</div>
                  <Menu as="div" className="relative inline-block text-left">
                    <div className="w-56">
                      <Menu.Button className="flex w-full items-center justify-between rounded-md border border-brand-primary bg-palette-black-1 px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-brand-primary">
                        {
                          rightColumnItems?.find((item) => item.key === activeRightColumnSectionBot)
                            ?.name
                        }
                        <CaretDown16 />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={React.Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-1 h-64 w-56 origin-top-right overflow-auto rounded-md border border-palette-border bg-palette-black-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {rightColumnItems.map((item) => (
                          <Menu.Item key={item.key}>
                            {({ active }) => (
                              <button
                                onClick={() => {
                                  setActiveRightColumnSectionBot(item.key);
                                  trackEvent(FLOW_TOGGLE_SIDE_MODULE, {
                                    module: item.key,
                                    section: 'bottom',
                                  });
                                }}
                                className={classNames(
                                  active ? 'bg-brand-primary text-white' : 'text-gray-200',
                                  'flex w-full items-center px-4 py-3 text-xs',
                                )}
                              >
                                {item.name}
                              </button>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </LayoutButtons>
                <LayoutButtonRow>
                  {activeRightColumnSectionBot === RightColumnList.TickerGraphs && (
                    <TopTickerGraphs presetGraph={topTickerGraph} tradesByTicker={tradesByTicker} />
                  )}
                  {activeRightColumnSectionBot === RightColumnList.SectorGraphs && (
                    <SectorGraphs
                      tradesBySector={tradesBySector}
                      isUnusualFiltered={filterState.unusual}
                      isSweepsFiltered={filterState.sweeps}
                      activeSectorGraph={activeSectorGraph}
                      setActiveSectorGraph={setActiveSectorGraph}
                    />
                  )}
                  {activeRightColumnSectionBot === RightColumnList.DarkPool && (
                    <DarkPoolTable
                      isWatchListOnly={isFlowWatchListOnly}
                      setIsWatchListOnly={setIsFlowWatchListOnly}
                      hasWatchListItems={hasWatchListItems}
                      watchListTickers={watchListTickers}
                      viewer={viewer}
                      searchValue={searchValue}
                      hasAccess={hasAccess || !!injectedPageData}
                      trades={filteredDarkpoolTrades}
                      loading={loading}
                      error={error}
                    />
                  )}
                  {activeRightColumnSectionBot === RightColumnList.DarkPoolCharts && (
                    <DarkPoolGraphs
                      presetGraph={topTickerGraph}
                      tradesByTicker={darkPoolByTicker}
                      tradesBySector={darkPoolBySector}
                    />
                  )}
                  {activeRightColumnSectionBot === RightColumnList.Signals && (
                    <SignalAlertTable
                      openInNewTab
                      watchListTickers={watchListTickers}
                      viewer={viewer}
                      searchValue={searchValue}
                    />
                  )}
                  {activeRightColumnSectionBot === RightColumnList.WatchList && (
                    <WatchList
                      watchList={watchListData?.watch_list}
                      userId={props.userDetails?.id}
                      refetch={watchListRefetch}
                      isFlowWatchListOnly={isFlowWatchListOnly}
                      setIsFlowWatchListOnly={setIsFlowWatchListOnly}
                      isAggregateWatchListOnly={isAggregateWatchListOnly}
                      setIsAggregateWatchListOnly={setIsAggregateWatchListOnly}
                    />
                  )}
                  {activeRightColumnSectionBot === RightColumnList.TopNews && (
                    <TopNewsEvents viewer={viewer} />
                  )}
                  {activeRightColumnSectionBot === RightColumnList.TopMovers && (
                    <TopMoversTable watchListTickers={watchListTickers} />
                  )}
                  {activeRightColumnSectionBot === RightColumnList.MostResearched && (
                    <MostActiveTickers watchListTickers={watchListTickers} viewer={viewer} />
                  )}
                </LayoutButtonRow>
              </div>
            </StackedColumn>
          </HideLarge>
          {/* NOTE: Always showing graphs large and hide the toggle option */}
          <ShowLarge>
            <StackedColumn enableTripleColumns={!injectedPageData} numRows={2}>
              <TopTickerGraphs presetGraph={topTickerGraph} tradesByTicker={tradesByTicker} />
              <SectorGraphs
                tradesBySector={tradesBySector}
                isUnusualFiltered={filterState.unusual}
                isSweepsFiltered={filterState.sweeps}
                activeSectorGraph={activeSectorGraph}
                setActiveSectorGraph={setActiveSectorGraph}
              />
            </StackedColumn>
          </ShowLarge>
          <ShowTripleColumns enableTripleColumns={!injectedPageData}>
            <StackedColumn hasHeader numRows={2} showTall>
              <LayoutButtons className="flex items-center justify-end" showTall>
                <div className="mr-4 text-xs font-semibold text-gray-400">Select Layout</div>
                <Menu as="div" className="relative inline-block text-left">
                  <div className="w-56">
                    <Menu.Button className="flex w-full items-center justify-between rounded-md border border-brand-primary bg-palette-black-1 px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-brand-primary">
                      {
                        rightColumnItemsLarge?.find(
                          (item) => item.key === activeRightColumnSectionLarge,
                        )?.name
                      }
                      <CaretDown16 />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={React.Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-1 w-56 origin-top-right rounded-md border border-palette-border bg-palette-black-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {rightColumnItemsLarge.map((item) => (
                        <Menu.Item key={item.key}>
                          {({ active }) => (
                            <button
                              onClick={() => {
                                setActiveRightColumnSectionLarge(item.key);
                                trackEvent(FLOW_TOGGLE_SIDE_MODULE, {
                                  module: item.key,
                                  section: 'large',
                                });
                              }}
                              className={classNames(
                                active ? 'bg-brand-primary text-white' : 'text-gray-200',
                                'flex w-full items-center px-4 py-3 text-xs',
                              )}
                            >
                              {item.name}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </LayoutButtons>
              {activeRightColumnSectionLarge === RightColumnList.Signals && (
                <SignalAlertTable
                  openInNewTab
                  watchListTickers={watchListTickers}
                  viewer={viewer}
                  searchValue={searchValue}
                />
              )}
              {activeRightColumnSectionLarge === RightColumnList.WatchList && (
                <WatchList
                  watchList={watchListData?.watch_list}
                  userId={props.userDetails?.id}
                  refetch={watchListRefetch}
                  isFlowWatchListOnly={isFlowWatchListOnly}
                  setIsFlowWatchListOnly={setIsFlowWatchListOnly}
                  isAggregateWatchListOnly={isAggregateWatchListOnly}
                  setIsAggregateWatchListOnly={setIsAggregateWatchListOnly}
                />
              )}
              {activeRightColumnSectionLarge === RightColumnList.TopNews && (
                <TopNewsEvents viewer={viewer} />
              )}
              {activeRightColumnSectionLarge === RightColumnList.TopMovers && (
                <TopMoversTable watchListTickers={watchListTickers} />
              )}
              {activeRightColumnSectionLarge === RightColumnList.MostResearched && (
                <MostActiveTickers watchListTickers={watchListTickers} viewer={viewer} />
              )}
              {activeRightColumnSectionLarge === RightColumnList.DarkPoolCharts && (
                <DarkPoolGraphs
                  presetGraph={topTickerGraph}
                  tradesByTicker={darkPoolByTicker}
                  tradesBySector={darkPoolBySector}
                />
              )}
              <DarkPoolTable
                isWatchListOnly={isFlowWatchListOnly}
                setIsWatchListOnly={setIsFlowWatchListOnly}
                hasWatchListItems={hasWatchListItems}
                watchListTickers={watchListTickers}
                viewer={viewer}
                searchValue={searchValue}
                hasAccess={hasAccess || !!injectedPageData}
                trades={filteredDarkpoolTrades}
                loading={loading}
                error={error}
              />
            </StackedColumn>
          </ShowTripleColumns>
        </LayoutGrid>
      </div>
      {isShowFlowDescription && <FlowDescription />}
    </SidebarPage>
  );
};

export default OptionFlowPage;
