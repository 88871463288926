import styled from 'styled-components';
import { SolidButton, CancelButton, RawButton } from 'styles/elements';
import { ModalWidths, maxModalWidths } from 'components/Modal';
import { mobile } from 'styles/breakpoints';
import { rgba } from 'styles/utils/color';

export const CommandCenterButton = styled(SolidButton)`
  height: 33px;
  width: 33px;
  font-size: 17px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  margin-left: 0.5rem;
  padding: 0;
  box-shadow: ${({ theme }) => theme.shadow.medium};
  position: relative;
`;

const modalWidth = maxModalWidths[ModalWidths.ContentLarge];

export const CommandContent = styled.div`
  width: ${modalWidth};
  max-width: 96vw;
  height: 100%;
  max-height: 96vh;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: env(safe-area-inset-top, 0);
  padding-bottom: env(safe-area-inset-bottom, 0);

  ${mobile()} {
    max-height: 100%;
  }
`;

export const COMMAND_HEADER_HEIGHT = 60;

export const CommandHeader = styled.h3`
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  line-height: 1;
  height: ${COMMAND_HEADER_HEIGHT}px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

export const CommandBody = styled.div`
  height: 100%;
  flex-shrink: 1;
  overflow: auto;
`;

export const CommandFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: ${COMMAND_HEADER_HEIGHT}px;
  border-top: 1px solid ${({ theme }) => theme.color.border};
`;

export const CommandApplyButton = styled(SolidButton)`
  line-height: 1;
  padding: 8px 12px;
  width: auto;
  font-size: 14px;
  font-weight: 600;
  margin-left: 0.3rem;

  ${mobile()} {
    width: calc(50% - 0.3rem);
  }
`;

export const CommandCancelButton = styled(CancelButton)`
  line-height: 1;
  padding: 8px 12px;
  width: auto;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0.3rem;
  background-color: ${({ theme }) => theme.color.paper};
  border-color: ${({ theme }) => theme.color.paper};

  ${mobile()} {
    width: calc(50% - 0.3rem);
  }
`;

export const CommandButtonGroup = styled.div`
  opacity: 0.65;
  transition: opacity 250ms ease;
`;

export const CommandRow = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.border};

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    ${CommandButtonGroup} {
      opacity: 1;
    }
  }
`;

export const OutlineButton = styled(RawButton)<{ isActive: boolean }>`
  line-height: 1;
  padding: 8px 12px;
  margin-right: 0.5rem;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  transition: background-color 250ms ease, border 250ms ease, color 250ms ease;
  border: 1px solid ${({ theme }) => theme.color.textPrimary};
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.buttonPrimary : theme.color.textPrimary};

  &:last-of-type {
    margin-right: 0;
  }

  &:focus {
    outline: none;
  }
`;

export const OutlineButtonWhite = styled(OutlineButton)<{ isActive: boolean }>`
  border: 1px solid ${({ theme }) => theme.color.textPrimary};
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.buttonPrimary : theme.color.textPrimary};
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.color.textPrimary : 'transparent'};
`;

export const OutlineButtonUnusual = styled(OutlineButton)<{ isActive: boolean }>`
  border: 1px solid ${({ theme, isActive }) => (isActive ? theme.color.unusualTrade : 'inherit')};
  color: ${({ theme, isActive }) => (isActive ? theme.color.textPrimary : 'inherit')};
  background-color: ${({ theme, isActive }) => (isActive ? theme.color.unusualTrade : 'inherit')};

  &:hover {
    border: 1px solid ${({ theme }) => theme.color.unusualTrade};
    color: ${({ theme, isActive }) =>
      isActive ? theme.color.textPrimary : theme.color.unusualTrade};
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.color.unusualTrade : 'transparent'};
  }
`;

export const OutlineButtonSweep = styled(OutlineButton)<{ isActive: boolean }>`
  border: 1px solid ${({ theme, isActive }) => (isActive ? theme.color.graphSweep : 'inherit')};
  color: ${({ theme, isActive }) => (isActive ? theme.color.textPrimary : 'inherit')};
  background-color: ${({ theme, isActive }) => (isActive ? theme.color.graphSweep : 'inherit')};

  &:hover {
    border: 1px solid ${({ theme }) => theme.color.graphSweep};
    color: ${({ theme, isActive }) =>
      isActive ? theme.color.textPrimary : theme.color.graphSweep};
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.color.graphSweep : 'transparent'};
  }
`;

export const OutlineButtonCall = styled(OutlineButton)<{ isActive: boolean }>`
  border: 1px solid ${({ theme, isActive }) => (isActive ? theme.color.callBackground : 'inherit')};
  color: ${({ theme, isActive }) => (isActive ? theme.color.textPrimary : 'inherit')};
  background-color: ${({ theme, isActive }) => (isActive ? theme.color.callBackground : 'inherit')};

  &:hover {
    border: 1px solid ${({ theme }) => theme.color.callBackground};
    color: ${({ theme, isActive }) =>
      isActive ? theme.color.textPrimary : theme.color.callBackground};
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.color.callBackground : 'transparent'};
  }
`;

export const OutlineButtonPut = styled(OutlineButton)<{ isActive: boolean }>`
  border: 1px solid ${({ theme, isActive }) => (isActive ? theme.color.putBackground : 'inherit')};
  color: ${({ theme, isActive }) => (isActive ? theme.color.textPrimary : 'inherit')};
  background-color: ${({ theme, isActive }) => (isActive ? theme.color.putBackground : 'inherit')};

  &:hover {
    border: 1px solid ${({ theme }) => theme.color.putBackground};
    color: ${({ theme, isActive }) =>
      isActive ? theme.color.textPrimary : theme.color.putBackground};
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.color.putBackground : 'transparent'};
  }
`;

export const ResetBox = styled.div`
  border: 1px solid ${({ theme }) => theme.color.buttonPrimary};
  background-color: ${({ theme }) => rgba(theme.color.buttonPrimary, 0.15)};
  line-height: 1;
`;

export const ClearFiltersButton = styled(RawButton)`
  line-height: 1;
  display: flex;
  align-items: center;
`;

export const FilterCountBox = styled.div`
  background-color: ${({ theme }) => theme.color.putBackground};
  box-shadow: ${({ theme }) => theme.shadow.low};
  border-radius: 2px;
`;

export const FilterCountBoxSmall = styled.div`
  background-color: ${({ theme }) => theme.color.putBackground};
  box-shadow: ${({ theme }) => theme.shadow.low};
  border-radius: 2px;
  font-size: 8px;
  font-weight: 700;
  width: 13px;
  height: 13px;
  position: absolute;
  top: -8px;
  right: -8px;
`;
