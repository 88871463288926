import styled from 'styled-components';
import {
  Input as BaseInput,
  Label as BaseLabel,
  Button as BaseButton,
  RawButton,
} from 'styles/elements';
import { EXTRA_COLUMN_BREAK } from 'styles/breakpoints';
import Card from 'components/Card';

export const DROPDOWN_HEIGHT = 0;
export const EXTRA_ITEMS_EXCLUDED = `.5rem - ${DROPDOWN_HEIGHT}px`;

const HEIGHT = 36;

export const Input = styled(BaseInput)`
  background-color: ${({ theme }) => theme.color.backgroundSecondary};
  border-color: ${({ theme }) => theme.color.textMuted};
  height: ${HEIGHT}px;
  margin-bottom: 0;
  font-size: 12px;
  padding: 10px 12px;
`;

export const Label = styled(BaseLabel)`
  font-size: 12px;
  margin-bottom: 6px;
  line-height: 1;
`;

export const Button = styled(BaseButton)`
  width: auto;
  height: ${HEIGHT}px;
  font-size: 12px;
  font-weight: 600;
  padding: 0 14px;
  flex-shrink: 0;
`;

export const FieldWrapper = styled.div``;

export const DropdownToggleWrapper = styled.div``;

export const AddWatchListButton = styled(RawButton)`
  display: flex;
  border-left: 1px solid ${({ theme }) => theme.color.border};
  border-radius: 0.15rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  transition: background-color 200ms ease, border-color 200ms ease;
  font-weight: 600;
  color: ${(props) => props.theme.color.textPrimary};
  border: 2px solid ${(props) => props.theme.color.buttonPrimary};
  background-color: ${(props) => props.theme.color.buttonPrimary};

  &:hover {
    background-color: ${(props) => props.theme.color.textPrimary};
    border-color: ${(props) => props.theme.color.textPrimary};
    color: ${(props) => props.theme.color.buttonPrimary};
  }

  &:disabled {
    background-color: ${(props) => props.theme.color.textPrimary};
    border-color: ${(props) => props.theme.color.textPrimary};
    color: ${(props) => props.theme.color.buttonPrimary};
  }
`;

export const WatchListButton = styled(RawButton)`
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: transparent;
  transition: background-color 200ms ease;

  &:hover {
    background-color: ${(props) => props.theme.color.backgroundSecondary};
  }
`;

export const IconButton = styled(BaseButton)<{ isActive: boolean }>`
  padding: 0;
  height: 34px;
  width: 34px;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-width: 1px;
  border-color: ${({ theme, isActive }) =>
    isActive ? theme.color.buttonPrimary : theme.color.border};
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.color.buttonPrimary : theme.color.border};

  &:focus {
    outline: none;
  }
`;

export const ShowTripleColumns = styled.div<{ enableTripleColumns: boolean }>`
  display: none;

  @media (min-width: ${EXTRA_COLUMN_BREAK}px) {
    display: ${({ enableTripleColumns }) => (enableTripleColumns ? 'block' : 'none')};
  }
`;

export const HideLarge = styled.div`
  display: block;

  @media (min-width: ${EXTRA_COLUMN_BREAK}px) {
    display: none;
  }
`;

export const ShowLarge = styled.div`
  display: none;

  @media (min-width: ${EXTRA_COLUMN_BREAK}px) {
    display: block;
  }
`;

export const LayoutButtons = styled.div<{ showTall?: boolean }>`
  @media (min-width: ${EXTRA_COLUMN_BREAK}px) {
    display: ${({ showTall }) => (showTall ? 'flex' : 'none')} !important;
  }
`;

export const RemoveTickerButton = styled(RawButton)`
  color: ${({ theme }) => theme.color.putText};
  opacity: 0;
  padding: 0;
  transition: opacity 250ms ease;
  font-size: 8px;
  text-transform: uppercase;
`;

export const WatchlistTickerCard = styled(Card)`
  &:hover {
    ${RemoveTickerButton} {
      opacity: 1;
    }
  }
`;

export const LayoutButtonRow = styled.div`
  height: calc(100% - 34px - 0.5rem);
`;

export const FeedTypeToggle = styled(BaseButton)<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 0;
  height: 18px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: medium;
  font-size: 9px;
  border-width: 1px;
  border-color: ${({ theme, isActive }) =>
    isActive ? theme.color.buttonPrimary : theme.color.border};
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.color.buttonPrimary : theme.color.border};
  border-radius: 0;

  &:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:focus {
    outline: none;
  }
`;
