import styled from 'styled-components';
import { TableRow } from 'components/Table';
import { Button as BaseButton } from 'styles/elements';

export const AggregateRow = styled(TableRow)<{ tableBodyRow?: boolean }>`
  grid-template-columns:
    minmax(60px, 1fr) minmax(48px, 0.8fr) minmax(48px, 0.8fr) repeat(3, minmax(60px, 1fr)) minmax(
      48px,
      0.85fr
    )
    repeat(5, minmax(60px, 1fr)) minmax(48px, 0.8fr) repeat(2, minmax(60px, 1fr));
`;

export const DarkPoolGridRow = styled(TableRow)<{ tableBodyRow?: boolean }>`
  grid-template-columns: repeat(10, minmax(64px, 1fr));
`;

export const FeedTypeToggle = styled(BaseButton)<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 0;
  height: 24px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  border-width: 1px;
  border-color: ${({ theme, isActive }) =>
    isActive ? theme.color.buttonPrimary : theme.color.border};
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.color.buttonPrimary : theme.color.border};

  &:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:focus {
    outline: none;
  }
`;
