import React from 'react';

import { useCommandCenter } from 'hooks/useCommandCenter';
import { SIZE_OPTIONS_DP, TYPE_OPTIONS_DP, AMOUNT_OPTIONS_DP } from './filterOptions';
import { SliderDirection, EQUITY_FILTER } from 'utils/shared/trades/presets';

import SliderFilter from './Slider';
import { FilterButtonGroup } from './FilterButtonGroup';
import { FilterButtonGroupWithLabel } from './LabelFilterButtonGroup';

import { CommandRow } from './styles';

interface StateProps {
  state: ReturnType<typeof useCommandCenter>['state'];
  dispatch: ReturnType<typeof useCommandCenter>['dispatch'];
  ActionTypes: ReturnType<typeof useCommandCenter>['ActionTypes'];
}

interface RowProps {
  enabled?: boolean;
}

const Row: React.FC<React.PropsWithChildren<RowProps>> = ({ children, enabled }) => (
  <CommandRow
    className={`py-3 text-sm last:mb-0 ${
      enabled ? 'block' : 'flex flex-col justify-between md:flex-row md:items-center'
    }`}
  >
    {children}
  </CommandRow>
);
const RowTitle: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <div className="text-lg font-semibold">{children}</div>
);

export const DarkPollFilters = ({
  enabled = false,
  state,
  dispatch,
  ActionTypes,
}: RowProps & StateProps) => {
  return (
    <>
      <Row>
        <RowTitle>Type</RowTitle>
        <div className="flex  justify-end opacity-65 hover:opacity-100">
          <FilterButtonGroupWithLabel
            activeOption={state.typedp}
            options={TYPE_OPTIONS_DP}
            onClick={(selectedVal) => {
              const stateWithoutAll = state.typedp.filter((item) => item !== 'All');
              const newItems =
                selectedVal === 'All'
                  ? ['All']
                  : stateWithoutAll.includes(selectedVal)
                  ? stateWithoutAll.filter((item) => item !== selectedVal)
                  : [...stateWithoutAll, selectedVal];

              if (newItems.length === 0) {
                newItems.push('All');
              }

              dispatch({
                type: ActionTypes.Set,
                payload: { items: { typedp: newItems } },
              });
            }}
          />
        </div>
      </Row>
      <Row enabled={enabled}>
        <RowTitle>Size</RowTitle>
        <SliderFilter
          enabled={enabled}
          range={{ min: 150000, max: 2500000 }}
          absoluteMin={EQUITY_FILTER.minSizeDp}
          absoluteMax={EQUITY_FILTER.maxSizeDp}
          unit="K"
          step={1000}
          value={
            state.sizeDpDirection === SliderDirection.Below ? state.maxSizeDp : state.minSizeDp
          }
          direction={state.sizeDpDirection}
          onDirectionChange={(direction: SliderDirection) => {
            dispatch({
              type: ActionTypes.Set,
              payload: { items: { sizeDpDirection: direction } },
            });
          }}
          onChange={(min, max) => {
            dispatch({
              type: ActionTypes.Set,
              payload: { items: { minSizeDp: min, maxSizeDp: max } },
            });
          }}
        />
        {!enabled && (
          <FilterButtonGroup
            options={SIZE_OPTIONS_DP}
            minState={state.minSizeDp}
            maxState={state.maxSizeDp}
            onClick={(min, max) => {
              dispatch({
                type: ActionTypes.Set,
                payload: { items: { minSizeDp: min, maxSizeDp: max } },
              });
            }}
          />
        )}
      </Row>
      <Row enabled={enabled}>
        <RowTitle>Amount</RowTitle>
        <SliderFilter
          enabled={enabled}
          range={{ min: 1000000, max: 250_000_000 }}
          absoluteMin={EQUITY_FILTER.minAmtDp}
          absoluteMax={EQUITY_FILTER.maxAmtDp}
          unit="K"
          step={100000}
          value={state.amtDpDirection === SliderDirection.Below ? state.maxAmtDp : state.minAmtDp}
          direction={state.amtDpDirection}
          onDirectionChange={(direction: SliderDirection) => {
            dispatch({
              type: ActionTypes.Set,
              payload: { items: { amtDpDirection: direction } },
            });
          }}
          onChange={(min, max) => {
            dispatch({
              type: ActionTypes.Set,
              payload: { items: { minAmtDp: min, maxAmtDp: max } },
            });
          }}
        />
        {!enabled && (
          <FilterButtonGroup
            options={AMOUNT_OPTIONS_DP}
            minState={state.minAmtDp}
            maxState={state.maxAmtDp}
            onClick={(min, max) => {
              dispatch({
                type: ActionTypes.Set,
                payload: { items: { minAmtDp: min, maxAmtDp: max } },
              });
            }}
          />
        )}
      </Row>
    </>
  );
};
