import * as React from 'react';
import Link from 'components/Link';
import * as Sentry from '@sentry/nextjs';
import { RiArrowUpDownFill } from 'react-icons/ri';
import { AiFillStar } from 'react-icons/ai';
import { TICKER_FLOW_PAGE } from 'constants/pages';
import SectionTitle from 'components/SectionTitle';
import { Tab, TabWrapper } from 'components/Tab';
import {
  TableWrapper,
  TableCell,
  TableCellNoPad,
  TableHeader,
  TableBody,
  HeaderCell,
  HeaderCellWrapper,
  WatchListIcon,
} from 'components/Table';
import PulseLoader from 'components/Loading/Pulse';
import api from 'services/client/api';
import { MINUTE } from 'constants/time';
import { MoverValues, GAINERS, LOSERS } from 'constants/polygon';
import { Props, ApiResponse, TopMoversTableHeaderProps, TopMoversTableRowProps } from './props';

import { TopMoversRow } from './styles';

const TITLE_NAMES = ['Ticker', 'Price', '$ Change', '% Change', 'Low', 'High'];

const Header: React.FC<React.PropsWithChildren<TopMoversTableHeaderProps>> = React.memo(
  ({ activeMover, setActiveMover }) => {
    return (
      <TableHeader>
        <SectionTitle
          Icon={RiArrowUpDownFill}
          title="Top Movers"
          right={
            <TabWrapper>
              <Tab isActive={activeMover === GAINERS} onClick={() => setActiveMover(GAINERS)}>
                Gainers
              </Tab>
              <Tab isActive={activeMover === LOSERS} onClick={() => setActiveMover(LOSERS)}>
                Losers
              </Tab>
            </TabWrapper>
          }
        />
        <TopMoversRow removePad>
          {TITLE_NAMES.map((t, i) => {
            return (
              <HeaderCellWrapper
                key={t}
                removePad={i + 1 === TITLE_NAMES.length}
                isActiveSort={false}
                onClick={() => {}}
              >
                <HeaderCell>
                  <span className="cell-name">{t}</span>
                </HeaderCell>
              </HeaderCellWrapper>
            );
          })}
        </TopMoversRow>
      </TableHeader>
    );
  },
);

const Row: React.FC<React.PropsWithChildren<TopMoversTableRowProps>> = React.memo(
  ({ item, watchListTickers, activeMover }) => {
    const isWatchListTrade = !!item.ticker && watchListTickers.includes(item.ticker);
    const leadingSymbol = activeMover === GAINERS ? '+' : '-';
    return (
      <TopMoversRow tableBodyRow isAnimated>
        <TableCell className="relative">
          {isWatchListTrade && (
            <WatchListIcon className="absolute text-yellow-400">
              <AiFillStar />
            </WatchListIcon>
          )}
          <Link href={`${TICKER_FLOW_PAGE}/${item.ticker}`}>{item.ticker}</Link>
        </TableCell>
        <TableCell>${item.lastTrade.p.toFixed(2)}</TableCell>
        <TableCell>
          {leadingSymbol}${Math.abs(item.todaysChange).toFixed(2)}
        </TableCell>
        <TableCell>
          {leadingSymbol}
          {Math.abs(item.todaysChangePerc).toFixed(0)}%
        </TableCell>
        {/* <TableCell>${item.day.o}</TableCell> */}
        <TableCell>${item.day.l.toFixed(2)}</TableCell>
        <TableCellNoPad>${item.day.h.toFixed(2)}</TableCellNoPad>
      </TopMoversRow>
    );
  },
);

const TopMoversTable: React.FC<React.PropsWithChildren<Props>> = ({ watchListTickers }) => {
  const [activeMover, setActiveMover] = React.useState<MoverValues>(GAINERS);
  const [isLoading, setIsLoading] = React.useState(false);
  const [apiResponse, setApiResponse] = React.useState<ApiResponse | null>(null);
  const gainers = apiResponse?.gainers?.tickers || [];
  const losers = apiResponse?.losers?.tickers || [];
  const selectedMoverItems = activeMover === GAINERS ? gainers : losers;

  React.useEffect(() => {
    api
      .get('products/movers')
      .then((resp: ApiResponse) => {
        setIsLoading(false);
        setApiResponse(resp);
      })
      .catch((err) => {
        setIsLoading(false);
        Sentry.withScope((_scope) => {
          Sentry.captureException(err);
        });
        setApiResponse(null);
      });

    let interval = setInterval(() => {
      setIsLoading(true);
      api
        .get('products/movers')
        .then((resp: ApiResponse) => {
          setIsLoading(false);
          setApiResponse(resp);
        })
        .catch((err) => {
          setIsLoading(false);
          Sentry.withScope((_scope) => {
            Sentry.captureException(err);
          });
        });
    }, 10 * MINUTE);

    return () => clearInterval(interval);
  }, []);

  return (
    <TableWrapper>
      <Header activeMover={activeMover} setActiveMover={setActiveMover} />
      <TableBody animateRows>
        {isLoading ? (
          <div className="flex h-full items-center justify-center">
            <PulseLoader />
          </div>
        ) : (
          selectedMoverItems.map((item) => {
            return (
              <Row
                key={item.ticker}
                item={item}
                watchListTickers={watchListTickers}
                activeMover={activeMover}
              />
            );
          })
        )}
      </TableBody>
    </TableWrapper>
  );
};

export default TopMoversTable;
