import styled from 'styled-components';
import { RawButton } from 'styles/elements';

const SLIDER_PAD = 5;
const HEIGHT = 34;
const BORDER_WIDTH = 2;

export const SliderWrapper = styled.div`
  border-radius: 4px;
  padding: ${SLIDER_PAD}px;
  height: ${HEIGHT}px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.backgroundDistinct};
  display: flex;
  align-items: center;
  position: relative;
  border: ${BORDER_WIDTH / 2}px solid ${({ theme }) => theme.color.buttonPrimary};
`;

export const SliderActive = styled.div<{ tabs: number; activeTab: number }>`
  width: calc(${({ tabs }) => `(100% - ${2 * SLIDER_PAD}px) / ${tabs}`});
  background-color: ${({ theme }) => theme.color.border};
  height: ${HEIGHT - 2 * SLIDER_PAD - BORDER_WIDTH}px;
  transform: translateX(calc(${({ activeTab }) => activeTab * 100}% - ${SLIDER_PAD}px));
  transition: transform 200ms linear;
  position: absolute;
  top: ${SLIDER_PAD}px;
  bottom: ${SLIDER_PAD}px;
  left: ${BORDER_WIDTH * SLIDER_PAD}px;
  z-index: 0;
  border-radius: 2px;
  box-shadow: ${({ theme }) => theme.shadow.low};
`;

export const SliderButton = styled(RawButton)<{ tabs: number; activeTab: number }>`
  width: ${({ tabs }) => 100 / tabs}%;
  position: relative;
  z-index: 1;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &:focus {
    outline: none;
  }
`;
