import * as React from 'react';
import styled from 'styled-components';
import { GiSpeaker, GiSpeakerOff } from 'react-icons/gi';
import { RawButton } from 'styles/elements';

interface Props {
  isSoundEnabled: boolean;
  setIsSoundEnabled: (isSoundEnabled: boolean) => void;
  className?: string;
}

const SoundToggle = styled(RawButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  line-height: 1;
  height: 24px;
  width: 24px;
  background-color: ${({ theme }) => theme.color.border};
  border-radius: 4px;
  font-size: 14px;
  position: absolute;
  top: 8px;
  right: 62px;

  &:focus {
    outline: none;
  }
`;

const SpeakerButton: React.FC<React.PropsWithChildren<Props>> = ({
  isSoundEnabled,
  setIsSoundEnabled,
  className,
}) => {
  return (
    <SoundToggle className={className} onClick={() => setIsSoundEnabled(!isSoundEnabled)}>
      {isSoundEnabled ? <GiSpeaker /> : <GiSpeakerOff />}
    </SoundToggle>
  );
};

export default SpeakerButton;
