import React from 'react';
import Markdown from 'react-markdown';
import { optionFlowArticleMarkdown } from './markdown';

export default function FlowDescription() {
  return (
    <div className="prose-sm prose-invert mt-12 max-w-xl px-2">
      <h1>Options Flow and Unusual Options Activity</h1>
      <Markdown>{optionFlowArticleMarkdown}</Markdown>
    </div>
  );
}
