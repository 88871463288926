import * as React from 'react';
import { themeDark } from 'styles/theme';
import Chart from 'chart.js';
import abbreviateNumber from 'utils/shared/number/abbreviateNumber';
import { SLIGHTLY_BULLISH, SLIGHTLY_BEARISH } from 'utils/shared/trades/types';
import { SectorGraphProps, SectorAggregatedDetails } from './props';

const MIN = 3;
const MAX = 18;

const mapSectorToBackgroundColor = (
  s: SectorAggregatedDetails,
  isUnusualFiltered: boolean,
  isSweepsFiltered: boolean,
) => {
  let callColor = themeDark.color.graphCall;
  let putColor = themeDark.color.graphPut;

  if (isUnusualFiltered) {
    callColor = themeDark.color.graphUnusualCall;
    putColor = themeDark.color.graphUnusualPut;
  } else if (isSweepsFiltered) {
    callColor = themeDark.color.graphSweep;
    putColor = themeDark.color.graphSweep;
  }

  if (s.putFlow <= SLIGHTLY_BULLISH) {
    return callColor;
  } else if (s.putFlow > SLIGHTLY_BULLISH && s.putFlow < SLIGHTLY_BEARISH) {
    return themeDark.color.mixedSentiment;
  } else {
    return putColor;
  }
};

const SectorBubbleChart: React.FC<React.PropsWithChildren<SectorGraphProps>> = ({
  tradesBySector,
  isUnusualFiltered,
  isSweepsFiltered,
}) => {
  const chartWrapperRef = React.useRef<HTMLCanvasElement>(null);
  const chartRef = React.useRef<Chart | undefined>(undefined);
  const sectors = React.useMemo(() => {
    return [...tradesBySector].sort((a, b) => {
      if (isUnusualFiltered) {
        return b.unusualOtm - a.unusualOtm;
      } else if (isSweepsFiltered) {
        return b.sweepOtm - a.sweepOtm;
      } else {
        return b.otm - a.otm;
      }
    });
  }, [tradesBySector, isUnusualFiltered, isSweepsFiltered]);
  const premiumArray = sectors.map((s) => {
    if (isUnusualFiltered) {
      return s.unusualPremium;
    } else if (isSweepsFiltered) {
      return s.sweepPremium;
    } else {
      return s.premium;
    }
  });
  const minValue = Math.min(...premiumArray);
  const maxValue = Math.max(...premiumArray);
  const data = sectors.map((sector, i) => {
    let yAxisData = sector.otm;
    let radius = sector.premium;

    if (isUnusualFiltered) {
      yAxisData = sector.unusualOtm;
      radius = sector.unusualPremium;
    } else if (isSweepsFiltered) {
      yAxisData = sector.sweepOtm;
      radius = sector.sweepPremium;
    }

    return {
      x: i,
      y: yAxisData,
      r: ((MAX - MIN) * (radius - minValue)) / (maxValue - minValue) + MIN,
    };
  });

  React.useEffect(() => {
    const myChartRef = chartRef.current;
    if (myChartRef?.data.datasets) {
      // @ts-ignore
      myChartRef.data.datasets[0].data = data;
      myChartRef.data.labels = sectors.map((s) => s.sector);
      myChartRef.data.datasets[0].backgroundColor = sectors.map((s) =>
        mapSectorToBackgroundColor(s, isUnusualFiltered, isSweepsFiltered),
      );
      myChartRef.update();
    }
  }, [sectors, data]);

  React.useEffect(() => {
    const myChartRef = chartWrapperRef.current?.getContext('2d');

    if (myChartRef) {
      chartRef.current = new Chart(myChartRef, {
        type: 'bubble',
        data: {
          datasets: [
            {
              data,
              backgroundColor: sectors.map((s) =>
                mapSectorToBackgroundColor(s, isUnusualFiltered, isSweepsFiltered),
              ),
            },
          ],
          labels: sectors.map((s) => s.sector),
        },
        options: {
          legend: {
            display: false,
          },
          responsive: true,
          maintainAspectRatio: false,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          circumference: 180,
          rotation: -90,
          tooltips: {
            callbacks: {
              title: function (item, data) {
                // @ts-ignore
                return data.labels[item[0].index];
              },
              label: function (item, data) {
                // @ts-ignore
                const dataset = data.datasets[item.datasetIndex];
                const dataItem = dataset.data[item.index];
                return `${dataItem.y}% OTM, $${abbreviateNumber(sectors[item.index || 0].premium)}`;
              },
            },
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: 'ITM < - - - - - - - - - - - - - - - - > OTM',
                  fontColor: themeDark.color.textLight,
                  fontSize: 11,
                },
                ticks: {
                  callback: function (value) {
                    return `${value}%`;
                  },
                  fontColor: themeDark.color.textLight,
                  fontSize: 11,
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  callback: function (_value, index) {
                    return sectors[index] ? sectors[index].sector : '';
                  },
                  fontColor: themeDark.color.textLight,
                  fontSize: 10,
                },
              },
            ],
          },
        },
      });
    }

    return () => {
      chartRef.current?.destroy();
    };
  }, []);

  return (
    <div
      style={{
        maxHeight: '100%',
        height: '100%',
        width: '100%',
      }}
    >
      <canvas ref={chartWrapperRef} style={{ height: '100%', width: '100%' }} />
    </div>
  );
};

export default SectorBubbleChart;
