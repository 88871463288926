import firebase from 'firebase';
import { AuthStatus, HasuraClaims } from 'constants/auth';

// TODO: Have a config param as well to load user settings from the DB
export interface Viewer {
  status: AuthStatus;
  viewer: firebase.User | null;
  config: null;
  claims: HasuraClaims | null;
}

export enum Role {
  User = 'user',
  Admin = 'admin',
  Editor = 'editor',
  Author = 'author',
  Reviewer = 'reviewer',
  Anonymous = 'anonymous',
}

export enum Theme {
  Dark = 'DARK',
  Light = 'LIGHT',
}

export enum StatusOfNonProAgreement {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  NeedsReview = 'NEEDS_REVIEW',
}

export enum EmploymentProfessionalStatus {
  Unknown = 'UNKNOWN',
  Professional = 'PROFESSIONAL',
  NonProfessional = 'NON_PROFESSIONAL',
}

export enum ProbabilityOfProfessional {
  Unknown = 'UNKNOWN',
  Clear = 'CLEAR',
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH',
}

export const MAX_FREE_TRADES = 200;
