import React from 'react';
import { SliderDirection } from 'utils/shared/trades/presets';

interface SliderFilterProps {
  buttonsDisp?: boolean;
  enabled: boolean;
  range: { min: number; max: number };
  absoluteMin: number;
  absoluteMax: number;
  unit: string;
  unitPosition?: 'prefix' | 'suffix';
  value: number;
  setValue?: (val: number) => void;
  step?: number;
  onChange?: (min: number, max: number) => void;
  direction: SliderDirection;
  onDirectionChange: (direction: SliderDirection) => void;
  isOnlyAbove?: boolean;
}

const SliderFilter: React.FC<SliderFilterProps> = ({
  buttonsDisp = true,
  enabled,
  range,
  absoluteMin,
  absoluteMax,
  unit,
  unitPosition = 'suffix',
  value,
  step = 1,
  onChange = () => {},
  direction,
  onDirectionChange,
  isOnlyAbove = false,
}) => {
  const handleButtonClick = (direction: SliderDirection) => {
    onDirectionChange(direction);

    let normalizedValue = value;
    if (value < range.min) {
      normalizedValue = range.min;
    } else if (value > range.max) {
      normalizedValue = absoluteMax;
    }

    if (direction === SliderDirection.All) {
      onChange(absoluteMin, absoluteMax);
    } else if (direction === SliderDirection.Above) {
      onChange(normalizedValue, absoluteMax);
    } else if (direction === SliderDirection.Below) {
      onChange(absoluteMin, normalizedValue);
    }
  };

  const getSliderBackground = () => {
    const percentage = ((value - range.min) / (range.max - range.min)) * 100;
    if (direction === SliderDirection.Below) {
      return `linear-gradient(to right, #3b82f6 ${percentage}%, #e5e7eb ${percentage}%)`;
    } else if (direction === SliderDirection.Above) {
      return `linear-gradient(to left, #3b82f6 ${100 - percentage}%, #e5e7eb ${100 - percentage}%)`;
    } else {
      return `linear-gradient(to right, #e5e7eb 0%, #e5e7eb 100%)`;
    }
  };

  const buttonClass = (d: SliderDirection) =>
    `items-center rounded-[4px] border px-3 py-2 text-xs font-semibold leading-none ${
      d === direction ? 'bg-white text-brand-primary' : 'text-white'
    }`;

  const formatValueWithUnit = (val: number) => {
    let formattedValue = val;
    let unitSuffix = '';

    if (val >= 1000000000) {
      formattedValue = val / 1000000000;
      unitSuffix = 'B';
    } else if (val >= 1000000) {
      formattedValue = val / 1000000;
      unitSuffix = 'M';
    } else if (val >= 1000) {
      formattedValue = val / 1000;
      unitSuffix = 'K';
    }

    return unitPosition === 'prefix'
      ? `${unit}${formattedValue}`
      : `${formattedValue}${unitSuffix}${unit !== 'K' && unit !== 'M' && unit !== 'B' ? unit : ''}`;
  };

  return enabled ? (
    <div className="flex w-full items-end space-x-2 opacity-65 transition-opacity	duration-[250] ease-in-out hover:opacity-100">
      {buttonsDisp && (
        <>
          <button
            type="button"
            className={buttonClass(SliderDirection.All)}
            onClick={() => handleButtonClick(SliderDirection.All)}
          >
            All
          </button>
          {!isOnlyAbove && (
            <button
              type="button"
              className={buttonClass(SliderDirection.Below)}
              onClick={() => handleButtonClick(SliderDirection.Below)}
            >
              Below
            </button>
          )}
          <button
            type="button"
            className={buttonClass(SliderDirection.Above)}
            onClick={() => handleButtonClick(SliderDirection.Above)}
          >
            Above
          </button>
        </>
      )}
      <div className="flex w-full flex-col items-center space-y-2">
        <div className="flex w-full justify-center">
          <span className="text-sm font-semibold">
            {direction === SliderDirection.All
              ? 'All'
              : direction === SliderDirection.Above
              ? `Above ${formatValueWithUnit(value)}`
              : `Below ${formatValueWithUnit(value)}`}
          </span>
        </div>
        <div className="flex w-full items-center space-x-2">
          <span className="md:w-18 w-12 whitespace-nowrap px-2 text-sm dark:text-gray-400">
            {formatValueWithUnit(range.min)}
          </span>
          <div className="relative flex-grow">
            <input
              id="range-slider"
              type="range"
              min={range.min}
              max={range.max}
              step={step}
              value={value}
              onChange={(e) => {
                if (direction === SliderDirection.All) {
                  onChange(absoluteMin, absoluteMax);
                } else if (direction === SliderDirection.Above) {
                  onChange(Number(e.target.value), absoluteMax);
                } else if (direction === SliderDirection.Below) {
                  onChange(absoluteMin, Number(e.target.value));
                }
              }}
              className="h-2 w-full cursor-pointer appearance-none rounded-md"
              style={{ background: getSliderBackground() }}
              disabled={direction === SliderDirection.All}
            />
          </div>
          <span className="md:w-18 w-12 whitespace-nowrap px-2 text-right text-sm dark:text-gray-400">
            {formatValueWithUnit(range.max)}
          </span>
        </div>
      </div>
    </div>
  ) : null;
};

export default SliderFilter;
