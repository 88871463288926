import React from 'react';

interface Option {
  label: string;
  value?: string;
  color?: string;
}

interface FilterButtonGroupProps {
  options: Option[];
  activeOption: string | string[];
  onClick: (label: string) => void;
}

export const FilterButtonGroupWithLabel: React.FC<FilterButtonGroupProps> = ({
  options,
  activeOption,
  onClick,
}) => {
  return (
    <>
      {options.map((option, index) => (
        <button
          key={index}
          type="button"
          className={`mr-2 items-center rounded-[4px] border px-3 py-2 text-xs font-semibold leading-none 
            ${
              (typeof activeOption === 'string' && activeOption === option.label) ||
              (Array.isArray(activeOption) && activeOption.includes(option.label))
                ? 'bg-white text-brand-primary'
                : 'text-white'
            }`}
          style={{
            backgroundColor:
              ((typeof activeOption === 'string' && activeOption === option.label) ||
                (Array.isArray(activeOption) && activeOption.includes(option.label))) &&
              option.color
                ? option.color
                : undefined,
            color:
              (activeOption === option.label ||
                (Array.isArray(activeOption) && activeOption.includes(option.label))) &&
              option.color
                ? '#ffff'
                : undefined,
            borderColor:
              ((typeof activeOption === 'string' && activeOption === option.label) ||
                (Array.isArray(activeOption) && activeOption.includes(option.label))) &&
              option.color
                ? option.color
                : undefined,
          }}
          onClick={() => onClick(option.label)}
        >
          {option.label}
        </button>
      ))}
    </>
  );
};
