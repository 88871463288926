import * as React from 'react';
import Chart from 'chart.js';
import 'chartjs-chart-treemap';
import { themeDark } from 'styles/theme';
import abbreviateNumber from 'utils/shared/number/abbreviateNumber';
import { colorFromValue } from 'utils/client/chart';
import { SLIGHTLY_BULLISH, SLIGHTLY_BEARISH } from 'utils/shared/trades/types';
import { SectorGraphProps } from './props';

const mapSectorToBackgroundColor = (
  putFlow: number,
  isUnusualFiltered: boolean,
  isSweepsFiltered: boolean,
) => {
  let callColor = themeDark.color.graphCall;
  let putColor = themeDark.color.graphPut;

  if (isUnusualFiltered) {
    callColor = themeDark.color.graphUnusualCall;
    putColor = themeDark.color.graphUnusualPut;
  } else if (isSweepsFiltered) {
    callColor = themeDark.color.graphSweep;
    putColor = themeDark.color.graphSweep;
  }

  if (putFlow <= SLIGHTLY_BULLISH) {
    return callColor;
  } else if (putFlow > SLIGHTLY_BULLISH && putFlow < SLIGHTLY_BEARISH) {
    return themeDark.color.mixedSentiment;
  } else {
    return putColor;
  }
};

const SectorUnusualTreemap: React.FC<React.PropsWithChildren<SectorGraphProps>> = ({
  tradesBySector,
  isUnusualFiltered,
  isSweepsFiltered,
}) => {
  const chartWrapperRef = React.useRef<HTMLCanvasElement>(null);
  const chartRef = React.useRef<Chart | undefined>(undefined);
  const sectors = React.useMemo(() => {
    return [...tradesBySector].sort((a, b) => {
      if (isUnusualFiltered) {
        return b.unusualPremium - a.unusualPremium;
      } else if (isSweepsFiltered) {
        return b.sweepPremium - a.sweepPremium;
      } else {
        return b.premium - a.premium;
      }
    });
  }, [tradesBySector]);
  const tree = sectors.map((sector, i) => {
    let otm = sector.otm;
    let premium = sector.premium;

    if (isUnusualFiltered) {
      otm = sector.unusualOtm;
      premium = sector.unusualPremium;
    } else if (isSweepsFiltered) {
      otm = sector.sweepOtm;
      premium = sector.sweepPremium;
    }

    return {
      sector: sector.sector,
      putFlow: sector.putFlow,
      code: i,
      premium: premium,
      OTM: otm,
    };
  });

  React.useEffect(() => {
    const myChartRef = chartRef.current;
    if (myChartRef?.data.datasets) {
      // @ts-ignore
      myChartRef.data.datasets[0].tree = tree;
      myChartRef.data.labels = sectors.map((s) => s.sector);

      const colorPerSector = tree.map((item) => {
        const maxValue = tree[0].premium;
        const minValue = tree[tree.length - 1].premium;
        const color = mapSectorToBackgroundColor(item.putFlow, isUnusualFiltered, isSweepsFiltered);
        const finalColor = colorFromValue({
          color: color,
          value: item.premium,
          border: false,
          maxValue,
          minValue,
        });

        return finalColor;
      });
      myChartRef.data.datasets[0].backgroundColor = colorPerSector.map((color) => color);
      myChartRef.data.datasets[0].borderColor = colorPerSector.map((color) => color);
      myChartRef.update();
    }
  }, [sectors, tree, isUnusualFiltered, isSweepsFiltered]);

  React.useEffect(() => {
    const myChartRef = chartWrapperRef.current?.getContext('2d');

    if (myChartRef) {
      chartRef.current = new Chart(myChartRef, {
        type: 'treemap',
        data: {
          datasets: [
            {
              // @ts-ignore (new property)
              tree,
              key: 'premium',
              groups: ['sector'],
              backgroundColor: function (ctx) {
                // @ts-ignore
                const chartPoint: any = ctx.dataset.data[ctx.dataIndex];

                if (!chartPoint || (!ctx.dataIndex && ctx.dataIndex !== 0)) {
                  return;
                }

                const maxValue = tree[0].premium;
                const minValue = tree[tree.length - 1].premium;

                const color = mapSectorToBackgroundColor(
                  tree[ctx.dataIndex].putFlow,
                  isUnusualFiltered,
                  isSweepsFiltered,
                );

                const finalColor = colorFromValue({
                  color: color,
                  value: chartPoint.v as number,
                  border: false,
                  maxValue,
                  minValue,
                });

                return finalColor;
              },
              borderColor: function (ctx) {
                // @ts-ignore
                const chartPoint: any = ctx.dataset.data[ctx.dataIndex];

                if (!chartPoint || (!ctx.dataIndex && ctx.dataIndex !== 0)) {
                  return themeDark.color.accentBlue;
                }

                const maxValue = tree[0].premium;
                const minValue = tree[tree.length - 1].premium;

                const color = mapSectorToBackgroundColor(
                  tree[ctx.dataIndex].putFlow,
                  isUnusualFiltered,
                  isSweepsFiltered,
                );

                const finalColor = colorFromValue({
                  color: color,
                  value: chartPoint.v as number,
                  border: false,
                  maxValue,
                  minValue,
                });

                return finalColor;
              },
              // @ts-ignore
              spacing: 0.1,
              borderWidth: 2,
              fontColor: themeDark.color.textPrimary,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          title: {
            display: false,
          },
          legend: {
            display: false,
          },
          tooltips: {
            callbacks: {
              title: function () {
                return '';
              },
              label: function (item, data) {
                if (
                  !item ||
                  typeof item.index === 'undefined' ||
                  typeof item.datasetIndex === 'undefined' ||
                  !data?.datasets ||
                  !data?.datasets[item.datasetIndex]
                ) {
                  return '';
                }

                const dataset = data.datasets[item.datasetIndex];
                const dataItem = dataset.data && dataset.data[item.index];

                if (!dataItem) {
                  return '';
                }

                // @ts-ignore
                return `${abbreviateNumber(dataItem._data.sector)}: $${abbreviateNumber(
                  // @ts-ignore
                  dataItem._data.premium,
                )}`;
              },
            },
          },
        },
      });
    }

    return () => {
      chartRef.current?.destroy();
    };
  }, []);

  return (
    <div
      style={{
        maxHeight: '100%',
        height: '100%',
        width: '100%',
      }}
    >
      <canvas ref={chartWrapperRef} style={{ height: '100%', width: '100%' }} />
    </div>
  );
};

export default SectorUnusualTreemap;
