import styled from 'styled-components';
import { mobile, tablet } from 'styles/breakpoints';

export const AGGREGATE_HEIGHT = 88;
export const FLOW_HEIGHT = 280;
export const GRAPH_HEIGHT = 360;
export const ARTICLES_HEIGHT = 2 * GRAPH_HEIGHT;
export const NEWS_NAV_HEIGHT = 38;
export const SECTION_MARGIN_REM = 0.5;

export const GraphWrapper = styled.div`
  height: ${GRAPH_HEIGHT}px;
`;

export const NewsItem = styled.a`
  display: block;
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  padding-bottom: 0.5rem;
  margin-bottom: 0.6rem;

  &:last-of-type {
    border-bottom: none;
    margin-bottom: none;
  }
`;

export const ListGrid = styled.div`
  display: grid;
  grid-row-gap: 0.5rem;
  grid-column-gap: 0.5rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  ${tablet()} {
    grid-template-columns: 1fr 1fr;
  }

  ${mobile()} {
    grid-template-columns: 1fr;
  }
`;

export const TopEventsWrapper = styled.div`
  border-radius: 4px;
  height: ${ARTICLES_HEIGHT}px;
  overflow: hidden;
`;

export const EventList = styled.div`
  background-color: ${({ theme }) => theme.color.backgroundDistinct};

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const EventItem = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.color.backgroundSecondary : 'transparent'};
  transition: background-color 150ms ease;

  &:hover {
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.color.backgroundSecondary : theme.color.paper};
  }
`;

export const EventArticleWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.backgroundSecondary};
`;

const TICKER_COLOR = '#58a6ff';
const BACKGROUND_COLOR = '56, 139, 253';
export const EventTicker = styled.a`
  color: ${TICKER_COLOR};
  background-color: rgba(${BACKGROUND_COLOR}, 0.2);
  border-radius: 4px;
  transition: background-color 150ms ease;
  padding: 0 12px;
  line-height: 28px;
  font-size: 10px;
  font-weight: 700;
  width: 60px;
  text-align: center;

  &:visited {
    color: ${TICKER_COLOR};
  }

  &:hover {
    background-color: rgba(${BACKGROUND_COLOR}, 0.35);
  }
`;

export const NewsEvent = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.border};
  cursor: pointer;
  background-color: transparent;
  transition: background-color 150ms ease;

  &:hover {
    background-color: ${({ theme }) => theme.color.paper};
  }
`;

export const NewsArticleListWrapper = styled.div`
  height: ${ARTICLES_HEIGHT}px;
  overflow: hidden;
`;

export const SectionTitleWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.backgroundDistinct};
`;

export const HeightWrapper = styled.div`
  height: ${ARTICLES_HEIGHT - NEWS_NAV_HEIGHT}px;
`;

export const InlineSentiment = styled.span<{ sentiment: string }>`
  text-transform: uppercase;
  margin-right: 0.5rem;
  font-size: 80%;
  font-weight: 600;
  color: ${({ theme, sentiment }) => {
    if (sentiment === 'Positive') {
      return theme.color.graphCall;
    } else if (sentiment === 'Negative') {
      return theme.color.graphPut;
    } else if (sentiment === 'Neutral') {
      return theme.color.mixedSentimentBright;
    } else {
      return theme.color.textPrimary;
    }
  }};
`;
