import * as React from 'react';
import Link from 'components/Link';
import { AiOutlineDeploymentUnit, AiFillStar } from 'react-icons/ai';
import { XIcon } from '@heroicons/react/solid';
import { FaDownload } from 'react-icons/fa';
import { BsBarChartSteps } from 'react-icons/bs';
import { Switch } from '@headlessui/react';
import abbreviateNumber from 'utils/shared/number/abbreviateNumber';
import { getSectorShortName } from 'utils/shared/trades/sectors';
import { trackEvent } from 'services/client/analytics';
import { DOWNLOAD_AGGREGATE } from 'services/client/analytics/events';
import { TICKER_FLOW_PAGE } from 'constants/pages';
import { SortDirection } from 'utils/shared/trades/types';
import { convertToCSV, downloadCSV } from 'utils/client/csvUtils';
import SectionTitle from 'components/SectionTitle';
import TickerName from 'components/TickerName';
import DarkPoolLevels from 'components/DarkPoolLevels';
import {
  TableWrapper,
  TableCell,
  TableHeader,
  TableBody,
  HeaderCellWrapper,
  HeaderCell,
  SentimentText,
  HeaderIcon,
  IconSortDesc,
  IconSortAsc,
  WatchListIcon,
} from 'components/Table';
import Modal from 'components/Modal/HeadlessUiModal';
import PermissionedBlock from 'components/PermissionedBlock';
import {
  OverviewProps,
  OverviewHeaderProps,
  TickerAggregatedDetails,
  DarkPoolAggregate,
  AGGREGATE_TABLE_HEADERS,
  WEEKLY_AGGREGATE_TABLE_HEADERS,
  DEFAULT_AGGREGATE_KEY,
  DARKPOOL_AGGREGATE_HEADERS,
  DEFAULT_DARKPOOL_KEY,
  DataBasedOnFeedType,
} from './props';

import { AggregateRow, DarkPoolGridRow, FeedTypeToggle } from './styles';

enum FeedType {
  Options = 'OPTIONS',
  DarkPool = 'DARK_POOL',
}

const formatData = (data: DataBasedOnFeedType<FeedType>, isWeekly: boolean) => {
  return data.map((item: any) => ({
    Ticker: item['Ticker'],
    'Put Flow': parseFloat((item['Put Flow'] / 100).toFixed(4)),
    OTM: parseFloat((item['OTM'] / 100).toFixed(4)),
    'Momentum Multiple': parseFloat(
      (isWeekly ? item['volumeGrowth'] : item['Momentum']).toFixed(2),
    ),
    'Total Contracts': parseFloat(item['Total Contracts'].toFixed(2)),
    'Total Premium ($)': parseFloat(item['Total Premium'].toFixed(2)),
    'Avg Price ($)': parseFloat(item['Avg Price'].toFixed(2)),
    'Avg Expiry Days': parseFloat(item['Avg Expiry'].toFixed(2)),
    Sector: item['Sector'] || '',
    'Unusual OTM': parseFloat((item['Unusual OTM'] / 100).toFixed(4)),
    'Unusual Contracts': parseFloat(item['Unusual Contracts'].toFixed(2)),
    'Unusual Premium ($)': parseFloat(item['Unusual Premium'].toFixed(2)),
    'Sweep OTM': parseFloat((item['Sweep OTM'] / 100).toFixed(4)),
    'Sweep Contracts': parseFloat(item['Sweep Contracts'].toFixed(2)),
    'Sweep Premium ($)': parseFloat(item['Sweep Premium'].toFixed(2)),
  }));
};

const Header: React.FC<
  React.PropsWithChildren<
    OverviewHeaderProps<FeedType> & {
      feedType: FeedType;
      setFeedType: (feedType: FeedType) => void;
      hasAccess: boolean;
    }
  >
> = ({
  aggregateSortKey,
  setAggregateSortKey,
  aggregateSortDirection,
  setAggregateSortDirection,
  isWeekly,
  isWatchListOnly,
  setIsWatchListOnly,
  hasWatchListItems,
  feedType,
  setFeedType,
  data,
  hasAccess,
}) => {
  const isOptions = feedType === FeedType.Options;
  const TITLE_NAMES = isOptions ? AGGREGATE_TABLE_HEADERS : DARKPOOL_AGGREGATE_HEADERS;
  const RowComponent = isOptions ? AggregateRow : DarkPoolGridRow;
  const cols = TITLE_NAMES.length - 1;
  const [isDownloadModalOpen, setIsDownloadModalOpen] = React.useState(false);

  const handleDownload = (): void => {
    trackEvent(DOWNLOAD_AGGREGATE, {
      hasAccess,
    });

    if (!hasAccess) {
      return setIsDownloadModalOpen(true);
    }

    const columns = isWeekly ? WEEKLY_AGGREGATE_TABLE_HEADERS : TITLE_NAMES;

    const csvData = (data as DataBasedOnFeedType<typeof feedType>).map((item: any) => {
      const obj: any = {};
      columns.forEach((column) => {
        obj[column.name] = item[column.sortKey];
      });
      return obj;
    });

    const formattedData = formatData(csvData, isWeekly ?? false);
    const csv = convertToCSV(formattedData);
    downloadCSV(csv, 'Smart Market Insight by Ticker');
  };

  return (
    <>
      <TableHeader>
        <div className="mb-2 flex justify-between">
          <div className="flex grow">
            <SectionTitle
              Icon={AiOutlineDeploymentUnit}
              title="Smart Market Insight by Ticker"
              style={{ marginBottom: 0 }}
            />
            <Switch.Group as="div" className="ml-4 flex items-center">
              <Switch
                checked={isWatchListOnly}
                onChange={setIsWatchListOnly}
                className="group relative inline-flex h-3 w-6 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
              >
                <span
                  aria-hidden="true"
                  className={`${isWatchListOnly ? 'bg-yellow-400' : 'bg-gray-600'}
          pointer-events-none absolute mx-auto h-2 w-5 rounded-full transition-colors duration-200 ease-in-out
        `}
                />
                <span
                  aria-hidden="true"
                  className={`${isWatchListOnly ? 'translate-x-3' : 'translate-x-0'} ${
                    isWatchListOnly ? 'bg-white' : 'bg-gray-300'
                  } pointer-events-none absolute left-0 inline-block h-3 w-3 transform rounded-full border border-gray-400 shadow ring-0 transition-transform duration-200 ease-in-out`}
                />
              </Switch>
              <div
                className={`ml-2 text-xs ${isWatchListOnly ? 'text-yellow-400' : 'text-gray-600'}`}
              >
                <AiFillStar />
              </div>
              {isWatchListOnly && !hasWatchListItems && (
                <Switch.Label as="span" className="ml-2 leading-none" style={{ lineHeight: 0 }}>
                  <span className="text-xs font-medium leading-none text-yellow-400">
                    Add items to your watchlist to appear in the feed
                  </span>
                </Switch.Label>
              )}
            </Switch.Group>
          </div>
          {!isWeekly ? (
            <div className="flex items-center">
              <FeedTypeToggle
                isActive={feedType === FeedType.Options}
                onClick={() => {
                  setFeedType(FeedType.Options);
                  setAggregateSortKey(DEFAULT_AGGREGATE_KEY);
                  setAggregateSortDirection(SortDirection.None);
                }}
              >
                Options
              </FeedTypeToggle>
              <FeedTypeToggle
                isActive={feedType === FeedType.DarkPool}
                onClick={() => {
                  setFeedType(FeedType.DarkPool);
                  setAggregateSortKey(DEFAULT_DARKPOOL_KEY);
                  setAggregateSortDirection(SortDirection.None);
                }}
              >
                Dark Pool
              </FeedTypeToggle>
            </div>
          ) : (
            <div>&nbsp;</div>
          )}
          <div className="ml-1.5 flex items-center ">
            <button
              className="flex h-6 items-center justify-center rounded-[4px] bg-white/10 px-2 text-[.65rem] shadow-sm"
              onClick={handleDownload}
            >
              <FaDownload /> <span className="ml-2 text-[.6rem] font-semibold">Download</span>
            </button>
          </div>
        </div>
        <RowComponent removePad>
          {(isWeekly ? WEEKLY_AGGREGATE_TABLE_HEADERS : TITLE_NAMES).map((t, i) => {
            const isActiveSort =
              t.sortKey === aggregateSortKey && aggregateSortDirection !== SortDirection.None;
            return (
              <HeaderCellWrapper
                key={`${feedType}-${t.name}`}
                isUnusual={isOptions && i >= cols - 5 && i < cols - 2}
                isSweep={isOptions && i >= cols - 2}
                isActiveSort={isActiveSort}
                onClick={() => {
                  if (i === cols && !isOptions) {
                    return;
                  }

                  if (aggregateSortKey !== t.sortKey) {
                    setAggregateSortKey(t.sortKey);
                    setAggregateSortDirection(SortDirection.Desc);
                  } else if (
                    aggregateSortKey === t.sortKey &&
                    aggregateSortDirection === SortDirection.Desc
                  ) {
                    setAggregateSortDirection(SortDirection.Asc);
                  } else if (
                    aggregateSortKey === t.sortKey &&
                    aggregateSortDirection === SortDirection.Asc
                  ) {
                    setAggregateSortKey(DEFAULT_AGGREGATE_KEY);
                    setAggregateSortDirection(SortDirection.None);
                  }
                }}
              >
                <HeaderCell>
                  <span className="cell-name">{t.name}</span>
                  {isActiveSort && aggregateSortDirection === SortDirection.Desc && (
                    <HeaderIcon>
                      <IconSortDesc />
                    </HeaderIcon>
                  )}
                  {isActiveSort && aggregateSortDirection === SortDirection.Asc && (
                    <HeaderIcon>
                      <IconSortAsc />
                    </HeaderIcon>
                  )}
                </HeaderCell>
              </HeaderCellWrapper>
            );
          })}
        </RowComponent>
      </TableHeader>
      <Modal isOpen={isDownloadModalOpen} handleClose={() => setIsDownloadModalOpen(false)}>
        <div className="relative rounded-lg bg-palette-black-1 p-4">
          <button onClick={() => setIsDownloadModalOpen(false)} className="absolute right-2 top-2">
            <XIcon className="w-5" />
          </button>
          <PermissionedBlock />
        </div>
      </Modal>
    </>
  );
};

const Row: React.FC<
  React.PropsWithChildren<{
    ticker: TickerAggregatedDetails;
    isWeekly?: boolean;
    watchListTickers: string[];
  }>
> = React.memo(({ ticker, isWeekly, watchListTickers }) => {
  const isWatchListTrade = !!ticker.ticker && watchListTickers.includes(ticker.ticker);
  return (
    <AggregateRow key={ticker.ticker} tableBodyRow>
      <TableCell className="relative">
        {isWatchListTrade && (
          <WatchListIcon className="absolute text-yellow-400">
            <AiFillStar />
          </WatchListIcon>
        )}
        <Link href={`${TICKER_FLOW_PAGE}/${ticker.ticker}`}>
          <TickerName name={ticker.tickerName || ''}>
            {ticker.ticker} <span className="ml-2 text-gray-500">{ticker.trades.length}</span>
          </TickerName>
        </Link>
      </TableCell>
      <TableCell>
        <SentimentText type={ticker.putFlow < 50 ? 'Call' : 'Put'}>{ticker.putFlow}%</SentimentText>
      </TableCell>
      <TableCell>{Math.round(ticker.otm)}%</TableCell>
      <TableCell>{(isWeekly ? ticker.volumeGrowth : ticker.momentum).toFixed(1)}x</TableCell>
      <TableCell>{abbreviateNumber(ticker.contracts)}</TableCell>
      <TableCell>${abbreviateNumber(ticker.premium)}</TableCell>
      <TableCell>${ticker.price.toFixed(2)}</TableCell>
      <TableCell>{ticker.expiry} days</TableCell>
      <TableCell>{getSectorShortName(ticker.sector || '')}</TableCell>
      <TableCell>{Math.round(ticker.unusualOtm)}%</TableCell>
      <TableCell>{abbreviateNumber(ticker.unusualContracts)}</TableCell>
      <TableCell>${abbreviateNumber(ticker.unusualPremium)}</TableCell>
      <TableCell>{Math.round(ticker.sweepOtm)}%</TableCell>
      <TableCell>{abbreviateNumber(ticker.sweepContracts)}</TableCell>
      <TableCell>${abbreviateNumber(ticker.sweepPremium)}</TableCell>
    </AggregateRow>
  );
});

const DarkPoolRow: React.FC<
  React.PropsWithChildren<{
    ticker: DarkPoolAggregate;
    isWeekly?: boolean;
    watchListTickers: string[];
    setActiveLevelsTicker: (ticker: string) => void;
  }>
> = React.memo(({ ticker, watchListTickers, setActiveLevelsTicker }) => {
  const isWatchListTrade = !!ticker.ticker && watchListTickers.includes(ticker.ticker);
  return (
    <DarkPoolGridRow key={ticker.ticker} tableBodyRow>
      <TableCell className="relative">
        {isWatchListTrade && (
          <WatchListIcon className="absolute text-yellow-400">
            <AiFillStar />
          </WatchListIcon>
        )}
        <Link href={`${TICKER_FLOW_PAGE}/${ticker.ticker}`}>
          <TickerName name={ticker.tickerName || ''}>
            {ticker.ticker} <span className="ml-2 text-gray-500">{ticker.trades.length}</span>
          </TickerName>
        </Link>
      </TableCell>
      <TableCell>{getSectorShortName(ticker.sector || '')}</TableCell>
      <TableCell>${abbreviateNumber(ticker.premium)}</TableCell>
      <TableCell>{abbreviateNumber(ticker.volume)}</TableCell>
      {/* <TableCell>${abbreviateNumber(ticker.marketCap)}</TableCell> */}
      <TableCell>{ticker.goldenTradeCount}</TableCell>
      <TableCell>{ticker.lateBuyCount}</TableCell>
      <TableCell>{ticker.lateSellCount}</TableCell>
      {/* <TableCell>{ticker.momentum.toFixed(1)}x</TableCell> */}
      <TableCell>{abbreviateNumber(ticker.dayVolume)}</TableCell>
      <TableCell>{abbreviateNumber(ticker.avgVolume)}</TableCell>
      <TableCell>
        <button
          onClick={() => setActiveLevelsTicker(ticker.ticker || '')}
          style={{ marginLeft: '-6px' }}
          className="flex items-center rounded border border-button-color bg-transparent px-2 py-1 transition-colors duration-150 hover:bg-button-color"
        >
          <BsBarChartSteps className="mr-1 h-2 w-2" /> View
        </button>
      </TableCell>
    </DarkPoolGridRow>
  );
});

const OptionAggregatedTable: React.FC<React.PropsWithChildren<OverviewProps>> = ({
  tradesByTicker,
  darkPoolByTicker,
  aggregateSortKey,
  setAggregateSortKey,
  aggregateSortDirection,
  setAggregateSortDirection,
  isWeekly,
  isWatchListOnly,
  setIsWatchListOnly,
  hasWatchListItems,
  watchListTickers,
  hasAccess,
}) => {
  const [feedType, setFeedType] = React.useState(FeedType.Options);
  const [activeLevelsTicker, setActiveLevelsTicker] = React.useState('');
  let darkPoolSortKey = DEFAULT_DARKPOOL_KEY;

  if (
    !aggregateSortKey ||
    aggregateSortKey === DEFAULT_DARKPOOL_KEY ||
    aggregateSortDirection === SortDirection.None
  ) {
    darkPoolSortKey = DEFAULT_DARKPOOL_KEY;
  } else {
    darkPoolSortKey = aggregateSortKey;
  }

  return (
    <>
      <TableWrapper>
        <Header
          isWeekly={isWeekly}
          aggregateSortKey={aggregateSortKey}
          setAggregateSortKey={setAggregateSortKey}
          aggregateSortDirection={aggregateSortDirection}
          setAggregateSortDirection={setAggregateSortDirection}
          isWatchListOnly={isWatchListOnly}
          setIsWatchListOnly={setIsWatchListOnly}
          hasWatchListItems={hasWatchListItems}
          feedType={feedType}
          setFeedType={setFeedType}
          data={feedType === FeedType.Options ? tradesByTicker : darkPoolByTicker}
          hasAccess={hasAccess}
        />
        <TableBody>
          {feedType === FeedType.Options
            ? tradesByTicker.map((ticker) => {
                return (
                  <Row
                    key={ticker.ticker}
                    ticker={ticker}
                    isWeekly={isWeekly}
                    watchListTickers={watchListTickers}
                  />
                );
              })
            : darkPoolByTicker
                .filter((t) => (isWatchListOnly ? watchListTickers.includes(t.ticker) : true))
                .sort((a, b) => {
                  let first = aggregateSortDirection === SortDirection.Asc ? a : b;
                  let last = aggregateSortDirection === SortDirection.Asc ? b : a;

                  // @ts-ignore
                  if (first[darkPoolSortKey] < last[darkPoolSortKey]) {
                    return -1;
                  }
                  // @ts-ignore
                  if (first[darkPoolSortKey] > last[darkPoolSortKey]) {
                    return 1;
                  }
                  return 0;
                })
                .map((ticker) => {
                  return (
                    <DarkPoolRow
                      key={ticker.ticker}
                      ticker={ticker}
                      watchListTickers={watchListTickers}
                      setActiveLevelsTicker={setActiveLevelsTicker}
                    />
                  );
                })}
        </TableBody>
      </TableWrapper>
      {!!activeLevelsTicker && (
        <DarkPoolLevels ticker={activeLevelsTicker} onClose={() => setActiveLevelsTicker('')} />
      )}
    </>
  );
};

export default React.memo(OptionAggregatedTable);
