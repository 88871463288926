import styled from 'styled-components';
import { TableRow } from 'components/Table';

export const MostActiveRow = styled(TableRow)<{ tableBodyRow?: boolean }>`
  grid-template-columns: minmax(56px, auto) minmax(0, 100%);
  align-items: center;
`;

export const ActivityBar = styled.div<{ width: number }>`
  height: 12px;
  width: ${(props) => props.width}%;
  background: linear-gradient(90deg, #f9cb28, #ff4d4d);
  border-radius: 4px;
`;
