import * as React from 'react';
import { useGetNewsEventsByKeyQuery } from 'types/generated/client';
import { Viewer } from 'constants/user';
import { hasDashboardAccess } from 'services/client/token';
import { AuthStatus } from 'constants/auth';
import { EventsResponse } from 'constants/stockNewsApi';
import { MINUTE } from 'constants/time';
import { NEWS_PAGE, TICKER_FLOW_PAGE } from 'constants/pages';
import TopEventsOverview from 'components/NewsComponents/TopEventsOverview';

const POLL_INTERVAL = 5 * MINUTE;

const getNewsUrl = (eventId: string) => `${NEWS_PAGE}?eventId=${eventId}`;
const getTickerUrl = (ticker: string) => `${TICKER_FLOW_PAGE}/${ticker}`;

const TopNewsEvents: React.FC<React.PropsWithChildren<{ viewer: Viewer }>> = ({ viewer }) => {
  const hasAccess = viewer.status === AuthStatus.User && hasDashboardAccess(viewer);
  const { data, loading, error, refetch } = useGetNewsEventsByKeyQuery();
  const events: EventsResponse['data'] =
    (data?.news_cache_stocks && data?.news_cache_stocks[0].response?.data) || [];

  React.useEffect(() => {
    let interval: number | undefined;

    if (hasAccess) {
      if (refetch) {
        refetch();
      }

      // @ts-ignore issue with Node / browser types
      interval = setInterval(() => {
        if (refetch) {
          refetch();
        }
      }, POLL_INTERVAL);
    }

    return () => clearInterval(interval);
  }, [hasAccess, refetch]);

  return (
    <TopEventsOverview
      events={events}
      loading={loading}
      error={error}
      getNewsUrl={getNewsUrl}
      getTickerUrl={getTickerUrl}
    />
  );
};

export default TopNewsEvents;
