import Chart from 'chart.js';

interface ColorFromValueParams {
  minValue: number;
  maxValue: number;
  color: string;
  value: number;
  border?: boolean;
}

const MAX = 1;
const MIN = 0.4;

export const colorFromValue = ({
  color,
  value,
  border,
  minValue,
  maxValue,
}: ColorFromValueParams) => {
  let alpha = ((MAX - MIN) * (value - minValue)) / (maxValue - minValue) + MIN;
  if (border) {
    alpha += 0.01;
  }

  return Chart.helpers.color(color).alpha(alpha).rgbString();
};
