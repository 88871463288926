import * as React from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { Switch } from '@headlessui/react';
import { CgClose, CgMathPlus } from 'react-icons/cg';
import { AiOutlineStar } from 'react-icons/ai';
import {
  useUpsertWatchListItemMutation,
  useSolfDeleteWathclistItemMutation,
  GetWatchListQuery,
} from 'types/generated/client';
import SectionTitle from 'components/SectionTitle';
import Card from 'components/Card';
import MiniInput from 'components/MiniInput';
import { TICKER_FLOW_PAGE } from 'constants/pages';

import {
  AddWatchListButton,
  WatchListButton,
  WatchlistTickerCard,
  RemoveTickerButton,
} from './styles';

interface Props {
  userId?: string | null;
  watchList?: GetWatchListQuery['watch_list'] | null;
  refetch: () => Promise<ApolloQueryResult<GetWatchListQuery>>;
  isFlowWatchListOnly: boolean;
  setIsFlowWatchListOnly: (isFlowWatchListOnly: boolean) => void;
  isAggregateWatchListOnly: boolean;
  setIsAggregateWatchListOnly: (isAggregateWatchListOnly: boolean) => void;
}

const WatchList: React.FC<React.PropsWithChildren<Props>> = ({
  watchList,
  userId,
  refetch,
  isFlowWatchListOnly,
  setIsFlowWatchListOnly,
  isAggregateWatchListOnly,
  setIsAggregateWatchListOnly,
}) => {
  const [watchListTicker, setWatchListTicker] = React.useState('');
  const items = (watchList || []).filter((item) => !item.deletedAt);
  const activeItems = items
    .filter((item) => item.isWatching)
    .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
  const inactiveItems = items
    .filter((item) => !item.isWatching)
    .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
  const [upsertWatchListItem, { loading: mutationLoading }] = useUpsertWatchListItemMutation();
  const [deleteWatchListItem, { loading: deleteLoading }] = useSolfDeleteWathclistItemMutation();

  return (
    <Card className="h-full w-full overflow-hidden" isDark>
      <div className="mb-4 flex items-center">
        <SectionTitle Icon={AiOutlineStar} title="Watchlist" style={{ marginBottom: 0 }} />
        <Switch.Group as="div" className="ml-6 flex items-center">
          <Switch
            checked={isAggregateWatchListOnly}
            onChange={setIsAggregateWatchListOnly}
            className="group relative inline-flex h-3 w-6 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
          >
            <span
              aria-hidden="true"
              className={`${isAggregateWatchListOnly ? 'bg-yellow-400' : 'bg-gray-400'}
          pointer-events-none absolute mx-auto h-2 w-5 rounded-full transition-colors duration-200 ease-in-out
        `}
            />
            <span
              aria-hidden="true"
              className={`${
                isAggregateWatchListOnly ? 'translate-x-3' : 'translate-x-0'
              } pointer-events-none absolute left-0 inline-block h-3 w-3 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out`}
            />
          </Switch>
          <Switch.Label as="span" className="ml-3 cursor-pointer">
            <span
              className={`text-xs font-medium ${
                isAggregateWatchListOnly ? 'text-yellow-400' : 'text-gray-400'
              }`}
            >
              Aggregate
            </span>
          </Switch.Label>
        </Switch.Group>
        <Switch.Group as="div" className="ml-6 flex items-center">
          <Switch
            checked={isFlowWatchListOnly}
            onChange={setIsFlowWatchListOnly}
            className="group relative inline-flex h-3 w-6 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
          >
            <span
              aria-hidden="true"
              className={`${isFlowWatchListOnly ? 'bg-yellow-400' : 'bg-gray-400'}
          pointer-events-none absolute mx-auto h-2 w-5 rounded-full transition-colors duration-200 ease-in-out
        `}
            />
            <span
              aria-hidden="true"
              className={`${
                isFlowWatchListOnly ? 'translate-x-3' : 'translate-x-0'
              } pointer-events-none absolute left-0 inline-block h-3 w-3 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out`}
            />
          </Switch>
          <Switch.Label as="span" className="ml-3 cursor-pointer">
            <span
              className={`text-xs font-medium ${
                isFlowWatchListOnly ? 'text-yellow-400' : 'text-gray-400'
              }`}
            >
              Flow
            </span>
          </Switch.Label>
        </Switch.Group>
      </div>
      <div className="mb-4">
        <form
          className="w-full"
          onSubmit={async (e) => {
            e.preventDefault();

            if (!watchListTicker || !watchListTicker.trim() || !userId) {
              return;
            }

            try {
              await upsertWatchListItem({
                variables: {
                  userId,
                  ticker: watchListTicker,
                  isWatching: true,
                },
              });
              refetch();
              setWatchListTicker('');
            } catch (_error) {
              // NOTE: Error is in the mutation response
            }
          }}
        >
          <MiniInput
            placeholder="Ticker to watch..."
            value={watchListTicker}
            onChange={(e) => setWatchListTicker(e.target.value.toUpperCase())}
            reset={() => setWatchListTicker('')}
            button={
              <AddWatchListButton
                disabled={mutationLoading}
                className="flex-shrink-0 items-center justify-center px-4 py-2 text-xs"
                type="submit"
              >
                {mutationLoading ? 'Loading...' : 'Add'}
              </AddWatchListButton>
            }
          />
        </form>
      </div>
      <div className="flex justify-between"></div>
      <div className="flex h-full max-h-full flex-col overflow-hidden pb-20">
        <div className="flex-shrink flex-grow overflow-y-auto">
          {!!activeItems && activeItems.length > 0 && (
            <div className="mb-4">
              <div className="mb-1 text-xs font-semibold text-gray-400">Active Items</div>
              <div className="grid grid-cols-3 gap-2">
                {activeItems.map((item) => (
                  <WatchlistTickerCard
                    key={item.ticker}
                    className="flex items-center justify-between text-xs"
                    style={{ padding: '.5rem', paddingLeft: '.75rem' }}
                  >
                    <div>
                      <a href={`${TICKER_FLOW_PAGE}/${item.ticker}`} target="_blank">
                        {item.ticker}
                      </a>
                    </div>
                    <WatchListButton
                      onClick={async () => {
                        if (userId) {
                          await upsertWatchListItem({
                            variables: {
                              userId,
                              ticker: item.ticker,
                              isWatching: false,
                            },
                          });
                          refetch();
                        }
                      }}
                    >
                      <CgClose />
                    </WatchListButton>
                  </WatchlistTickerCard>
                ))}
              </div>
            </div>
          )}
          {!!inactiveItems && inactiveItems.length > 0 && (
            <div>
              <div className="mb-1 text-xs font-semibold text-gray-400">Inactive Items</div>
              <div className="grid grid-cols-3 gap-2">
                {inactiveItems.map((item) => (
                  <WatchlistTickerCard
                    key={item.ticker}
                    className="flex items-center justify-between text-xs"
                    style={{ padding: '.5rem', paddingLeft: '.75rem' }}
                  >
                    <div>
                      <a href={`${TICKER_FLOW_PAGE}/${item.ticker}`} target="_blank">
                        {item.ticker}
                      </a>
                    </div>
                    <div>
                      <RemoveTickerButton
                        onClick={() => {
                          if (!deleteLoading) {
                            deleteWatchListItem({ variables: { id: item.id } });
                          }
                        }}
                      >
                        Remove
                      </RemoveTickerButton>
                    </div>
                    <WatchListButton
                      onClick={async () => {
                        if (userId) {
                          await upsertWatchListItem({
                            variables: {
                              userId,
                              ticker: item.ticker,
                              isWatching: true,
                            },
                          });
                          refetch();
                        }
                      }}
                    >
                      <CgMathPlus />
                    </WatchListButton>
                  </WatchlistTickerCard>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default WatchList;
