import * as React from 'react';
import Card from 'components/Card';

interface Props {
  title: string;
  topRow: string;
  bottomRow: string;
  stat: string;
}

const StatCard: React.FC<React.PropsWithChildren<Props>> = ({ title, topRow, bottomRow, stat }) => {
  return (
    <Card className="ml-2 hidden w-1/3 items-center justify-between md:flex">
      <div>
        <div className="mb-1 text-xs font-semibold uppercase text-gray-500">{title}</div>
        <div className="mb-1 text-xs text-blue-200">{topRow}</div>
        <div className="text-xs text-blue-200">{bottomRow}</div>
      </div>
      <div className="text-3xl xl:text-5xl">{stat}</div>
    </Card>
  );
};

export default StatCard;
