import * as React from 'react';
import Chart from 'chart.js';
import { TICKER_FLOW_PAGE } from 'constants/pages';
import { themeDark } from 'styles/theme';
import { BarChartProps } from './props';

const BarChartTopTickers: React.FC<React.PropsWithChildren<BarChartProps>> = ({
  topTrades: displayedTrades,
  sortKey,
  color,
  prefix,
  postfix,
  label,
  formatter,
}) => {
  const chartWrapperRef = React.useRef<HTMLCanvasElement>(null);
  const chartRef = React.useRef<Chart | undefined>(undefined);

  React.useEffect(() => {
    const myChartRef = chartRef.current;
    if (myChartRef?.data.datasets) {
      // @ts-ignore
      myChartRef.data.datasets[0].data = displayedTrades.map((t) => t[sortKey]);
      myChartRef.data.labels = displayedTrades.map((t) => t.ticker);
      myChartRef.update();
    }
  }, [displayedTrades, sortKey]);

  React.useEffect(() => {
    const myChartRef = chartWrapperRef.current?.getContext('2d');

    if (myChartRef) {
      chartRef.current = new Chart(myChartRef, {
        type: 'bar',
        data: {
          datasets: [
            {
              type: 'bar',
              label: 'Contracts',
              // @ts-ignore
              data: displayedTrades.map((t) => t[sortKey]),
              backgroundColor: color,
              // fill: true,
              // borderWidth: 1,
              // borderColor: '#2a587f',
              // hoverBackgroundColor: '#4c87b9',
              // hoverBorderColor: '#2a587f',
            },
          ],
          labels: displayedTrades.map((t) => t.ticker),
        },
        options: {
          legend: {
            display: false,
          },
          responsive: true,
          maintainAspectRatio: false,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            callbacks: {
              title: function (item, data) {
                // @ts-ignore
                return `${data.labels[item[0].index]} ${label}`;
              },
              label: function (item) {
                // @ts-ignore
                return `${prefix}${Math.round(Number(item.value))}${postfix}`;
              },
            },
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: label,
                  fontColor: themeDark.color.textLight,
                  fontSize: 11,
                },
                ticks: {
                  callback: function (value) {
                    return `${prefix}${formatter(Number(value))}${postfix}`;
                  },
                  fontColor: themeDark.color.textLight,
                  fontSize: 11,
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  fontColor: themeDark.color.textLight,
                  fontSize: 10,
                },
              },
            ],
          },
          onClick: function (_e, items) {
            if (!items || !items.length) {
              return;
            }
            // @ts-ignore
            const ticker = items[0]._model.label;
            window.open(`${TICKER_FLOW_PAGE}/${ticker}`, '_blank');
          },
        },
      });
    }

    return () => {
      chartRef.current?.destroy();
    };
  }, []);

  return (
    <div
      style={{
        maxHeight: '100%',
        height: '100%',
        width: '100%',
      }}
    >
      <canvas ref={chartWrapperRef} style={{ height: '100%', width: '100%' }} />
    </div>
  );
};

export default BarChartTopTickers;
