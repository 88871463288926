import * as React from 'react';

import { SliderWrapper, SliderActive, SliderButton } from './styles';

interface Props {
  items: string[];
  activeTab: number;
  setActiveTab: React.Dispatch<React.SetStateAction<any>>;
}

const Slider: React.FC<React.PropsWithChildren<Props>> = ({ items, activeTab, setActiveTab }) => {
  const tabs = items.length;

  return (
    <SliderWrapper>
      <SliderActive tabs={tabs} activeTab={activeTab} />
      {items.map((item, i) => {
        return (
          <SliderButton
            key={item}
            tabs={tabs}
            activeTab={activeTab}
            onClick={() => setActiveTab(i)}
          >
            {item}
          </SliderButton>
        );
      })}
    </SliderWrapper>
  );
};

export default Slider;
