import { uniqueId } from 'lodash';
import React from 'react';

interface Tab {
  label: string;
  desc?: string;
}

interface TabNavProps {
  tabs: Tab[];
  activeTab: number;
  onTabClick: (index: number) => void;
  tabClassName?: (index: number, isActive: boolean) => string;
  defaultClassName?: string;
  activeClassName?: string;
  navClasses?: string;
}

export const TabNav: React.FC<TabNavProps> = ({
  tabs,
  activeTab,
  onTabClick,
  tabClassName,
  defaultClassName = 'whitespace-nowrap text-sm font-semibold px-4 py-2 border-palette-border text-white hover:bg-white hover:text-brand-primary border border-palette-border',
  activeClassName = 'bg-brand-primary text-white border border-transparent',
  navClasses = "",
}) => {
  const getClassNames = (index: number) => {
    if (tabClassName) {
      return tabClassName(index, activeTab === index);
    }

    let baseClass = defaultClassName;
    if (index === 0) {
      baseClass += ' rounded-l-md';
    }
    if (index === tabs.length - 1) {
      baseClass += ' rounded-r-md';
    }
    const activeClass = activeTab === index ? activeClassName : '';

    return `${baseClass} ${activeClass}`;
  };

  return (
    <nav aria-label="Tabs" className={`flex justify-center ${navClasses}`}>
      {tabs.map((tab, index) => (
        <div key={uniqueId()}>
          <button
            onClick={() => onTabClick(index)}
            className={getClassNames(index)}
          >
            {tab.label}
          </button>
        </div>
      ))}
    </nav>
  );
};

