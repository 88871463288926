import * as React from 'react';
import styled from 'styled-components';
import { RawButton } from 'styles/elements';

interface TabProps {
  isActive?: boolean;
  onClick: () => void;
}

export const StyledTab = styled(RawButton)<TabProps>`
  background-color: transparent;
  transition: background-color 250ms ease, border 250ms ease, color 250ms ease;
  border-bottom: 2px solid ${({ theme, isActive }) => (isActive ? theme.color.cta : 'transparent')};
  color: ${({ theme, isActive }) => (isActive ? theme.color.cta : theme.color.textLight)};

  &:hover,
  &:focus {
    background-color: ${({ theme, isActive }) =>
      isActive ? 'transparent' : theme.color.backgroundDistinct};
    color: ${({ theme, isActive }) => (isActive ? theme.color.cta : theme.color.textPrimary)};
  }

  &:focus {
    outline: none;
  }
`;

export const Tab: React.FC<React.PropsWithChildren<TabProps>> = ({ children, ...props }) => {
  return (
    <StyledTab
      {...props}
      className={`ml-2 px-4 py-2 text-xs font-semibold first:ml-0 ${
        props.isActive ? '' : 'rounded'
      }`}
    >
      {children}
    </StyledTab>
  );
};

export const TabWrapper: React.FC<React.PropsWithChildren<{ className?: string }>> = ({ children, className = '' }) => {
  return <div className={`flex ${className}`}>{children}</div>;
};
