import * as React from 'react';
import { AiOutlineFire } from 'react-icons/ai';
import Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';
import Dropdown from 'components/Dropdown';
import { themeDark } from 'styles/theme';
import abbreviateNumber from 'utils/shared/number/abbreviateNumber';
import BarChartTopTickers from './BarChartTopTickers';
import { TopTickerProps } from './props';

const NAV_HEIGHT = '38px';
const NAV_MARGIN = '.5rem';
const MAX_GRAPHS = 12;

export const DEFAULT_TOP_TICKER_GRAPH_INDEX = 0;

const options = [
  {
    name: 'OTM',
    color: themeDark.color.graphCall,
    sortKey: 'otm',
    label: 'OTM %',
    prefix: '',
    postfix: '%',
    formatter: (num: number) => Math.round(num),
  },
  {
    name: 'Unusual OTM',
    color: themeDark.color.graphUnusualCall,
    sortKey: 'unusualOtm',
    label: 'OTM %',
    prefix: '',
    postfix: '%',
    formatter: (num: number) => Math.round(num),
  },
  {
    name: 'Momentum',
    color: themeDark.color.graphMomentum,
    sortKey: 'momentum', // CHANGE ONCE YOU HAVE CALCULATION
    label: 'Multiple of Daily Average',
    prefix: '',
    postfix: 'x',
    formatter: (num: number) => Math.round(num),
  },
  {
    name: 'Unusual Volume',
    color: themeDark.color.graphUnusualCall,
    sortKey: 'unusualContracts',
    label: 'Unusual Contracts',
    prefix: '',
    postfix: '',
    formatter: (num: number) => abbreviateNumber(num),
  },
  {
    name: 'Unusual Premium',
    color: themeDark.color.graphUnusualCall,
    sortKey: 'unusualPremium',
    label: 'Unusual Premium',
    prefix: '$',
    postfix: '',
    formatter: (num: number) => abbreviateNumber(num),
  },
  {
    name: 'Call Volume',
    color: themeDark.color.graphCall,
    sortKey: 'callVolume',
    label: 'Call Contracts',
    prefix: '',
    postfix: '',
    formatter: (num: number) => abbreviateNumber(num),
  },
  {
    name: 'Call Premium',
    color: themeDark.color.graphCall,
    sortKey: 'callPremium',
    label: 'Call Premium',
    prefix: '$',
    postfix: '',
    formatter: (num: number) => abbreviateNumber(num),
  },
  {
    name: 'Put Volume',
    color: themeDark.color.graphPut,
    sortKey: 'putVolume',
    label: 'Put Contracts',
    prefix: '',
    postfix: '',
    formatter: (num: number) => abbreviateNumber(num),
  },
  {
    name: 'Put Premium',
    color: themeDark.color.graphPut,
    sortKey: 'putPremium',
    label: 'Put Premium',
    prefix: '$',
    postfix: '',
    formatter: (num: number) => abbreviateNumber(num),
  },
  {
    name: 'Sweep Volume',
    color: themeDark.color.graphSweep,
    sortKey: 'sweepContracts',
    label: 'Sweep Contracts',
    prefix: '',
    postfix: '',
    formatter: (num: number) => abbreviateNumber(num),
  },
  {
    name: 'Sweep Premium',
    color: themeDark.color.graphSweep,
    sortKey: 'sweepPremium',
    label: 'Sweep Premium',
    prefix: '$',
    postfix: '',
    formatter: (num: number) => abbreviateNumber(num),
  },
];

const OptionFlowTable: React.FC<React.PropsWithChildren<TopTickerProps>> = ({ tradesByTicker, presetGraph }) => {
  const [activeGraph, setActiveGraph] = React.useState(DEFAULT_TOP_TICKER_GRAPH_INDEX);
  const sortKey = options[activeGraph].sortKey;
  const topTrades = [...tradesByTicker]
    // @ts-ignore
    .sort((a, b) => b[sortKey] - a[sortKey])
    .slice(0, MAX_GRAPHS);

  React.useEffect(() => {
    setActiveGraph(presetGraph);
  }, [presetGraph]);

  return (
    <div className="h-full w-full">
      <Card className="flex h-full w-full flex-col" isDark>
        <SectionTitle
          Icon={AiOutlineFire}
          title="Options Top Tickers"
          className="mb-0"
          right={
            <Dropdown
              triggerText={options[activeGraph].name}
              items={options.map((f, i) => ({ ...f, onClick: () => setActiveGraph(i) }))}
            />
          }
        />
        {tradesByTicker.length === 0 ? null : (
          <div
            style={{ height: `calc(100% - ${NAV_HEIGHT} - ${NAV_MARGIN})` }}
            className="w-full max-w-full"
          >
            {options.map((f, i) => {
              return (
                <React.Fragment key={f.name}>
                  {activeGraph === i && (
                    <BarChartTopTickers
                      topTrades={topTrades}
                      color={f.color}
                      label={f.label}
                      prefix={f.prefix}
                      postfix={f.postfix}
                      sortKey={f.sortKey}
                      formatter={f.formatter}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        )}
      </Card>
    </div>
  );
};

export default OptionFlowTable;
