import * as React from 'react';
import Link from 'components/Link';
import { IoLogoReact } from 'react-icons/io5';
import { AiFillStar } from 'react-icons/ai';
import { TICKER_FLOW_PAGE } from 'constants/pages';
import SectionTitle from 'components/SectionTitle';
import { hasDashboardAccess } from 'services/client/token';
import { AuthStatus } from 'constants/auth';
// import { Tab, TabWrapper } from 'components/blocks/Tab';
import {
  TableWrapper,
  TableCell,
  TableHeader,
  TableBody,
  HeaderCell,
  HeaderCellWrapper,
  WatchListIcon,
} from 'components/Table';
import PulseLoader from 'components/Loading/Pulse';
import { useGetMostResearchedTickersLazyQuery } from 'types/generated/client';
import { Props, MostActiveTickersRowProps } from './props';

import { MostActiveRow, ActivityBar } from './styles';
import PermissionedBlock from 'components/PermissionedBlock';

const TITLE_NAMES = ['Ticker', 'Activity'];

const POLL_INTERVAL = 5 * 60_000;

const Header: React.FC<React.PropsWithChildren<unknown>> = React.memo(() => {
  return (
    <TableHeader>
      <SectionTitle Icon={IoLogoReact} title="Most Researched Tickers" />
      <MostActiveRow removePad>
        {TITLE_NAMES.map((t, i) => {
          return (
            <HeaderCellWrapper
              key={t}
              removePad={i + 1 === TITLE_NAMES.length}
              isActiveSort={false}
              onClick={() => {}}
            >
              <HeaderCell>
                <span className="cell-name">{t}</span>
              </HeaderCell>
            </HeaderCellWrapper>
          );
        })}
      </MostActiveRow>
    </TableHeader>
  );
});

const Row: React.FC<React.PropsWithChildren<MostActiveTickersRowProps>> = React.memo(
  ({ item, watchListTickers, largestValue }) => {
    const isWatchListTrade = !!item.id && watchListTickers.includes(item.id);
    return (
      <MostActiveRow tableBodyRow isAnimated>
        <TableCell className="relative">
          <div className="py-1">
            {isWatchListTrade && (
              <WatchListIcon className="absolute text-yellow-400">
                <AiFillStar />
              </WatchListIcon>
            )}
            <Link href={`${TICKER_FLOW_PAGE}/${item.id}`}>{item.id}</Link>
          </div>
        </TableCell>
        <TableCell>
          <div className="w-full py-1">
            <ActivityBar width={((item.count || 0) / largestValue) * 100}>&nbsp;</ActivityBar>
          </div>
        </TableCell>
      </MostActiveRow>
    );
  },
);

const MostActiveTickers: React.FC<React.PropsWithChildren<Props>> = ({
  viewer,
  watchListTickers,
}) => {
  const hasAccess = viewer.status === AuthStatus.User && hasDashboardAccess(viewer);
  const [fetchMostResearchedTickers, { data, loading, refetch }] =
    useGetMostResearchedTickersLazyQuery();
  const items = data?.most_researched_tickers_daily_unique || [];
  const largestValue = items[0]?.count || 1;

  React.useEffect(() => {
    let interval: number | undefined;

    if (hasAccess) {
      if (refetch) {
        refetch();
      } else {
        fetchMostResearchedTickers();
      }

      // @ts-ignore issue with Node / browser types
      interval = setInterval(() => {
        if (refetch) {
          refetch();
        } else {
          fetchMostResearchedTickers();
        }
      }, POLL_INTERVAL);
    }

    return () => clearInterval(interval);
  }, [fetchMostResearchedTickers, hasAccess, refetch]);

  return (
    <TableWrapper>
      <Header />
      <TableBody animateRows>
        {loading ? (
          <div className="flex h-full items-center justify-center">
            <PulseLoader />
          </div>
        ) : !hasAccess ? (
          <PermissionedBlock />
        ) : (
          data?.most_researched_tickers_daily_unique.map((item) => {
            return item.id && item.count ? (
              <Row
                key={item.id}
                item={item}
                watchListTickers={watchListTickers}
                largestValue={largestValue}
              />
            ) : null;
          })
        )}
      </TableBody>
    </TableWrapper>
  );
};

export default MostActiveTickers;
