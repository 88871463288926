import * as React from 'react';
import { IconType } from 'react-icons';
import {
  GetOptionFlowQuery,
  GetUserForDashboardQuery,
  GetWeeklyOverviewQuery,
  GetDarkPoolTradesQuery,
} from 'types/generated/client';
import { Viewer } from 'constants/user';
import { Trade, RawTrade } from 'utils/shared/trades/types';
import { TickerAggregatedDetails } from 'components/OptionAggregatedTable/props';
import { FlowTableProps } from 'utils/shared/trades/types';

export {
  DEFAULT_FLOW_KEY_INDEX,
  FLOW_TABLE_HEADERS,
  DEFAULT_FLOW_KEY,
} from 'components/OptionFlowTable/props';

export enum LayoutType {
  Stacked = 0,
  Market = 1,
  Flow = 2,
}

export { DEFAULT_AGGREGATE_KEY } from 'components/OptionAggregatedTable/props';

export interface OwnProps {}

export interface Props extends OwnProps {
  viewer: Viewer;
  title: string;
  Icon: IconType;
  trades?: RawTrade[];
  dailyVolume?: GetOptionFlowQuery['daily_volume'];
  loading?: boolean;
  called?: boolean;
  userDetails?: GetUserForDashboardQuery['users_by_pk'] | null;
  OptionFlowTable: React.FC<React.PropsWithChildren<FlowTableProps>>;
  shouldShowDatePicker: boolean;
  startDate?: string;
  setStartDate?: React.Dispatch<React.SetStateAction<string>>;
  endDate?: string;
  setEndDate?: React.Dispatch<React.SetStateAction<string>>;
  tickers?: string;
  setTickers?: React.Dispatch<React.SetStateAction<string>>;
  maxResults?: number | null;
  setMaxResults?: React.Dispatch<React.SetStateAction<number | null>>;
  handleHistoricalSearch?: () => void;
  flowSliderReplacement?: React.ReactNode;
  defaultLayoutType?: LayoutType;
  injectedPageData?: GetOptionFlowQuery | GetWeeklyOverviewQuery;
  isWeekly?: boolean;
  pageHref: string;
  isShowFlowDescription?: boolean;
  totalFlowCount?: number;
}

export interface TopTickerProps {
  tradesByTicker: TickerAggregatedDetails[];
  presetGraph: number;
}

export interface TopTickerGraphProps {
  topTrades: TickerAggregatedDetails[];
}

export interface SectorAggregatedDetails {
  sector: string;
  putFlow: number;
  trades: Trade[];
  unusual: Trade[];
  sweeps: Trade[];
  splits: Trade[];
  blocks: Trade[];
  goldenSweeps: Trade[];
  otm: number;
  contracts: number;
  premium: number;
  expiry: number;
  unusualOtm: number;
  unusualContracts: number;
  unusualPremium: number;
  sweepOtm: number;
  sweepContracts: number;
  sweepPremium: number;
  splitOtm: number;
  splitContracts: number;
  splitPremium: number;
  blockOtm: number;
  blockContracts: number;
  blockPremium: number;
  goldenSweepOtm: number;
  goldenSweepContracts: number;
  goldenSweepPremium: number;
}

export interface DarkPoolSectorAggregatedDetails {
  sector: string;
  premium: number;
  volume: number;
  lateBuyCount: number;
  lateSellCount: number;
  goldenTradeCount: number;
  trades: GetDarkPoolTradesQuery['equity_print_flow'];
  isEtf: boolean;
  avgVolume: number;
  dayVolume: number;
  marketCap: number;
}

export interface DarkPoolTickerAggregatedDetails extends DarkPoolSectorAggregatedDetails {
  ticker: string;
  tickerName: string;
  momentum: number;
}

export interface DarkPoolGraphProps {
  tradesByTicker: DarkPoolTickerAggregatedDetails[];
  tradesBySector: DarkPoolSectorAggregatedDetails[];
  presetGraph: number;
}

export interface SectorGraphProps {
  tradesBySector: SectorAggregatedDetails[];
  isUnusualFiltered: boolean;
  isSweepsFiltered: boolean;
  activeSectorGraph: number;
  setActiveSectorGraph: (index: number) => void;
}

export interface DarkPoolSectorGraphProps {
  tradesBySector: DarkPoolSectorAggregatedDetails[];
}

export interface BarChartProps {
  topTrades: TickerAggregatedDetails[];
  color: string;
  label: string;
  prefix: string;
  postfix: string;
  sortKey: string;
  formatter: (num: number) => string | number;
}

export interface DarkPoolBarChartProps {
  topTrades: DarkPoolTickerAggregatedDetails[];
  color: string;
  label: string;
  prefix: string;
  postfix: string;
  sortKey: string;
  formatter: (num: number) => string | number;
  yTickOptions?: object;
}
