import { GetDarkPoolTradesQuery } from 'types/generated/client';
import { Trade, SortDirection, ColumnType } from 'utils/shared/trades/types';

type FeedType = 'OPTIONS' | 'DARK_POOL';
export interface TickerAggregatedDetails {
  ticker: string;
  tickerName: string;
  marketCap: number;
  sector: string;
  putFlow: number;
  trades: Trade[];
  unusual: Trade[];
  sweeps: Trade[];
  splits: Trade[];
  blocks: Trade[];
  goldenSweeps: Trade[];
  otm: number;
  momentum: number;
  volumeGrowth: number;
  contracts: number;
  premium: number;
  price: number;
  expiry: number;
  unusualOtm: number;
  unusualContracts: number;
  unusualPremium: number;
  sweepOtm: number;
  sweepContracts: number;
  sweepPremium: number;
  putVolume: number;
  callVolume: number;
  putPremium: number;
  callPremium: number;
  splitContracts: number;
  splitPremium: number;
  splitOtm: number;
  blockContracts: number;
  blockPremium: number;
  blockOtm: number;
  goldenSweepContracts: number;
  goldenSweepPremium: number;
  goldenSweepOtm: number;
}

export interface DarkPoolAggregate {
  ticker: string;
  tickerName: string;
  sector: string;
  premium: number;
  volume: number;
  lateBuyCount: number;
  lateSellCount: number;
  goldenTradeCount: number;
  trades: GetDarkPoolTradesQuery['equity_print_flow'];
  isEtf: boolean;
  avgVolume: number;
  dayVolume: number;
  marketCap: number;
  momentum: number;
}

export type DataBasedOnFeedType<T extends FeedType> = T extends 'OPTIONS'
  ? TickerAggregatedDetails[]
  : DarkPoolAggregate[];

export interface OverviewHeaderProps<T extends FeedType> {
  aggregateSortKey: string;
  setAggregateSortKey: (key: string) => void;
  aggregateSortDirection: SortDirection;
  setAggregateSortDirection: (direction: SortDirection) => void;
  isWeekly?: boolean;
  isWatchListOnly: boolean;
  setIsWatchListOnly: (isWatchListOnly: boolean) => void;
  hasWatchListItems: boolean;
  data?: DataBasedOnFeedType<T>;
}

export interface OverviewProps extends OverviewHeaderProps<FeedType> {
  tradesByTicker: TickerAggregatedDetails[];
  darkPoolByTicker: DarkPoolAggregate[];
  watchListTickers: string[];
  hasAccess: boolean;
}

const CONSTANT_HEADERS_1 = [
  { name: 'Ticker', sortKey: 'ticker', columnType: ColumnType.Text },
  { name: 'Put Flow', sortKey: 'putFlow', columnType: ColumnType.Number },
  { name: 'OTM', sortKey: 'otm', columnType: ColumnType.Number },
];

const CONSTANT_HEADERS_2 = [
  { name: 'Total Contracts', sortKey: 'contracts', columnType: ColumnType.Number },
  { name: 'Total Premium', sortKey: 'premium', columnType: ColumnType.Number },
  { name: 'Avg Price', sortKey: 'price', columnType: ColumnType.Number },
  { name: 'Avg Expiry', sortKey: 'expiry', columnType: ColumnType.Number },
  { name: 'Sector', sortKey: 'sector', columnType: ColumnType.Text },
  { name: 'Unusual OTM', sortKey: 'unusualOtm', columnType: ColumnType.Number },
  { name: 'Unusual Contracts', sortKey: 'unusualContracts', columnType: ColumnType.Number },
  { name: 'Unusual Premium', sortKey: 'unusualPremium', columnType: ColumnType.Number },
  { name: 'Sweep OTM', sortKey: 'sweepOtm', columnType: ColumnType.Number },
  { name: 'Sweep Contracts', sortKey: 'sweepContracts', columnType: ColumnType.Number },
  { name: 'Sweep Premium', sortKey: 'sweepPremium', columnType: ColumnType.Number },
];

export const DEFAULT_AGGREGATE_KEY_INDEX = 2;
export const AGGREGATE_TABLE_HEADERS = [
  ...CONSTANT_HEADERS_1,
  { name: 'Momentum', sortKey: 'momentum', columnType: ColumnType.Number },
  ...CONSTANT_HEADERS_2,
];
export const WEEKLY_AGGREGATE_TABLE_HEADERS = [
  ...CONSTANT_HEADERS_1,
  { name: 'Momentum', sortKey: 'volumeGrowth', columnType: ColumnType.Number },
  ...CONSTANT_HEADERS_2,
];
export const DEFAULT_AGGREGATE_KEY = AGGREGATE_TABLE_HEADERS[DEFAULT_AGGREGATE_KEY_INDEX].sortKey;

export const DEFAULT_DARKPOOL_KEY_INDEX = 3;
export const DARKPOOL_AGGREGATE_HEADERS = [
  { name: 'Ticker', sortKey: 'ticker', columnType: ColumnType.Text },
  { name: 'Sector', sortKey: 'sector', columnType: ColumnType.Text },
  { name: 'Premium', sortKey: 'premium', columnType: ColumnType.Number },
  { name: 'Volume', sortKey: 'volume', columnType: ColumnType.Number },
  // { name: 'Market Cap', sortKey: 'marketCap', columnType: ColumnType.Number },
  { name: 'Golden Trades', sortKey: 'goldenTradeCount', columnType: ColumnType.Number },
  { name: 'Late Buys', sortKey: 'lateBuyCount', columnType: ColumnType.Number },
  { name: 'Late Sells', sortKey: 'lateSellCount', columnType: ColumnType.Number },
  // { name: 'Momentum', sortKey: 'momentum', columnType: ColumnType.Number },
  { name: 'Lit Volume', sortKey: 'dayVolume', columnType: ColumnType.Number },
  { name: 'Average Daily Volume', sortKey: 'avgVolume', columnType: ColumnType.Number },
  { name: 'Levels', sortKey: '', columnType: ColumnType.Text },
];
export const DEFAULT_DARKPOOL_KEY = DARKPOOL_AGGREGATE_HEADERS[DEFAULT_DARKPOOL_KEY_INDEX].sortKey;
