import * as React from 'react';
import { AiOutlineAreaChart } from 'react-icons/ai';
import Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';
import Dropdown from 'components/Dropdown';
import PermissionedBlock from 'components/PermissionedBlock';
import { hasDashboardAccess } from 'services/client/token';
import { AuthStatus } from 'constants/auth';
import useViewer from 'hooks/useViewer';
import { themeDark } from 'styles/theme';
import abbreviateNumber from 'utils/shared/number/abbreviateNumber';
import BarChartDarkPool from './BarChartDarkPool';
import DarkPoolSectorTreemap from './DarkPoolSectorTreemap';
import { DarkPoolGraphProps } from './props';

import { FeedTypeToggle } from './styles';

const NAV_HEIGHT = '38px';
const NAV_MARGIN = '.5rem';
const MAX_GRAPHS = 12;

enum SectorType {
  All = 'ALL',
  Equity = 'EQUITY',
  ETF = 'ETF',
}

export const DEFAULT_DARK_POOL_GRAPH_INDEX = 0;
const yTickOptions = {
  min: 0,
  stepSize: 1,
};

const options = [
  {
    name: 'Premium',
    color: themeDark.color.basePalette.Violet,
    sortKey: 'premium',
    label: 'Premium $',
    prefix: '$',
    postfix: '',
    formatter: (num: number) => abbreviateNumber(num),
    type: 'bar',
  },
  {
    name: 'Volume',
    color: themeDark.color.basePalette.Violet,
    sortKey: 'volume',
    label: 'Volume',
    prefix: '',
    postfix: '',
    formatter: (num: number) => abbreviateNumber(num),
    type: 'bar',
  },
  {
    name: 'Late Buys',
    color: themeDark.color.graphCall,
    sortKey: 'lateBuyCount',
    label: 'Late Buy Count',
    prefix: '',
    postfix: '',
    formatter: (num: number) => abbreviateNumber(num, 0),
    type: 'bar',
    yTickOptions,
  },
  {
    name: 'Late Sells',
    color: themeDark.color.graphPut,
    sortKey: 'lateSellCount',
    label: 'Late Sell Count',
    prefix: '',
    postfix: '',
    formatter: (num: number) => abbreviateNumber(num, 0),
    type: 'bar',
    yTickOptions,
  },
  {
    name: 'Golden Trades',
    color: themeDark.color.mixedSentiment,
    sortKey: 'goldenTradeCount',
    label: 'Golden Trade Count',
    prefix: '',
    postfix: '',
    formatter: (num: number) => abbreviateNumber(num, 0),
    type: 'bar',
    yTickOptions,
  },
  {
    name: 'Sector',
    color: themeDark.color.graphUnusualCall,
    sortKey: 'premium',
    label: 'Sector Premium',
    prefix: '$',
    postfix: '',
    formatter: (num: number) => abbreviateNumber(num),
    type: 'tree',
  },
];

const DarkPooolGraphs: React.FC<React.PropsWithChildren<DarkPoolGraphProps>> = ({
  tradesByTicker,
  tradesBySector,
}) => {
  const viewer = useViewer();
  const isLoadingAuthStatus = viewer.status === AuthStatus.Loading;
  const hasAccess = viewer.status === AuthStatus.User && hasDashboardAccess(viewer);
  const [activeGraph, setActiveGraph] = React.useState(DEFAULT_DARK_POOL_GRAPH_INDEX);
  const [sectorType, setSectorType] = React.useState(SectorType.All);
  const sortKey = options[activeGraph].sortKey;
  const topTrades = [...tradesByTicker]
    // @ts-ignore
    .sort((a, b) => b[sortKey] - a[sortKey])
    .filter((t) => {
      if (sectorType === SectorType.All) {
        return true;
      } else if (sectorType === SectorType.Equity && !t.isEtf) {
        return true;
      } else if (sectorType === SectorType.ETF && t.isEtf) {
        return true;
      } else {
        return false;
      }
    })
    .slice(0, MAX_GRAPHS);
  const sectorTrades = [...tradesBySector].filter((t) => {
    if (sectorType === SectorType.All) {
      return true;
    } else if (sectorType === SectorType.Equity && !t.isEtf) {
      return true;
    } else if (sectorType === SectorType.ETF && t.isEtf) {
      return true;
    } else {
      return false;
    }
  });

  return (
    <div className="h-full w-full">
      <Card className="flex h-full w-full flex-col" isDark>
        <SectionTitle
          Icon={AiOutlineAreaChart}
          title={
            <div>
              <div>Dark Pool Top Tickers</div>
              <div className="mb-2 mt-1 flex items-center">
                <FeedTypeToggle
                  isActive={sectorType === SectorType.All}
                  onClick={() => setSectorType(SectorType.All)}
                >
                  All
                </FeedTypeToggle>
                <FeedTypeToggle
                  isActive={sectorType === SectorType.Equity}
                  onClick={() => setSectorType(SectorType.Equity)}
                >
                  Eqty
                </FeedTypeToggle>
                <FeedTypeToggle
                  isActive={sectorType === SectorType.ETF}
                  onClick={() => setSectorType(SectorType.ETF)}
                >
                  ETF
                </FeedTypeToggle>
              </div>
            </div>
          }
          className="mb-0"
          right={
            <Dropdown
              triggerText={options[activeGraph].name}
              items={options.map((f, i) => ({ ...f, onClick: () => setActiveGraph(i) }))}
            />
          }
        />
        {isLoadingAuthStatus ? null : !hasAccess ? (
          <PermissionedBlock />
        ) : tradesByTicker.length === 0 ? null : (
          <div
            style={{ height: `calc(100% - ${NAV_HEIGHT} - ${NAV_MARGIN})` }}
            className="w-full max-w-full"
          >
            {options.map((f, i) => {
              return f.type === 'bar' ? (
                <React.Fragment key={f.name}>
                  {activeGraph === i && (
                    <BarChartDarkPool
                      topTrades={topTrades}
                      color={f.color}
                      label={f.label}
                      prefix={f.prefix}
                      postfix={f.postfix}
                      sortKey={f.sortKey}
                      formatter={f.formatter}
                      yTickOptions={f.yTickOptions}
                    />
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment key={f.name}>
                  {activeGraph === i && <DarkPoolSectorTreemap tradesBySector={sectorTrades} />}
                </React.Fragment>
              );
            })}
          </div>
        )}
      </Card>
    </div>
  );
};

export default DarkPooolGraphs;
